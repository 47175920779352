import React, { ChangeEvent } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { discriptionPropsStyle, projectDiscriptionBoxStyle, projectTitlesStyle } from "./ProjectHeaderStyles";

interface ProjectDiscriptionProps {
  prDiscValue: string;
  prDiscOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ProjectDiscription = ({
  prDiscValue,
  prDiscOnChange,
}: ProjectDiscriptionProps) => {
  return (
    <Box>
      <Typography
        sx={projectTitlesStyle}
      >
        Описание
      </Typography>
      <Box
        sx={projectDiscriptionBoxStyle}
      >
        <TextField
          value={prDiscValue}
          onChange={prDiscOnChange}
          variant="filled"
          color="secondary"
          fullWidth
          multiline
          rows={7}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            maxLength: 500,
          }}
          sx={discriptionPropsStyle}
        />
      </Box>
    </Box>
  );
};
