export const ButtonUserInfoStyle = {
  fontSize: "16px",
  fontWeight: 700,
  color: "#fff",
  textTransform: "none",
  fontFamily: "Zen Maru Gothic",
  justifyContent: "flex-start",
  gap: "7px",
  p: 0,
};

export const LocationTypographyStyle = {
  marginLeft: "7px",
  fontSize: "16px",
  marginTop: "6px",
  fontWeight: 300,
  fontFamily: "Nunito",
}

export const RatingTypographyStyle = {
  width: "95px",
  fontSize: "12px",
  px: "30px",
  py: "10px",
  fontWeight: 500,
  textAlign: "center",
  position: "absolute",
  right: 0,
  top: "-5px",
}

export const PlaceInRatingTypographyStyle = {
  width: "95px",
  fontSize: "12px",
  fontWeight: "500",
  textAlign: "center",
  color: "#F5F5F5",
  position: "absolute",
  right: 0,
  top: "30px",
}

export const NameOfUserTypographyStyle = {
  fontSize: "20px",
  textAlign: "center",
  marginTop: "24px",
  fontFamily: "Zen Maru Gothic",
  fontWeight: 700,
}

export const UserNicknameTypographyStyle = {
  fontSize: "16px",
  textAlign: "center",
  marginTop: "8px",
  fontWeight: 300,
  fontFamily: "Nunito",
}