import { Avatar, Box, IconButton, ListItemAvatar, Typography } from "@mui/material";
import CrownIcon from "@mui/icons-material/EmojiEvents";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import React from "react";
import SendMessageModal from "./modal/SendMessageModal";
import { useTheme } from "@mui/material";


interface AllTeamsCardsProps {
  avatar: string;
  name: string;
  projects: number;
  members: number;
  rating: number;
  leaderAvatar: string;
  leaderName: string;
  leaderRole: string;
  onClick: ()=> void;
  teamId: number
}
export const AllTeamsCardItems = ({
  avatar,
  name,
  projects,
  members,
  rating,
  leaderAvatar,
  leaderName,
  leaderRole,
  onClick,
  teamId
}: AllTeamsCardsProps) => {

  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  return (
    <Box
      sx={{
        mt: "40px",
        pt: "15px",
        pl: "30px",
        pr: "30px",
        pb: "26px",
        width: "368px",
        height: "280px",
        background: theme.palette.background.paper,
        borderRadius: "20px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        Команда {name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
          onClick={onClick}
          sx={{
            p: 0
          }}
          >
          <Avatar
            src={avatar}
            alt={name}
            sx={{
              width: "95px",
              height: "95px",
              borderRadius: "8px",
            }}
          ></Avatar>
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "14px",
                fontWeight: 700,
                color: theme.palette.text.secondary,
              }}
            >
              Характеристика команды:
            </Typography>
            <Typography
              sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "14px",
                fontWeight: 700,
                mt: "5px",
              }}
            >
              Количество проектов {projects}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "14px",
                fontWeight: 700,
                mt: "5px",
              }}
            >
              Количество участников {members}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "14px",
                fontWeight: 700,
                mt: "5px",
              }}
            >
              Рейтинг {rating}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "row"
          }}>
          <ListItemAvatar
            sx={{
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <Avatar
              src={leaderAvatar}
              alt={leaderName}
              sx={{
                width: "64px",
                height: "64px",
              }}
            />
            <CrownIcon
              sx={{
                position: "absolute",
                top: 0,
                right: "-20px",
              }}
            />
          </ListItemAvatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "42px",
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {leaderName}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {leaderRole}
            </Typography>
          </Box>
          </Box>
          <Box sx={{
            display: "flex",
            alignSelf: "start"
          }}>
            <IconButton 
             onClick={handleOpenModal}>
          <SmsOutlinedIcon
            sx={{
              width: "16px",
              height: "16px",
              borderRadius: "100%",
              
            }}
          ></SmsOutlinedIcon>
          </IconButton>
          </Box>
        </Box>
        
      </Box>
      <SendMessageModal
      name={name}
      avatar={leaderAvatar}
      leaderName={leaderName}
       open={openModal}
       onClose={handleCloseModal}
       onSave={() => {}}
       teamId={teamId}
      />

      
    </Box>
  );
};
