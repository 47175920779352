import { ListItem } from "@mui/material";
import { Avatar } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import DocumentModal from "../../../modal/DocumentModal";
import { ITeamDoc } from "../../../../models/ITeamData";
import { useTheme } from "@mui/material";

interface TeamDocsItemsProps {
  id: number | undefined;
  name: string;
  description: string;
  avatar: string;
  publishedAt: number;
  teamId: number;
}
export const TeamDocsItems = ({
  id,
  name,
  description,
  avatar,
  publishedAt,
  teamId,
}: TeamDocsItemsProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const theme = useTheme();

  function formatTime(publishedAt: number) { 
    if (publishedAt) {
      const date = new Date(publishedAt); 
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    } else {
      return "";
    }
  }

  return (
    <ListItem
      key={id}
      sx={{
        p: 0,
        mb: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      }}
    >
      <Avatar
        src={avatar}
        alt={name}
        sx={{
          width: "44px",
          height: "44px",
        }}
      />

      <Box
        sx={{
          ml: "20px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "16px",
              fontWeight: 500,
              color: theme.palette.text.primary,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "10px",
              fontWeight: 300,
              color: theme.palette.text.primary,
            }}
          >
            {publishedAt ? formatTime(publishedAt) : ""}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "14px",
              fontWeight: 400,
              color: theme.palette.text.primary,
            }}
          >
            {description}
          </Typography>

          <IconButton onClick={handleOpenModal}>
            <EditIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Box>
      </Box>
      <DocumentModal
        open={openModal}
        onClose={handleCloseModal}
        document={
          {
            name,
            description,
            avatar,
            publishedAt,
          } as ITeamDoc
        }
        onSave={() => {}}
        onDelete={() => {}}
        teamId={teamId}
      />
    </ListItem>
  );
};
