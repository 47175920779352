import React from "react";

export const SettingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4827_52376)">
        <path
          d="M10.0766 2.42399C8.40159 0.748985 5.89326 0.407319 3.87659 1.39065L7.49326 5.00732L4.99326 7.50732L1.37659 3.89065C0.40159 5.91565 0.743256 8.40732 2.41826 10.0823C3.96826 11.6323 6.23492 12.0407 8.15992 11.3157L16.3433 19.499L19.4349 16.4073L11.2849 8.24899C12.0516 6.29899 11.6516 3.99899 10.0766 2.42399Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4827_52376">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
