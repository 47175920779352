import React from "react";

export const CopyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4827_52373)">
        <path
          d="M3.22083 17.6755V10.1505L0.5625 16.5672L3.22083 17.6755ZM18.9875 15.6505L13.5875 2.61719L4.37917 6.42552L9.77917 19.4589L18.9875 15.6505ZM6.5625 8.12552C6.10417 8.12552 5.72917 7.75052 5.72917 7.29219C5.72917 6.83385 6.10417 6.45885 6.5625 6.45885C7.02083 6.45885 7.39583 6.83385 7.39583 7.29219C7.39583 7.75052 7.02083 8.12552 6.5625 8.12552ZM4.89583 18.9589H7.77083L4.89583 12.0089V18.9589Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4827_52373">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
