import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import List from "@mui/material/List";
import { TeamMembersItems } from "./TeamMembersItems";
import { ITeamMember } from "../../../models/ITeamData";
import { useMemo } from "react";
import { useState }  from "react";
import AddTeamMemberModal from "../../modal/AddTeamMemberModal";
import React  from "react";
import { useTheme } from '@mui/material';


interface TeamMembersProps {
  members: ITeamMember[];
  teamId: number;
}

export const TeamMembers = ({ members, teamId }: TeamMembersProps) => {

 const [openModal, setOpenModal] = useState(false);
 const theme = useTheme();

  
  const memoizedMembers = useMemo(() => {
    return members.map((member) => (
      <TeamMembersItems
        key={member.id}
        name={member.name}
        role={member.role}
        avatar={member.avatar}
        id={member.id}
        isTeamLeader={!!member.isTeamLeader}
        teamId={teamId}
      />
    ));
  }, [members]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: "16px",
            fontFamily: "Zen Maru Gothic",
            fontWeight: 600,
          }}
        >
          ЧЛЕНЫ КОМАНДЫ
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            mt: "16px",
            width: "367px",
            height: "543px",
            bgcolor: theme.palette.background.paper,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              pt: "19px",
              pl: "17px",
              pr: "24px",
              borderRadius: "8px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            {memoizedMembers}
          </List>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: "13px",

              mr: "16px",
            }}
          >
            <IconButton 
            onClick={handleOpenModal}
            >
              <AddIcon
                sx={{
                  height: "36px",
                  width: "36px",
                  color: theme.palette.grey[700],
                  bgcolor: theme.palette.grey[100],
                  borderRadius: "100%",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <AddTeamMemberModal
       open={openModal}
       onClose={handleCloseModal}
       onSave={() => {}}
       teamId={teamId}
      />
     </Box>
  );
};
