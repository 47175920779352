import { Box, Button, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useGetUserTeamsQuery } from "../../../services/teams";
import { TeamCurrentBar } from "../components/TeamCurrentBar/TeamCurrentBar";
import { TeamItemList } from "../components/TeamItemList/TeamItemList";
import { ITeamData } from "../models/ITeamData";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TeamsNavigation } from "../components/TeamsNavigation";

export const MyTeamsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedId = id ? parseInt(id, 10) : null;
  const [selectedTeam, setSelectedTeam] = useState<ITeamData | null>(null);
  const { data: teamsData, isLoading: teamsLoading } = useGetUserTeamsQuery();

  const handleTeamSelect = (team: ITeamData) => {
    setSelectedTeam(team);
  };

  if (teamsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <TeamsNavigation
        activeButton="my-teams"
        onButtonClick={(buttonName) => {
          if (buttonName === "teams") {
            navigate("/teams/all-teams");
          }
        }}
      />
      <Box sx={{ mt: "20px" }}>
        {(!teamsData || teamsData.length === 0) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
                mb: "4",
              }}
            >
              У вас пока нет команд. Добавьтесь в проект или создайте новую
              команду.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  alignSelf: "flex-end",
                }}
                onClick={() => navigate("/teams/new-team")}
              >
                Создать новую команду
              </Button>
            </Box>
          </Box>
        )}
        {teamsData && teamsData.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TeamCurrentBar
              teamId={selectedTeam?.id ?? parsedId ?? 1}
              teams={teamsData}
              currentTeamId={selectedTeam?.id}
              onTeamClick={handleTeamSelect}
            />
            <TeamItemList teamId={selectedTeam?.id ?? parsedId ?? 1} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

//     <Box>
//       <TeamsNavigation
//         activeButton="my-teams"
//         onButtonClick={(buttonName) => {
//           if (buttonName === "teams") {
//             navigate("/teams");
//           }
//         }}
//       />
//       <Box sx={{ display: "flex", flexDirection: "column", mt: "20px" }}>
//         <TeamNavBar
//           teams={teamsData || []}
//           currentTeamId={selectedTeam?.id}
//           onTeamClick={handleTeamSelect}
//         />
//         <TeamCurrentBar teamId={selectedTeam?.id ?? parsedId ?? 1} />
//         <TeamItemList teamId={selectedTeam?.id ?? parsedId ?? 1} />
//       </Box>
//     </Box>
//   );
// };
