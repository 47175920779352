export const projectButtonOutterBoxStyle = {
  mt: "60px",
  ml: "50px"
};

export const projectButtonTextStyle = {
  ml: "5px",
  fontFamily: "Zen Maru Gothic",
  fontSize: "16px",
  fontWeight: 700,
  color: "white",
};

export const projectButtonIconStyle = {
  width: "24px",
  height: "24px",
  color: "white",
};
