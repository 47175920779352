import React from "react";

export const typographyHeader = {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
}

export const typograhyMainHeader = {
    fontFamily: "Zen Maru Gothic",
    fontSize: "16px",
    fontWeight: 700,
    mb: "10px",
    ml: "10px",
    mt: "10px",
}

export const mainBox = {
    width: "835px",
    minHeight: "150px",
    mt: "30px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
    px: "45px",
    py: "25px",
    display: "flex",
}

export const outterBox ={
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  }

export const innerBox = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontFamily: "Nunito",
    fontSize: "15px",
    fontWeight: 500,
    color: "black",
    gap: "20px",
}

export const roleBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "240px",
    height: "30px",
    backgroundColor: "#82D9FF",
    borderRadius: "4px",
  }

  export const skillCloudBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "285px",
    height: "30px",
    backgroundColor: "#82D9FF",
    borderRadius: "4px",
  }

  export const quantityBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "115px",
    height: "30px",
    backgroundColor: "#82D9FF",
    borderRadius: "4px",
  }

  export const roleItemsBox = {
    mt: "20px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
  }

  export const selectedRolesBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    px: "40px",
  }