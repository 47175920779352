import React from "react";

export const roleMenuOutterBoxStyle = {
    position: "absolute",
    width: "220px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: 1,
    fontFamily: "Nunito",
    color: "black",
  }