import { Typography, Box, useTheme } from "@mui/material";
import TaskItem from "./TaskItem/TaskItem";

export default function CurrentTasks() {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        padding: { lg:"24px", md: '24px', xs: '2%'},
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Box>
        <Typography sx={{
          textAlign: "center",
          fontFamily: "Zen Maru Gothic",
          fontWeight: 700,
          fontSize: "20px",
        }}>Текущие задачи</Typography>
          </Box>
          {/* через map перечислить свойства и передать пропсы */}
      <TaskItem />
      <TaskItem />
    </Box>
  );
}
