import { Box } from '@mui/system'
import { NavLink } from 'react-router-dom'
import styles from "./SubNavBar.module.css";


export const SubNavBar = ({pages}: any) => {
  return (
    <Box display="flex" justifyContent="center">
    {pages.map((page: any) => (
      <NavLink
        key={page.url}
        className={({ isActive }) =>
          isActive ? styles.link_active : styles.link
        }
        to={page.url}
      >
        {page.title}
      </NavLink>
    ))}
  </Box>
  )
}
