import { useState } from "react";
import { Modal, Typography, TextField, Button, Box } from "@mui/material";
import { ITeamDoc } from "../../models/ITeamData";
import { useCreateTeamDocumentMutation } from "../../../../services/teams";
import Alert from '@mui/material/Alert';


interface CreateDocumentModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  teamId: number;
}

const CreateDocumentModal = ({
  open,
  onClose,
  onSave,
  teamId,
}: CreateDocumentModalProps) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [createDocument] = useCreateTeamDocumentMutation();

  const handleSave = async () => {
    try {
    const newDocument = {
      name,
      description,
      avatar,
      publishedAt: new Date().getTime(),
    } as ITeamDoc;

    await createDocument({
      id: teamId,
      document: newDocument,
    });
    
      onSave();
      onClose();
  } catch(error) {
    <Alert severity="error">
      Не удалось создать документ для команды !
    </Alert>
  };
    
  };

  const handleCancel = () => {
    setName("");
    setDescription("");
    setAvatar("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Создание документа</Typography>
        </Box>
        <TextField
          label="Ссылка на аватар"
          value={avatar}
          onChange={(e) => setAvatar(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Название"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Описание"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Создать
          </Button>
          <Box sx={{ mx: 2 }} />
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleCancel}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateDocumentModal;
