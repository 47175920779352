import { Box } from "@mui/material";

export const InfoBaseStub = () => {
  return (
    <Box sx={{
      minHeight: "580px",
      display: "grid",
      alignContent: "center"
    }}>
      {/* Рейтинг */}
      <Box justifyContent="end" display="grid" alignContent="baseline"></Box>

      {/* Ава, Дата, Кнопки */}
      <Box
        sx={{
          borderRadius: "8px",
          width: "295px",
          display: "grid",
          justifySelf: "center",
        }}
      >
        <Box display="grid" justifySelf="center">
          Нет связи с сервером...
        </Box>
      </Box>
    </Box>
  );
};
