import React from "react";
import { Box } from "@mui/system";
import Vacancy from './Vacancy';
import { IProjectVacancy } from "../../model/projectData";

interface VacanciesListProps {
  vacancies: IProjectVacancy[];
}

export default function VacanciesList({ vacancies }: VacanciesListProps) {
 
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 318,
         mt: "10px",
        
      }}
    >
      <Box
        sx={{
          display: "inline",
          fontFamily: "Zen Maru Gothic",
          fontWeight: 700,
          fontSize: "16px",
        }}
      >
        Вакансии
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: "10px"
         }}
      >
        {vacancies.map((vacancy, index) => (
          <Vacancy 
          key={`vacancy-${vacancy.id}-${index}`} 
          vacancy={vacancy}
           />
        ))}
      </Box>
    </Box>
  );
}
