import { Box, Typography, useTheme } from "@mui/material";

export default function DailyMess() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        display: "flex",
        padding: { lg: "93px 123px", md: "5% 7%", xs: "5% 7%" },
        alignItems: "center",
        backgroundColor: theme.palette.primary.dark,
        textAlign: "center",
      }}
    >
      <Typography>
        Сообщение дня (рекламный пост, советы, напоминания, достижения, новости,
        появление новых вакантных проектов, голосование за фичи) в виде баннера
      </Typography>
    </Box>
  );
}
