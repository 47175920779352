import React from "react";
import { IconButton } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

interface SortButtonProps {
  onClick: () => void;
}

const SortButton = ({ onClick }:SortButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      <ImportExportIcon />
    </IconButton>
  );
};

export default SortButton;
