import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IFriends, IUserData } from "../pages/lk/model/UserData";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `http://185.46.199.211:39809`,
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUserData: builder.query<IUserData, string>({
      query: (userid) => ({
        url: `api/users/${userid}/user`,
      }),
      providesTags: ["User"],
    }),
    updateUserData: builder.mutation<IUserData, any>({
      query: (body) => {
        return {
          url: "api/users/update",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    uploadUserAvatar: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "avatar/upload", //другой порт
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    createUser: builder.mutation<IUserData, any>({
      query: (body) => {
        return {
          url: "api/users",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/users/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    createUserSocial: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `api/users/social/add`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUserSocial: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/users/social/del/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    deactUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/users/deact/${userid}`,
          method: "POST",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    addUserContact: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `api/users/contact/add`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    delUserContact: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/users/contact/del/${userid}`,
          method: "DELETE",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),
    activUser: builder.mutation<any, any>({
      query: (userid) => {
        return {
          url: `api/users/activ/${userid}`,
          method: "POST",
          body: userid,
        };
      },
      invalidatesTags: ["User"],
    }),

    getUserStacks: builder.query<any, string>({
      query: (id) => ({
        url: `${id}/stacks`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"], //другой порт
    }),
    getUserFriends: builder.query<IFriends, string>({
      query: (userid) => ({
        url: `api/friends/${userid}/friends`,
      }),
      providesTags: ["User"],
    }),
    getUserFriendsList: builder.query<IFriends, string>({
      query: () => ({
        url: "api/friends",
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"],
    }),
    delFriend: builder.query<any, string>({
      query: () => ({
        url: "api/friends/delete",
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"],
    }),
    addFriend: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "api/friends",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getsFriend: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: "api/friends/gets",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["User"],
    }),
    getUserProjects: builder.query<IUserData, string>({
      query: (userid) => ({
        url: `${userid}/projects`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: ["User"], //другой порт
    }),
  }),
});

export const {
  useGetUserFriendsQuery,
  useGetUserProjectsQuery,
  useGetUserStacksQuery,
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUploadUserAvatarMutation,
  useActivUserMutation,
  useCreateUserMutation,
  useAddUserContactMutation,
  useCreateUserSocialMutation,
  useDeactUserMutation,
  useGetUserFriendsListQuery,
  useDelUserContactMutation,
  useDelUserMutation,
  useDelUserSocialMutation,
} = userApi;
