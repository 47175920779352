import React from "react";
import { Box } from "@mui/material";
import useCountDown from "./useCountDown";

interface TimerProps {
  stopTimer: () => void;
}

export const Timer = ({ stopTimer }: TimerProps) => {
  const { startAt } = useCountDown(stopTimer, 60);

  return (
    <Box
      sx={{
        width: "75px",
        height: "57px",
        fontFamily: "Nunito",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19.36px",
        textAlign: "center",
      }}
    >
      Осталось <Box textAlign="center">{startAt}</Box> секунд
    </Box>
  );
};
