import React from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface FilterButtonProps {
  onClick: () => void;
  open: boolean;
}

const FilterButton = ({ onClick, open }: FilterButtonProps) => {
  return (
    <IconButton onClick={onClick}>
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

export default FilterButton;


