import React from "react";
import { Box, Typography } from "@mui/material";
import {
  projectContactBoxStyle,
  projectItemStyle,
  projectTitlesStyle,
} from "./ProjectHeaderStyles";

interface PrContactInfoProps {
  mail?: string;
  phone?: string;
}

export const ProjectContactInfo = ({ mail, phone }: PrContactInfoProps) => {
  return (
    <Box
      sx={{
        ml: "20px",
      }}
    >
      <Typography sx={projectTitlesStyle}>Контактная информация</Typography>
      <Box sx={projectContactBoxStyle}>
        <Box
          sx={{
            p: "10px",
          }}
        >
          <Typography sx={projectItemStyle}>email : {mail}</Typography>
          <Typography sx={projectItemStyle}>телефон : {phone}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
