const AddFriendIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#E6E1E5"
      d="M12.503 7.003a3.332 3.332 0 0 0 3.333-3.334 3.332 3.332 0 1 0-6.667 0 3.332 3.332 0 0 0 3.334 3.334Zm-7.5-1.667v-2.5H3.336v2.5h-2.5v1.667h2.5v2.5h1.667v-2.5h2.5V5.336h-2.5Zm7.5 3.333c-2.225 0-6.667 1.117-6.667 3.334v1.666h13.333v-1.666c0-2.217-4.441-3.334-6.666-3.334Z"
    />
  </svg>
)
export default AddFriendIcon
