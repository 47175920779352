import { Typography, Box } from "@mui/material";
import ProjectItem from "./ProjectItem/ProjectItem";

export default function WhileAway() {
  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        padding: { lg: "24px", md: "24px" },
        wordWrap: { xs: "break-word", lg: "normal" },
      }}
    >
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Zen Maru Gothic",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          Пока вас не было
        </Typography>
      </Box>
      <ProjectItem />
      <ProjectItem />
    </Box>
  );
}
