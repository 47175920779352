import React, { useState } from "react";
import {
  Alert,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IProject } from "../../model/projectData";
import { useDeleteMyProjectMutation } from "../../../../services/projects";

interface DelBtnProps {
  project: IProject;
}

export default function DelBtn({ project }: DelBtnProps) {
  const [deleteProject, { isError }] = useDeleteMyProjectMutation();
  const [error, setError] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteProject({
        id: project.id,
      });
      setIsDialogOpen(false);
    } catch (error) {
      setError("Не удалось удалить проект");
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {error && (
        <Alert severity="error">
          <Typography sx={{ fontFamily: "Zen Maru Gothic" }}>
            {error}
          </Typography>
        </Alert>
      )}
      <IconButton
        sx={{ pt: "8px", width: "22px", height: "22px" }}
        onClick={handleOpenDialog}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Удаление проекта</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить этот проект?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Отмена
          </Button>
          <Button onClick={handleDelete} color="error">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
