export const LocationIcon = (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00033 0.333496C3.06699 0.333496 0.666992 2.7335 0.666992 5.66683C0.666992 9.26683 5.33366 13.3335 5.53366 13.5335C5.66699 13.6002 5.86699 13.6668 6.00033 13.6668C6.13366 13.6668 6.33366 13.6002 6.46699 13.5335C6.66699 13.3335 11.3337 9.26683 11.3337 5.66683C11.3337 2.7335 8.93366 0.333496 6.00033 0.333496ZM6.00033 12.1335C4.60033 10.8002 2.00033 7.9335 2.00033 5.66683C2.00033 3.46683 3.80033 1.66683 6.00033 1.66683C8.20033 1.66683 10.0003 3.46683 10.0003 5.66683C10.0003 7.86683 7.40033 10.8002 6.00033 12.1335ZM6.00033 3.00016C4.53366 3.00016 3.33366 4.20016 3.33366 5.66683C3.33366 7.1335 4.53366 8.3335 6.00033 8.3335C7.46699 8.3335 8.66699 7.1335 8.66699 5.66683C8.66699 4.20016 7.46699 3.00016 6.00033 3.00016ZM6.00033 7.00016C5.26699 7.00016 4.66699 6.40016 4.66699 5.66683C4.66699 4.9335 5.26699 4.3335 6.00033 4.3335C6.73366 4.3335 7.33366 4.9335 7.33366 5.66683C7.33366 6.40016 6.73366 7.00016 6.00033 7.00016Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
