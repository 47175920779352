import React, { useEffect, useRef } from "react";
import {
  Box,
  MenuItem,
  Checkbox,
  FormControlLabel,
  styled,
} from "@mui/material";

interface FilterMenuProps {
  open: boolean;
  filters: string[];
  selectedFilters: string[];
  onChange: (option: string) => void;
  onOutsideClick: () => void;
}

const StyledCheckbox = styled(Checkbox)`
  color: black;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FilterMenu = ({
  open,
  filters,
  onChange,
  selectedFilters,
  onOutsideClick,
}: FilterMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!menuRef.current || !menuRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open, onOutsideClick]);

  const handleListMouseDown = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    filter: string
  ) => {
    event.stopPropagation();
    onChange(filter);
  };

  return (
    <Box
      ref={menuRef}
      sx={{
        position: "absolute",
        top: "100%",
        left: 0,
        width: "270px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: 1,
        fontFamily: "Nunito",
        color: "black",
      }}
      onMouseDown={handleListMouseDown}
    >
      {filters.map((filter) => (
        <MenuItem
          key={filter}
          onClick={(event) => handleItemClick(event, filter)}
        >
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={selectedFilters.includes(filter)}
                onChange={() => onChange(filter)}
              />
            }
            label={filter}
            labelPlacement="start"
            value={filter}
          />
        </MenuItem>
      ))}
    </Box>
  );
};

export default FilterMenu;
