import { Typography, Box, Grid, Button, useTheme } from "@mui/material";

export default function ProjectItem() {
  const theme = useTheme();

  const typographyClampStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "22px",
        backgroundColor: theme.palette.primary.dark,
        width: "711px",
        borderRadius: "24px",
        textAlign: "start",
        padding: { lg: "10px 24px", md: "10px 24px", xs: "2% 4%" },
      }}
    >
      <Typography variant="h5">Название проекта</Typography>

      <Grid
        container
        spacing={2}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={6}>
          <Typography sx={typographyClampStyle}>
            Название задачи или начало ее текста. Длинный текст - скрывается
            часть скрывается многоточием.
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Typography>Закрыл</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography>23.04.2023</Typography>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Typography>Константин Константинопольский</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography sx={typographyClampStyle}>
            Вторая задача по этому же проекту. Думаю, что 2 строчки -
            оптимальный вариант.
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Typography>Закрыл</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography>23.04.2023</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography>Константин Константинопольский</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography sx={typographyClampStyle}>
            Третья задача. Всего 3 задачи на плашку проекта. Если задач больше -
            надо уже идти в сам проект.
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Typography>Закрыл</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography>23.04.2023</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Typography>Константин Константинопольский</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          href="#text-buttons"
          sx={{
            fontFamily: "Nunito",
            fontWeight: "400",
            fontSize: "14px",
            textTransform: "none",
            color: theme.palette.text.secondary,
          }}
        >
          Еще
        </Button>
      </Box>
    </Box>
  );
}
