import React from "react";
import { ITeamProject } from "../../../models/ITeamData";
import { useMemo } from "react";
import { TeamProjectsItems } from "./TeamProjectsItems";
import { Typography, Box, List} from "@mui/material";
import { useTheme } from '@mui/material';

interface TeamProjectsProps {
  projects: ITeamProject[];
}

export const TeamProjects = ({ projects }: TeamProjectsProps) => {
  const memoizedProjects = useMemo(() => {
    return projects.map((project) => (
      <TeamProjectsItems
        key={project.id}
        name={project.name}
        status={project.status}
        avatar={project.avatar}
        id={project.id}
      />
    ));
  }, [projects]);

  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: "16px",
          fontFamily: "Nunito",
          fontWeight: 600,
          mt: "5px",
        }}
      >
        ПРОЕКТЫ КОМАНДЫ
      </Typography>

      <Box
        sx={{
          display: "flex",
          mt: "5px",
          mb: "90px",
          width: "368px",
          height: "223px",
          overflow: "hidden",
          backgroundColor: theme.palette.background.paper,
          borderRadius: "8px"
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "19px",
            pl: "10px",
            pr: "24px",
            borderRadius: "8px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          {memoizedProjects}
        </List>
      </Box>
    </Box>
  );
};





// import { ITeamProject } from "../../../models/ITeamData";
// import { useMemo } from "react";
// import { TeamProjectsItems } from "../TeamMembers/TeamProjectsItems/TeamProjectsItems";
// import { Typography, Box, List, Tabs } from "@mui/material";

// interface TeamProjectsProps {
//   projects: ITeamProject[];
// }

// export const TeamProjects = ({ projects }: TeamProjectsProps) => {
//   const memoizedProjects = useMemo(() => {
//     return projects.map((project) => (
//       <TeamProjectsItems
//         key={project.id}
//         name={project.name}
//         status={project.status}
//         avatar={project.avatar}
//         id={project.id}
//       />
//     ));
//   }, [projects]);

//   return (
//     <Box>
//       <Typography
//         variant="h1"
//         sx={{
//           fontSize: "16px",
//           fontStyle: "Inter",
//           fontWeight: 600,
//           mt: "38px",
//         }}
//       >
//         ПРОЕКТЫ КОМАНДЫ
//       </Typography>

//       <Box
//         sx={{
//           display: "flex",

//           marginTop: "20px",
//           width: "368px",
//           height: "223px",
//           bgcolor: "rgba(217, 217, 217, 1)",
//           borderRadius: "8px",
//         }}
//       >
       
//        <List
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             width: "100%",
//             pt: "19px",
//             pl: "10px",
//             pr: "24px",
//             bgcolor: "rgba(217, 217, 217, 1)",
//             borderRadius: "8px",
//           }}
//         >
//           {memoizedProjects}
//         </List>
//       </Box>
//     </Box>
//   );
// };
