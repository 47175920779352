import { Typography, Box, LinearProgress, useTheme, Chip } from "@mui/material";
import { tokens } from "../../../../components/theme";

export default function ProgressTable() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        padding: { lg: "22px 34px", md: "5% 7%", xs: "5% 7%" },
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <LinearProgress
        color="success"
        variant="buffer"
        value={70}
        valueBuffer={100}
      />
      <Typography sx={{
        mt: "20px",
        fontSize: "14px",
        fontFamily: "Nunito"
      }}>
        Вы подтвердили 3 из 5 необходимых навыков 
      </Typography>
      <Typography sx={{
        mt: "20px",
        fontSize: "14px",
        fontFamily: "Nunito"
      }}>
        Чтобы приступить к поиску проектов, добавьте и подтвердите недостающие
        навыки:
      </Typography>
      <Box sx={{
        gap: "12px",
        display: "flex",
        mt: "20px"
      }}>
        <Chip
          label="CSS"
          sx={{ backgroundColor: colors.blue[50], color: colors.black[100] }}
        />
        <Chip
          label="React"
          sx={{ backgroundColor: colors.blue[50], color: colors.black[100] }}
        />
      </Box>
    </Box>
  );
}
