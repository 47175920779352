import { Box, Stack, Typography, colors, useTheme } from "@mui/material";
import CheckedIcon from "../icons/checked";

interface ISkill {
  id: number;
  name: string;
  verified: boolean;
  skillEntity: {
    subSkills: ISubskill[];
  };
}

interface ISubskill {
  id: number;
  idSkill: number;
  subSkillEntity: {
    id: number;
    name: string;
  };
}

interface UserSkillsProps {
  skills: ISkill[];
}

export const UserSkills = ({ skills }: UserSkillsProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "220px 220px",
        }}
      >
        <Box
          sx={{
            display: "grid",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Навыки
          </Typography>
          <Box
            sx={{
              width: "844px",
              borderRadius: "8px",
              bgcolor: theme.palette.primary.dark,
            }}
          >
            <Stack
              spacing="37px"
              direction="row"
              sx={{
                mt: "8px",
                p: "10px",
              }}
            >
              {skills &&
                skills.map((skill) => (
                  <Box key={skill.id}>
                    <Box
                      sx={{
                        background: "#D6F3FF",
                        borderRadius: "4px",
                        p: "9px 7px",
                        fontSize: "16px",
                        fontFamily: "Nunito",
                        width: "180px",
                        fontWeight: 600,
                        color: colors.blue[700],
                      }}
                    >
                      {skill.name}
                    </Box>
                    <Stack
                      sx={{
                        mt: "7px",
                        display: "flex",
                        gap: "7px",
                      }}
                    >
                      {skill.skillEntity.subSkills.map((s) => (
                        <Box
                          key={s.id}
                          sx={{
                            border: `1px solid ${colors.green[500]}`,
                            p: "4px 8px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ fontSize: "14px" }}>
                            {s.subSkillEntity.name}
                          </Typography>
                          <CheckedIcon />
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
