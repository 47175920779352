import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import PrItem from "../projectItem/ProjectItem";
import { IProjects, IProject } from "../../model/projectData";

interface PrSearchResultsProps {
  projectList: IProjects;
  isFavorite: boolean;
 }

export default function PrSearchResults({
  projectList,
  isFavorite,
 }: PrSearchResultsProps) {
  const hasResults = projectList.length > 0;
  const userId= 3

  return (
    <Box sx={{ mt: "20px" }}>
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "24px",
          fontWeight: 700,
        }}
      >
        Результат поиска
      </Typography>
      {hasResults ? (
        <Grid sx={{ mt: "20px" }} container spacing={3}>
          {projectList.map((listItem: IProject) => {
            const isListItemFavorite = listItem.isFavorite || isFavorite;
            return (
              <Grid item lg={4} md={6} xs={12} key={listItem.id}>
                <PrItem
                  listItem={listItem}
                  isFavorite={isListItemFavorite}
                  userId = {userId}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "25px",
              fontWeight: 700,
            }}
          >
            К сожалению, по вашему запросу ничего не найдено.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
