import React from "react";
import { Box } from "@mui/material";

interface SelectedCountItemsProps {
  count: number | "";
}

export const SelectedCountItems = ({ count }: SelectedCountItemsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        bgcolor: "#82D9FF",
        color: "black",
        maxWidth: "25px",
        maxHeight: "25px",
        py: "5px",
        px: "8px",
      }}
    >
      {count}
    </Box>
  );
};
