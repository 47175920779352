import React, { useState, useEffect, useCallback } from "react";
import { Box, useTheme, IconButton } from "@mui/material";
import {
  stacksApi,
  useGetFullStackQuery,
} from "../../../../../services/stacks";
import { ISubSkill } from "../../../model/UserData";
import { JHSelect } from "./components/jHSelect/JHSelect";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../../../../components/theme";

interface UserSkillsProps {
  onBlockChange: (index: number, block: BlockState) => void;
}

interface BlockState {
  selectedRole?: number;
  selectedSkill?: number;
  selectedSubSkills: ISubSkill[];
}

const initialBlockState: BlockState = {
  selectedRole: undefined,
  selectedSkill: undefined,
  selectedSubSkills: [] || undefined,
};

export const UserSkills = ({ onBlockChange }: UserSkillsProps) => {
  const {
    data: fullStackList,
    error,
    isLoading,
  } = stacksApi.useGetFullStackQuery("");

  const [blockStates, setBlockStates] = useState<BlockState[]>(
    Array(3).fill({ ...initialBlockState })
  );

  const handleRoleChange = useCallback(
    (index: number, roleId: number) => {
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
          selectedRole: roleId,
          selectedSkill: undefined,
        };
        return updatedBlockStates;
      });
    },
    [setBlockStates]
  );

  const setSelectedSkill = useCallback(
    (index: number, skillId: number) => {
      setBlockStates((prevStates) => {
        const updatedBlockStates = [...prevStates];
        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
          selectedSkill: skillId,
        };

        const selectedSkill = fullStackList
          ?.find(
            (role: { id: number | undefined }) =>
              role.id === updatedBlockStates[index].selectedRole
          )
          ?.skill.find((skill: { id: number }) => skill.id === skillId);

        const subSkills = selectedSkill?.subSkills || [];

        updatedBlockStates[index] = {
          ...updatedBlockStates[index],
        };
        return updatedBlockStates;
      });
    },
    [setBlockStates, fullStackList]
  );

  useEffect(() => {
    const allBlocksFilled = blockStates.every(
      (blockState) =>
        blockState.selectedRole &&
        blockState.selectedSkill &&
        blockState.selectedSubSkills.length > 0
    );
    if (allBlocksFilled && blockStates.length < 5) {
      setBlockStates((prevStates) => [...prevStates, { ...initialBlockState }]);
    }
  }, [blockStates]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          // flexDirection: "row",
          width: "844px",
          height: "325px",
          borderRadius: "8px",
          background: theme.palette.background.paper,
          overflowX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            //flexDirection: "row",
            width: "610px",
            height: "265px",
            mt: "24px",
            ml: "24px",
            mb: "24px",
            gap: "40px",
          }}
        >
          {blockStates.map((blockState, index) => (
            <JHSelect
              key={index}
              fullStackList={fullStackList || []}
              selectedRole={blockState.selectedRole}
              setSelectedRole={(roleId) => handleRoleChange(index, roleId)}
              selectedSkill={blockState.selectedSkill}
              setSelectedSkill={(skillId) => setSelectedSkill(index, skillId)}
              selectedSubSkills={blockState.selectedSubSkills}
              onRoleChange={(roleId) => handleRoleChange(index, roleId)}
              onSkillChange={(skillId) => setSelectedSkill(index, skillId)}
            />
          ))}
        </Box>
        <IconButton
          sx={{
            position: "relative",
            left: "100px",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.0)",
            },
          }}
        >
          <AddIcon
            sx={{
              padding: "2px",
              height: "32px",
              width: "32px",
              bgcolor: theme.palette.text.secondary,
              borderRadius: "50%",
              color: theme.palette.info.light,
              "&:hover": {
                background: theme.palette.primary.light,
              },
            }}
          />
        </IconButton>
      </Box>
    </>
  );
};
