import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material";

export const tokens = (mode: string) => ({
  ...(mode === "dark"
    ? {
        primary: {
          main: "#101335",
          dark: "#2E3153",
          light: "#00A1E7",
          contrastText: "#E6E1E5",
        },
        secondary: {
          default: "#EAEAEA",
          50: "rgba(217, 217, 217, 0.1)",
          100: "rgba(217, 217, 217, 0.2)",
        },
        blue: {
          default: "#101335",
          50: "#D6F3FF", //в паллитре макета нет
          200: "#83D9FF",
          300: "#00A1E7",
          400: "#0E44D0",
          500: "#0050AD", //#0050AD
          600: "#000A67", //#000A67
          700: "#2E3153",
          800: "#0B1F33",
          900: "#101335",
        },
        grey: {
          default: "#D0D0D0",
          50: "#FFFFFF",
          200: "rgba(245, 245, 245, 1)",
          500: "rgba(230, 225, 229, 1)",
          600: "#D7D7D7", //в макете встречатся значение GREY/600 #E6E1E5;
          700: "#969696",
          800: "#49454F",
          900: "#000000",
        },
        red: {
          default: "#FF4C51",
        },
        green: {
          default: "#0ED050",
        },
        black: {
          default: "#000000",
          100: "#101335",
        },
        text: {
          primary: "#FFFFFF",
          secondary: "#82D9FF",
          disabled: "#00A1E7",
          divider: "#E6E1E5",
        },
        info: {
          main: "#00A1E7",
          light: "#00A1E7",
          dark: "#82D9FF",
        },
      }
    : {
        primary: {
          main: "#FFFFFF",
          dark: "#82D9FF",
          light: "#E6E1E5",
          contrastText: "#00A1E7",
        },
        secondary: {
          default: "#101335",
          50: "rgba(217, 217, 217, 0.1)",
          100: "rgba(217, 217, 217, 0.2)",
          200: "#EAEAEA",
        },
        blue: {
          default: "#101335",
          50: "#D6F3FF", //в паллитре макета нет
          200: "#83D9FF",
          300: "#00A1E7",
          400: "#0E44D0",
          500: "#0050AD", //#0050AD
          600: "#000A67", //#000A67
          700: "#2E3153",
          800: "#0B1F33",
          900: "#101335",
        },
        grey: {
          default: "#D0D0D0",
          50: "#FFFFFF",
          200: "rgba(245, 245, 245, 1)",
          500: "rgba(230, 225, 229, 1)",
          600: "#D7D7D7",
          700: "#969696",
          800: "#49454F",
          900: "#000000",
        },
        red: {
          default: "#FF4C51",
        },
        green: {
          default: "#0ED050",
        },
        black: {
          default: "#000000",
          100: "#101335",
        },
        text: {
          primary: "#101335",
          secondary: "#82D9FF",
          disabled: "#00A1E7",
          divider: "#00A1E7",
        },
        info: {
          main: "#82D9FF",
          light: "#D6F3FF",
          dark: "#949494",
        },
      }),
});

export const themeSettings: any = (mode: string) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary.main,
              dark: colors.primary.dark,
              light: colors.primary.light,
              contrastText: colors.primary.contrastText,
            },
            background: {
              paper: colors.primary.dark,
              default: colors.primary.main,
            },
            text: {
              primary: colors.text.primary,
              secondary: colors.text.secondary,
              disabled: colors.black[100],
            },
            secondary: {
              main: colors.secondary.default,
              light: colors.secondary[100],
              dark: colors.secondary.default,
            },
            info: {
              main: colors.secondary[50],
              light: colors.grey.default,
              dark: colors.grey[700],
            },
            grey: {
              100: colors.grey.default, //useTheme нет default, поэтому присваиваю числу
              200: colors.grey[200],
              500: colors.grey[500],
              700: colors.grey[700],
            },
            success: {
              main: colors.info.main,
              light: colors.red.default,
            },
          }
        : {
            primary: {
              main: colors.primary.main,
              dark: colors.primary.dark,
              light: colors.primary.light,
              contrastText: colors.primary.contrastText,
            },
            background: {
              paper: colors.primary.dark,
              default: colors.primary.main,
            },
            text: {
              primary: colors.text.primary,
              secondary: colors.text.secondary,
              disabled: colors.black[100],
            },
            secondary: {
              main: colors.secondary.default,
              light: colors.secondary[100],
              dark: colors.secondary[200],
            },
            info: {
              main: colors.blue[50],
              light: colors.grey[700],
              dark: colors.grey[600],
            },
            grey: {
              100: colors.grey.default, //useTheme нет default, поэтому присваиваю числу
              200: colors.grey[200],
              500: colors.grey[500],
              700: colors.grey[700],
            },
            success: {
              main: colors.info.main,
              light: colors.red.default,
            },
          }),
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        contained: {
          boxShadow: "none",
        },
        containedPrimary: {
          backgroundColor: "rgba(217, 217, 217, 0.20)",
          color: "#D9D9D9",
        },
      },
    },
  };
};

export const ColorModeContext = createContext<{ toggleColorMode: () => void }>({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme: any = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
