export const DocumentsAndLinksInnerBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  "&:not(:first-of-type)" : {
    mt: "20px"
  }
};

export const DocumentsAndLinksTitleStyle = {
  fontFamily: "Nunito",
  fontSize: "12px",
  fontWeight: 500,
};

export const DocumentsAndLinksDiscriptionStyle = {
  fontFamily: "Nunito",
  fontSize: "12px",
  fontWeight: 400,
};

export const DocumentsAndLinksTimeBoxStyle = {
  display: "flex",
  fontFamily: "Nunito",
  fontSize: "10px",
  fontWeight: 300,
};
