import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../components/theme";
import ModeIcon from "@mui/icons-material/Mode";
import { useState } from "react";

export enum Visible {
  all = "all",
  friends = "friends",
  me = "me",
}

export interface UserPrivacyData {
  contacts: Visible;
  basic: Visible;
  projects: Visible;
  stacks: Visible;
}

export const Confidentiality = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [contacts, setContacts] = useState(Visible.me);
  const [basic, setBasic] = useState(Visible.me);
  const [projects, setProjects] = useState(Visible.me);
  const [stacks, setStacks] = useState(Visible.me);

  const titleStyle = {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Zen Maru Gothic",
    lineHeight: "16px",
    color: colors.grey[200],
    mt: "4px",
    mb: "4px",
  };

  const textStyle = {
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "16px",
    color: colors.grey[200],
  };
  return (
    <>
      <Typography sx={titleStyle}>
        Конфиденциальность (не входит в MVP)
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          width: "845px",
          height: "246px",
          borderRadius: "8px",
          bgcolor: theme.palette.primary.dark,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "815px",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          <Typography
            sx={{
              marginLeft: "auto",
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: "16px",
              color: colors.grey[200],
            }}
          >
            Выберите данные о вас, которые будут видны другим пользователям.
          </Typography>
          <Link color={theme.palette.text.primary} marginLeft="auto">
            <ModeIcon />
          </Link>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplate: "40px repeat(4, 32px)/165px 130px 165px 130px",
            gap: "2px",
            alignItems: "center",
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          <Box></Box>
          <Typography
            sx={textStyle}
            textAlign="center"
            justifySelf="center"
            width={94}
          >
            Видны только мне
          </Typography>
          <Typography sx={textStyle} textAlign="center" justifySelf="center">
            Видны мне и моим друзьям
          </Typography>
          <Typography
            sx={textStyle}
            justifySelf="center"
            textAlign="center"
            width={52}
          >
            Видны всем
          </Typography>

          <Typography sx={textStyle}>Контактные данные</Typography>
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onChange={() => setContacts(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setContacts(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            value={Visible.all}
            control={<Checkbox onClick={() => setContacts(Visible.all)} />}
            label=""
          />
          <Typography sx={textStyle}>Основные данные</Typography>
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setBasic(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setBasic(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            value={Visible.all}
            control={<Checkbox onClick={() => setBasic(Visible.all)} />}
            label=""
          />
          <Typography sx={textStyle}>Данные о проектах</Typography>
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setProjects(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setProjects(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            value={Visible.all}
            control={<Checkbox onClick={() => setProjects(Visible.all)} />}
            label=""
          />

          <Typography sx={textStyle}>Мои навыки</Typography>
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setStacks(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            control={<Checkbox onClick={() => setStacks(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            sx={{
              alignContent: "center",
              justifyContent: "center",
              m: "0 auto",
            }}
            value={Visible.all}
            control={<Checkbox onClick={() => setStacks(Visible.all)} />}
            label=""
          />
        </Box>
      </Box>
    </>
  );
};
