import { Avatar, Box } from "@mui/material";
import { IProjectDocumentAndLink } from "../../../../model/projectData";
import {
  DocumentsAndLinksDiscriptionStyle,
  DocumentsAndLinksInnerBoxStyle,
  DocumentsAndLinksTimeBoxStyle,
  DocumentsAndLinksTitleStyle,
} from "./styles/DocumentsAndLinksStyles";
import { convertTimestampToTime } from "./TimeConvertHelper";

interface DocumentsAndLinksItemsProps {
  props: IProjectDocumentAndLink;
}

export const DocumentsAndLinksItems = ({
  props,
}: DocumentsAndLinksItemsProps) => {
  const propTime = props.postedTimestamp;
  const time = convertTimestampToTime(propTime);
  const userAvatar = "https://picsum.photos/200/300";
  return (
    <Box  sx={DocumentsAndLinksInnerBoxStyle}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignSelf: "center" }}>
          <Avatar
            src={userAvatar}
            alt={props.title}
            sx={{width: "38px",height: "38px"}}
          />
        </Box>
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <Box sx={DocumentsAndLinksTitleStyle}>{props.title}</Box>
          <Box sx={DocumentsAndLinksDiscriptionStyle}>{props.description}</Box>
        </Box>
      </Box>
      <Box sx={DocumentsAndLinksTimeBoxStyle}>{time}</Box>
    </Box>
  );
};
