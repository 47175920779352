import React from "react";
import { Box } from "@mui/material";
import { ProjectCreateButton } from "./ProjectCreateButton";
import { ProjectReturnButton } from "./ProjectReturnButton";

interface ProjectManagerButtonProps {
    handleCreateProject: ()=>void;
}

export const ProjectManagerButton = ({handleCreateProject}: ProjectManagerButtonProps)=> {
    return (
        <Box>
            <ProjectCreateButton
            handleClick={handleCreateProject}
            />
            <ProjectReturnButton/>
        </Box>
    )
}