import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { IProjectRolesSubskill } from "../../../../model/projectData";
import {
  buttonApplyStyle,
  buttonCancelStyle,
  iconCheckStyle,
  outterBox,
  titleStyle,
} from "./ButtonStyles";

interface SubSkillConfirmationButtonProps {
  subSkill: IProjectRolesSubskill;
  onConfirm: (subSkill: IProjectRolesSubskill) => void;
  onUnconfirm: (subSkill: IProjectRolesSubskill) => void;
  isSelected: boolean;
  instrumentGroup: string | null;
}

export const SubSkillConfirmationButton = ({
  subSkill,
  onConfirm,
  onUnconfirm,
  isSelected,
  instrumentGroup,
}: SubSkillConfirmationButtonProps) => {
  const handleSubSKillClick = () => {
    if (isSelected) {
      onUnconfirm(subSkill);
    } else {
      const updatedSubSkill = { ...subSkill, selected: true };
      onConfirm(updatedSubSkill);
    }
  };

  return (
    <Box sx={outterBox}>
      <Typography sx={titleStyle}>{subSkill.name}</Typography>
      {instrumentGroup == null ? (
        <CheckIcon sx={iconCheckStyle} />
      ) : isSelected ? (
        <Button
          sx={buttonCancelStyle}
          variant="outlined"
          size="small"
          onClick={() => handleSubSKillClick()}
        >
          Отменить
        </Button>
      ) : (
        <Button
          sx={buttonApplyStyle}
          variant="outlined"
          size="small"
          onClick={() => handleSubSKillClick()}
        >
          Выбрать
        </Button>
      )}
    </Box>
  );
};
