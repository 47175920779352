import { SVGProps } from "react";
const CheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    {...props}
  >
    <path
      fill="#148E00"
      d="M11.776 1.004c.275.245.3.666.056.942l-7.112 8a.667.667 0 0 1-.996 0l-3.556-4a.667.667 0 1 1 .997-.886L4.222 8.5l6.613-7.44a.667.667 0 0 1 .941-.056Z"
    />
  </svg>
);
export default CheckedIcon;
