import React from "react";
import { Box, Typography } from "@mui/material";
import { ProjectWorkingHoursItems } from "./ProjectWorkingHoursItems";
import {
  projectHoursBoxStyle,
  projectTitlesStyle,
} from "./ProjectHeaderStyles";

interface PrWorkingHoursProps {
  hoursCount: number;
  hoursOnCountChange: (newCount: number) => void;
}

export const PrWorkingHours = ({
  hoursCount,
  hoursOnCountChange,
}: PrWorkingHoursProps) => {
  return (
    <Box
      sx={{
        mt: "5px",
        ml: "20px",
      }}
    >
      <Typography sx={projectTitlesStyle}>Темп работы</Typography>
      <Box sx={projectHoursBoxStyle}>
        <ProjectWorkingHoursItems
          hoursCount={hoursCount}
          hoursOnCountChange={hoursOnCountChange}
        />
      </Box>
    </Box>
  );
};
