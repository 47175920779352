import React from "react";
import { Box, Typography } from "@mui/material";
import { PrManagerTitleStyle } from "../styles";

interface ProjectNameProps {
  currentName: string;
}

export const ProjectName = ({ currentName }: ProjectNameProps) => {
  const cuttedName =
    currentName.length > 15
      ? `${currentName.substring(0, 12)}...`
      : currentName;
  return (
    <Box
      sx={{
        display: "flex",
        mt: "15px",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <Typography sx={PrManagerTitleStyle}>Название проекта</Typography>
      </Box>
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <Typography
          sx={{
            mt: "20px",
            fontFamily: "Zen Maru Gothic",
            fontSize: "24px",
            fontWeight: 700,
          }}
        >
          {cuttedName}
        </Typography>
      </Box>
    </Box>
  );
};
