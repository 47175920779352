import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../components/theme";
// import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import NavBarHorizontal from "../../components/navBar/NavBarHorizontal";

export const MinimalLayout = () => {
  const [theme, colorMode] = useMode();

  // const isAutorized = useSelector((state: any) => state.auth.user);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBarHorizontal />
        <Outlet />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
