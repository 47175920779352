import React from "react";
import { Box, Button, Typography } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { projectButtonIconStyle, projectButtonTextStyle } from "./styles/ProjectButtonStyles";

export const ProjectApplicationButton = () => {
  return (
    <Button>
      <PersonAddIcon sx={projectButtonIconStyle} />
      <Typography sx={projectButtonTextStyle}>
Заявки
      </Typography>
    </Button>
  );
};