import React from "react";
import {
  Avatar,
  Box,
  IconButton,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useTheme } from "@emotion/react";
import {
  avatarIconButtonStyle,
  avatarListStyle,
  avatarRatingBoxStyle,
  avatarStyle,
  placeTestStyle,
  projectAvatarBoxStyle,
  ratingTextStyle,
} from "./styles/ProjectAvatarStyles";

export const ProjectAvatarRating = () => {
  const theme = useTheme();
  return (
    <Box sx={projectAvatarBoxStyle}>
      <ListItemAvatar sx={avatarListStyle}>
        <Avatar src={""} alt={""} sx={avatarStyle} />
        <IconButton onClick={() => {}} sx={avatarIconButtonStyle}>
          <CameraAltOutlinedIcon sx={{ color: "#82D9FF" }} />
        </IconButton>
        <Box sx={avatarRatingBoxStyle}>
          <Typography sx={ratingTextStyle}>100</Typography>
          <Typography sx={placeTestStyle}>место</Typography>
          <Typography sx={placeTestStyle}>в рейтинге</Typography>
        </Box>
      </ListItemAvatar>
    </Box>
  );
};
