import { Box, useTheme } from "@mui/material";
import { Shield } from "./components/shield/Shield";
import { Video } from "./components/video/Video";

export const HomePage = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100% - 84px)",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Shield />
      </Box>
      <Video />
    </>
  );
};
