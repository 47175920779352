import React, { useState } from "react";
import { Box, IconButton, Button, MenuItem, useTheme } from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import RoleMenu from "../RoleMenu";
import QuizModalWindow from "./components/QuizModalWindow";
import { useNavigate } from "react-router-dom";
import { ISubSkill, IRole } from "../../../../../model/UserData";
import { tokens } from "../../../../../../../components/theme";

interface JHSelectProps {
  fullStackList?: IRole[];
  selectedRole?: number;
  setSelectedRole: (roleId: number) => void;
  selectedSkill?: number;
  setSelectedSkill: (skillId: number) => void;
  selectedSubSkills: ISubSkill[];
  onRoleChange: (roleId: number) => void;
  onSkillChange: (skillId: number) => void;
}

export const JHSelect = ({
  fullStackList,
  selectedRole,
  setSelectedRole,
  selectedSkill,
  setSelectedSkill,
  selectedSubSkills,
  onRoleChange,
  onSkillChange,
}: JHSelectProps) => {
  const [roleMenuOpen, setRoleMenuOpen] = useState(false);
  const [skillMenuOpen, setSkillMenuOpen] = useState(false);
  const [subSkillId, setSubSkillId] = useState("");
  const [quizTitle, setQuizTitle] = useState("");
  const [quizWindowOpen, setQuizWindowOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const roleTitle = fullStackList?.find(
    (role) => role.id === selectedRole
  )?.name;

  const selectedRoleData = fullStackList?.find(
    (role) => role.id === selectedRole
  );

  const selectedSkillData =
    selectedRoleData?.skill.find((skill) => skill.id === selectedSkill) || null;

  const navigate = useNavigate();

  const boxBtnStyle = {
    width: "96px",
    color: theme.palette.secondary.main,
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "14px",
  };
  const btnStyle = {
    width: "94px",
    height: "26px",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    bgcolor: theme.palette.text.secondary,
    textTransform: "none",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "12px",
    "&:hover": {
      background: colors.blue[500],
    },
  };

  return (
    <Box
      sx={{
        position: "relative",
        minWidth: "190px",
        height: "265px",
      }}
    >
      <Box
        sx={{
          width: "190px",
          minHeight: "36px",
          bgcolor: colors.blue[50],
          color: theme.palette.primary.main,
          borderRadius: "4px",
          display: "flex",
          px: "7px",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          fontFamily: "Nunito",
          fontSize: "16px",
          fontWeight: 500,
        }}
        onClick={(event) => {
          event.stopPropagation();
          setRoleMenuOpen(!roleMenuOpen);
        }}
      >
        {selectedRole === undefined ? "Выберите роль" : roleTitle}
        <IconButton
          sx={{
            color: "black",
            width: "16px",
            height: "16px",
          }}
        >
          {roleMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      {roleMenuOpen && (
        <RoleMenu
          open={roleMenuOpen}
          selectedOption={selectedRole}
          options={fullStackList || []}
          onChange={(roleId) => {
            setSelectedRole(roleId);
            onRoleChange(roleId);
            setRoleMenuOpen(false);
          }}
          onOutsideClick={() => setRoleMenuOpen(false)}
        />
      )}

      {selectedRole !== undefined && (
        <Box
          sx={{
            mt: "10px",
            width: "190px",
            height: "25px",
            bgcolor: colors.blue[50],
            borderRadius: "4px",
            position: "relative",
            display: "flex",
            px: "7px",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: 400,
            color: theme.palette.primary.main,
          }}
          onClick={() => setSkillMenuOpen(!skillMenuOpen)}
        >
          {selectedSkill === undefined
            ? "Выберите Стек"
            : selectedSkillData?.name}

          <IconButton
            sx={{
              color: "black",
              width: "16px",
              height: "16px",
            }}
          >
            {skillMenuOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {skillMenuOpen && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                width: "100%",
                backgroundColor: "#fff",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
                zIndex: 1,
                fontFamily: "Nunito",
                color: "black",
              }}
            >
              {selectedRoleData?.skill.map((skill) => (
                <MenuItem
                  key={skill.id}
                  onClick={() => {
                    setSelectedSkill(skill.id);
                    onSkillChange(skill.id);
                    setSkillMenuOpen(false);
                  }}
                  selected={skill.id === selectedSkill}
                >
                  {skill.name}
                </MenuItem>
              ))}
            </Box>
          )}
        </Box>
      )}
      {selectedSkill !== undefined && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "190px",
            height: "26px",
            gap: "7px",
            mt: "7px",
          }}
        >
          <Box sx={boxBtnStyle}>{selectedSkillData?.name}</Box>
          {selectedSkillData && (
            <Button
              sx={btnStyle}
              onClick={() => {
                setSubSkillId(`${selectedSkillData?.id}`);
                setQuizWindowOpen(true);
                setQuizTitle(selectedSkillData.name);
              }}
            >
              Подтвердить
            </Button>
          )}
        </Box>
      )}

      {selectedSkill !== undefined && (
        <Box
          sx={{
            mt: "7px",
            height: "125px",
            width: "199px",
            overflow: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.blue[300],
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: colors.blue[500],
            },
          }}
        >
          <Box
            sx={{
              fontSize: "10px",
            }}
          ></Box>
          {selectedSkillData?.subSkills.map((subSkill, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "190px",
                height: "26px",
                gap: "7px",
                mt: "7px",
              }}
            >
              <Box sx={boxBtnStyle}>{subSkill.subSkillEntity.name}</Box>
              <Button
                sx={btnStyle}
                onClick={() => {
                  setQuizWindowOpen(true);
                  setSubSkillId(`${subSkill.id}`);
                  setQuizTitle(subSkill.name);
                }}
              >
                Подтвердить
              </Button>
            </Box>
          ))}
        </Box>
      )}
      {quizWindowOpen && (
        <QuizModalWindow
          open={quizWindowOpen}
          onClose={() => setQuizWindowOpen(false)}
          setSubSkillId={setSubSkillId}
          subSkillId={subSkillId}
          quizTitle={quizTitle}
        />
      )}
      {selectedSkillData && (
        <Button
          variant="outlined"
          sx={{
            width: "190px",
            height: "36px",
            border: "2px solid #00A1E7",
            borderRadius: "24px",
            color: theme.palette.text.primary,
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: "16px",
            textTransform: "none",
            mt: "10px",
          }}
          onClick={() => navigate("/projects")}
        >
          Искать Проекты
        </Button>
      )}
    </Box>
  );
};
