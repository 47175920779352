import { useState, useEffect } from "react";
import { Modal, Typography, TextField, Button, Box } from "@mui/material";
import { ITeamDoc } from "../../models/ITeamData";
import { useUpdateTeamDocumentMutation } from "../../../../services/teams";
import { useDeleteTeamDocumentMutation } from "../../../../services/teams";
import Alert from '@mui/material/Alert';

interface DocumentModalProps {
  open: boolean;
  onClose: () => void;
  document: ITeamDoc;
  onSave: () => void;
  onDelete: () => void;
  teamId: number;
}

const DocumentModal = ({
  open,
  onClose,
  document,
  onSave,
  onDelete,
  teamId,
}: DocumentModalProps) => {
  const [name, setName] = useState(document.name);
  const [description, setDescription] = useState(document.description);
  const [avatar, setAvatar] = useState(document.avatar);
  const [updateDocument] = useUpdateTeamDocumentMutation();
  const [deleteDocument] = useDeleteTeamDocumentMutation();

  useEffect(() => {
    if (open) {
      setName(document.name);
      setDescription(document.description);
      setAvatar(document.avatar);
    }
  }, [open, document]);

  const handleSave = async () => {
    try {
    const updatedDocument: ITeamDoc = {
      ...document,
      avatar,
      name,
      description,
    };

    await updateDocument({
      id: teamId,
      document: updatedDocument,
    });

    onSave();
    onClose();
  } catch (error) {
    <Alert severity="error">
      Не удалось обновить документ команды !
    </Alert>
  }
  };

  const handleDelete = async () => {
    try {
    await deleteDocument({
      id: teamId,
      document: document,
    });

    onDelete();
    onClose();
  } catch(error) {
    <Alert severity="error">
      Не удалось удалить документ команды !
    </Alert>
  }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Документы и ссылки</Typography>
        </Box>
        <TextField
          label="Ссылка на аватар"
          value={avatar}
          onChange={(e) => setAvatar(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Название"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Описание"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Сохранить
          </Button>
          <Box sx={{ mx: 2 }} />
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleDelete}
          >
            Удалить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentModal;
