import { Box } from "@mui/material";
import { AllTeamsSearch } from "../components/AllTeamsSearch";
import { AllTeamsCards } from "../components/AllTeamsCards";
import { useGetAllTeamsQuery } from "../../../services/teams";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ITeamData } from "../models/ITeamData";
import { TeamsNavigation } from "../components/TeamsNavigation";
import { useTheme } from "@mui/material";

export const AllTeamsPage = () => {
  const navigate = useNavigate();
  const { data: allTeamsData, isLoading } = useGetAllTeamsQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTeams, setFilteredTeams] = useState<ITeamData[]>([]);
  const theme = useTheme();

  if (!allTeamsData || allTeamsData.length === 0) {
    return (
      <Box>
        <TeamsNavigation
          activeButton="teams"
          onButtonClick={(buttonName) => {
            if (buttonName === "my-teams") {
              navigate("/teams/my-teams");
            }
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: "700",
              fontSize: "16px",
              textAlign: "center",
              mb: "4",
            }}
          >
            Пока у нас нет ни одной команды. Создайте первую команду :)
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                alignSelf: "flex-end",
              }}
              onClick={() => navigate("/teams/new-team")}
            >
              Создать новую команду
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    const filteredTeams = allTeamsData.filter((team) => {
      const lowercaseName = team.name.toLowerCase();
      return lowercaseName.includes(lowercaseSearchTerm);
    });
    setFilteredTeams(filteredTeams);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <TeamsNavigation
        activeButton="teams"
        onButtonClick={(buttonName) => {
          if (buttonName === "my-teams") {
            navigate("/teams/my-teams");
          }
        }}
      />
      <Box sx={{ mt: "50px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AllTeamsSearch 
          allTeams={allTeamsData} 
          onSearch={handleSearch}
          onChange={(searchTerm) => handleSearch(searchTerm)}
          />
          <Button
            sx={{
              width: "292px",
              py: "2px",
              color: theme.palette.text.disabled,
              backgroundColor: theme.palette.info.light,
              borderRadius: "16px",
              fontFamily: "Zen Maru Gothic",
              fontWeight: 700,
              fontSize: "16px",
            }}
            onClick={() => navigate("/teams/new-team")}
          >
            Создать новую команду
          </Button>
        </Box>

        <AllTeamsCards
          allTeams={filteredTeams.length > 0 ? filteredTeams : allTeamsData}
        />
      </Box>
    </Box>
  );
};
