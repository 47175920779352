import React  from "react";
import { Box, Typography } from "@mui/material";
import { ITeamData } from "../../models/ITeamData";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material";

interface TeamNavBarProps {
  teams: ITeamData[];
  currentTeamId?: number;
  onTeamClick: (team: ITeamData) => void;
}
export const TeamNavBar = ({
  teams,
  currentTeamId,
  onTeamClick,
}: TeamNavBarProps) => {
  const handleTeamClick = (team: ITeamData) => {
    onTeamClick(team);
  };

  const defaultTeam = teams[0];
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "32px",
        border: "1px",
        borderStyle: "solid",
        borderColor: "black",
        borderRadius: "8px",
      }}
    >
      <Tabs
        sx={{
         
          "& .MuiTabs-indicator": {
            display: "none",
          },
          ".MuiTabs-scrollButtons": {
            width: "20px",
            height: "30px",
          },
          ".MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 0.3,
          }
        }}
        value={currentTeamId !== undefined ? currentTeamId : defaultTeam.id}
        onChange={(event, teamId) =>
          handleTeamClick(teams.find((team) => team.id === teamId)!)
        }
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        
      >
        {teams.map((team) => (
          <Tab
            sx={{
              backgroundColor:
                (currentTeamId === undefined && team.id === defaultTeam.id) ||
                currentTeamId === team.id
                  ? theme.palette.secondary.light
                  : "",
              color:
                (currentTeamId === undefined && team.id === defaultTeam.id) ||
                currentTeamId === team.id
                  ? "black"
                  : "",
              borderRadius: "8px",
              padding: "2.7px 10px",
              height: "100%",
              minHeight: "auto",
              whiteSpace: "nowrap",
            }}
            key={team.id}
            value={team.id}
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontFamily: "Nunito",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  variant="subtitle1"
                >
                  Команда
                </Typography>
                <Box sx={{ width: 8 }} />
                <Typography
                  sx={{
                    color: theme.palette.text.primary,
                    fontFamily: "Nunito",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                  variant="subtitle1"
                >
                  {team.name}
                </Typography>
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};
