import { useState, useEffect } from "react";
import { Modal, Typography, TextField, Button, Box } from "@mui/material";
import { ITeamMember } from "../../models/ITeamData";
import {
  useDeleteTeamMemberMutation,
  useUpdateTeamMemberMutation,
} from "../../../../services/teams";
import Alert from '@mui/material/Alert';

interface TeamMemberModalProps {
  open: boolean;
  onClose: () => void;
  member: ITeamMember;
  onSave: () => void;
  onDelete: () => void;
  teamId: number;
}

const TeamMemberModal = ({
  open,
  onClose,
  member,
  onSave,
  onDelete,
  teamId,
}: TeamMemberModalProps) => {
  const [role, setRole] = useState(member.role);
  const [updateMember] = useUpdateTeamMemberMutation();
  const [deleteMember] = useDeleteTeamMemberMutation();

  useEffect(() => {
    if (open) {
      setRole(member.role);
    }
  }, [open, member]);

  const handleSave = async () => { try {

  
    const updatedMember: ITeamMember = {
      ...member,
      role,
    };

    await updateMember({
      id: teamId,
      member: updatedMember,
    });

    onSave();
    onClose();
  } catch (error) {
      <Alert severity="error">
        Не удалось обновить роль члена команды !
      </Alert>
  };
  
  };

  const handleDelete = async () => {
    try{
    await deleteMember({
      id: teamId,
      member: member,
    });

    onDelete();
    onClose();
  } catch(error) {
    <Alert severity="error">
      Не удалось удалить члена команды !
    </Alert>
  }
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Редактировать члена команды</Typography>
        </Box>

        <TextField
          label="Введите новую роль"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Сохранить
          </Button>
          <Box sx={{ mx: 2 }} />
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleDelete}
          >
            Удалить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TeamMemberModal;
