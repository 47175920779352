import { Box } from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ViewAllItemsButtonStyle } from "./styles/WorkingPanelStyles";

interface ViewAllButtonProps {
    isExpanded: boolean;
    onClick: ()=> void;
}

export const ViewAllItemsButton = ({isExpanded, onClick}: ViewAllButtonProps) => {
    return (
        <Box onClick={onClick} sx={ViewAllItemsButtonStyle} >
            {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </Box>
    )
}