import React, { useMemo, useState } from "react";
import { ITeamData } from "../models/ITeamData";
import { Grid } from "@mui/material";
import TeamInfoModal from "./modal/TeamInfoModal";
import { AllTeamsCardItems } from "./AllTeamsCardItems";

interface AllTeamsCardsProps {
  allTeams: ITeamData[];
}

export const AllTeamsCards = ({ allTeams }: AllTeamsCardsProps) => {
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [selectedTeamLeaderName, setSelectedTeamLeaderName] =
    useState<string>("");

  const memoizedTeams = useMemo(() => {
    return allTeams.map((team) => {
      const leader = team.members.find((member) => member.isTeamLeader);
      const leaderName = leader ? leader.name : "";
      

      return (
        <Grid
          container
          columnSpacing={1}
          rowSpacing={"10px"}
          item
          key={team.id}
          xs={12}
          sm={6}
          md={4}
          lg={4}
        >
          <AllTeamsCardItems
            teamId={team.id}
            avatar={team.avatar}
            name={team.name}
            projects={team.projects.length}
            members={team.members.length}
            rating={team.rating}
            leaderAvatar={leader?.avatar || ""}
            leaderName={leaderName}
            leaderRole="Project manager"
            onClick={() => {
              setSelectedTeamId(team.id);
              setSelectedTeamLeaderName(leaderName);
            }}
          />
        </Grid>
      );
    });
  }, [allTeams]);

  const handleModalClose = () => {
    setSelectedTeamId(null);
  };

  const selectedTeam = allTeams.find((team) => team.id === selectedTeamId);

  return (
    <div>
      <Grid container spacing={2}>
        {memoizedTeams}
      </Grid>
      {selectedTeam && (
        <TeamInfoModal
          open={selectedTeamId !== null}
          onClose={handleModalClose}
          avatar={selectedTeam.avatar}
          name={selectedTeam.name}
          rating={selectedTeam.rating}
          leaderName={selectedTeamLeaderName}
          description={selectedTeam.description}
        />
      )}
    </div>
  );
};

// export const AllTeamsCards = ({ allTeams }: AllTeamsCardsProps) => {
//     const memoizedTeams = useMemo(() => {
//       return allTeams.map((team) => (

//         <Grid item key={team.id} xs={12} sm={6} md={4} lg={4}>
//           <AllTeamsCardItems
//             avatar={team.avatar}
//             name={team.name}
//             projects={team.projects.length}
//             members={team.members.length}
//             rating={team.rating}
//             leaderAvatar=""
//             leaderName="11111"
//             leaderRole="2222"
//           />
//         </Grid>
//       ));
//     }, [allTeams]);

//     return (
//       <Grid container spacing={2}>
//         {memoizedTeams}
//       </Grid>
//     );
//   };
