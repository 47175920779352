import React from "react";
import { ListItem } from "@mui/material";
import { Avatar } from "@mui/material";
import { ListItemText } from "@mui/material";
import { useTheme } from "@mui/material";

interface TeamProjectsItemsProps {
  id: number;
  name: string;
  status: string;
  avatar: string;
}

export const TeamProjectsItems = ({
  name,
  status,
  avatar,
}: TeamProjectsItemsProps) => {

  const theme = useTheme();

  return (
    <ListItem
      sx={{
        p: 0,
        marginBottom: 2.5,
        maxHeight: 64,
        display: "flex",
        flexDirection: "row",
        width: "236px"
      }}
    >
      <Avatar
        src={avatar}
        alt={name}
        sx={{
          width: 64,
          height: 64,
        }}
      />

      <ListItemText
        sx={{ pl: 1.5 }}
        primary={name}
        secondary={status}
        primaryTypographyProps={{
          fontStyle: "Nunito",
          fontSize: "16px",
          fontWeight: 500,
          color: theme.palette.text.primary,
        }}
        secondaryTypographyProps={{
          fontStyle: "Nunito",
          fontSize: "14px",
          fontWeight: 400,
          color: theme.palette.text.primary,
        }}
      />
    </ListItem>
  );
};
