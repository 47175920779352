import React from "react";
import { Box, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { projectNameBoxStyle } from "../../../../prCreate/component/PrCreateHeader/components/ProjectHeaderStyles";
import {
  projectNameInnerTitleStyle,
  projectNameOutterBoxStyle,
  projectNameTitleStyle,
  projectPaceIconStyle,
  projectPaceInnerBoxStyle,
  projectPaceInnerTextStyle,
  projectPaceTextStyle,
} from "./styles/ProjectNameAndPaceStyles";

interface PrNameandTempProps {
  projectName?: string;
  hours?: number;
}

export const ProjectNameandPace = ({
  projectName,
  hours,
}: PrNameandTempProps) => {
  return (
    <Box sx={projectNameOutterBoxStyle}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={projectNameTitleStyle}>Название проекта</Typography>
        <Typography sx={projectNameInnerTitleStyle}>{projectName}</Typography>
        <Typography sx={projectPaceTextStyle}>Темп работы</Typography>
        <Box sx={projectPaceInnerBoxStyle}>
          <AccessTimeIcon sx={projectPaceIconStyle} />
          <Typography sx={projectPaceInnerTextStyle}>
            {hours} часов в неделю
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
