import React, { useEffect, useRef } from "react";
import { Box, MenuItem } from "@mui/material";

interface SortMenuProps {
  open: boolean;
  selectedOption: string;
  options: string[];
  onChange: (option: string) => void;
  onOutsideClick: () => void;
}

const SortMenu = ({
  open,
  selectedOption,
  options,
  onChange,
  onOutsideClick,
}: SortMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!menuRef.current || !menuRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open, onOutsideClick]);

  const handleListMouseDown = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <Box
      ref={menuRef}
      sx={{
        position: "absolute",
        top: "100%",
        left: 0,
        width: "270px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: 1,
        fontFamily: "Nunito",
        color: "black",
      }}
      onMouseDown={handleListMouseDown}
    >
      <MenuItem
        onClick={() => onChange("new")}
        selected={"new" === selectedOption}
      >
        Сначала новые
      </MenuItem>
      <MenuItem
        onClick={() => onChange("old")}
        selected={"old" === selectedOption}
      >
        Сначала старые
      </MenuItem>
      <MenuItem
        onClick={() => onChange("completed")}
        selected={"completed" === selectedOption}
      >
        Сначала завершенные
      </MenuItem>
      <MenuItem
        onClick={() => onChange("abc")}
        selected={"abc" === selectedOption}
      >
        По алфавиту
      </MenuItem>
    </Box>
  );
};

export default SortMenu;
