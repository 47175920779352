import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box, Button, Link, useTheme } from "@mui/material";
import {
  createButtonStyles,
  navigationBarOutterBoxStyles,
} from "./PrNavBarStyles";

interface PrNavBarProps {
  onTabChange: (tab: string) => void;
}

const pagesUser = [
  { title: "найти проект", url: "/projects" },
  { title: "мои проекты", url: "/projects/myprojects" },
  { title: "избранное", url: "/projects/selected" }
];

export default function PrNavBar({ onTabChange }: PrNavBarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isActive = (url: string) => {
    return location.pathname === url
      ? {
          fontFamily: "Nunito",
          fontSize: "16px",
          color: theme.palette.secondary.main,
          fontWeight: 400,
          border: "none",
          backgroundColor: theme.palette.info.main,
          borderRadius: "8px",
          padding: "8px 16px",
          textDecoration: "none",
        }
      : {
          fontFamily: "Nunito",
          fontSize: "16px",
          color: theme.palette.secondary.main,
          fontWeight: 600,
          border: "none",
          backgroundColor: "transparent",
          borderRadius: "8px",
          padding: "8px 16px",
          textDecoration: "none",
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
        };
  };

  const handleCreateProject = () => {
    navigate(`/projects/create`);
  };

  return (
    <Box sx={navigationBarOutterBoxStyles}>
      <Box>
        {pagesUser.map((item) => (
          <Link
            key={item.url}
            to={item.url}
            component={NavLink}
            sx={{
              ...isActive(item.url),
              color: "inherit",
              textTransform: "uppercase",
            }}
            onClick={() => onTabChange(item.url.replace("/projects/", ""))}
          >
            {item.title}
          </Link>
        ))}
      </Box>
      <Box>
        <Button onClick={handleCreateProject} sx={createButtonStyles}>
          Создать новый проект
        </Button>
      </Box>
    </Box>
  );
}
