import { Grid } from "@mui/material";
import CurrentTasks from "./currentTasks/CurrentTasks";
import DailyMess from "./dailyMess/DailyMess";
import ProgressTable from "./progressTable/ProgressTable";
import WhileAway from "./whileAway/WhileAway";

// Пока не определен формат данных из API, только разбивка компонентов, без полей и состояний

export const LkWelcome = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "44px",
      }}
    >
      <Grid item lg={8} md={8} xs={12}>
        <DailyMess />
      </Grid>
      <Grid item lg={4} md={4} xs={12}>
        <ProgressTable />
      </Grid>

      <Grid item lg={8} md={12} xs={12}>
        <WhileAway />
      </Grid>
      <Grid item lg={4} md={12} xs={12}>
        <CurrentTasks />
      </Grid>
    </Grid>
  );
};
