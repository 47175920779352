export const projectAvatarBoxStyle = {
    display: "flex",
    justifyContent: "space-around",
    mt: "20px",
  }

  export const avatarListStyle = {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  }

  export const avatarStyle = {
    color: "#2E3153",
    bgcolor: "#82D9FF",
    width: "90px",
    height: "90px",
  }

  export const avatarIconButtonStyle = {
    alignSelf: "flex-end",
    width: "26px",
    height: "26px",
    position: "absolute",
    left: "85px",
    top: "60px",
  }

  export const avatarRatingBoxStyle = {
    position: "absolute",
    left: "115px",
    bottom: "50px",
    width: "65px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }

  export const ratingTextStyle = {
    mb: "2px",
    color: "white",
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "12px",
  }

  export const placeTestStyle = {
    color: "white",
    fontFamily: "Nunito",
    fontWeight: 200,
    fontSize: "12px",
  }