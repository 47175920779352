import React from "react";
import { Button, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { projectButtonIconStyle, projectButtonTextStyle } from "./styles/ProjectButtonStyles";
import { Link, useParams } from "react-router-dom";

export const ProjectEditButton = () => {
  const {id} = useParams();

  return (
    <Link to={`/projects/create/${id}`}>
    <Button>
      <DateRangeIcon sx={projectButtonIconStyle} />
      <Typography sx={projectButtonTextStyle}>
Редактирование
      </Typography>
    </Button>
    </Link>
  );
};
