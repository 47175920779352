import { createSlice } from "@reduxjs/toolkit";

export interface User {
  id: number;
  login: string;
}

export type Auth = { user: User }

const initialState: Auth = {
  user: localStorage && localStorage.user ? JSON.parse(localStorage.user) : undefined,
}

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setAutorized: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      localStorage.user = JSON.stringify(user)
    },
  }
})

export const { setAutorized } = authSlice.actions;

export const authReducer = authSlice.reducer;
