import { Box, useTheme, Stack } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { IUserData } from "../../../model/UserData";
import { tokens } from "../../../../../components/theme";
import { InfoBaseBasic } from "./components/InfoBaseBasic";
import { SettingIcon } from "../../../assets/icons/SettingIcon";
import { CopyIcon } from "../../../assets/icons/CopyIcon";
import styles from "./InfoBase.module.css";
interface IInfoBaseProps {
  userData: IUserData;
  isLoading?: boolean;
}

export const InfoBase = ({ userData }: IInfoBaseProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const contacts = userData.listContacts;
  const socials = userData.listSocial;

  return (
    <>
      <Box>
        <InfoBaseBasic key={userData.id} userData={userData} />
        <Stack
          sx={{
            maxHeight: "260px",
            fontFamily: "Nunito",
            marginTop: "10px",
          }}
        >
          <Box mt="10px">
            <Box
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: colors.grey[200],
              }}
            >
              Мессенджеры:
            </Box>
            <Box
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: colors.grey[200],
              }}
            >
              {contacts.map((contact, index) => (
                <Box key={index}>
                  <> {contact}</>
                </Box>
              ))}
            </Box>
          </Box>
          <Box mt="6px">
            <Box
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: colors.grey[200],
              }}
            >
              Мои сети:
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {socials.map((social, index) => (
                <Link key={index} to="/" className={styles.link}>
                  <>{social}</>
                </Link>
              ))}
            </Box>
          </Box>
          <Box mt="6px">
            <Box
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: colors.grey[200],
              }}
            >
              О себе
            </Box>
            <Box
              sx={{
                mt: "3px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: colors.grey[200],
              }}
            >
              {userData.bio}
            </Box>
            <Box
              mt="6px"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: colors.grey[200],
              }}
            >
              {userData.email}
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{
            mt: "10px",
            display: "grid",
            gap: "4px",
            gridTemplateColumns: "max-content max-content",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <SettingIcon />
          <Box
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "16px",
              color: colors.grey[500],
            }}
          >
            <Link
              className={styles.link_s}
              to="/profile/edit"
            >
              Настройки
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "6px",
            display: "grid",
            gap: "4px",
            gridTemplateColumns: "max-content max-content",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <CopyIcon />
          <Link to="/profile" className={styles.link_s}>
            Копировать ссылку на профиль
          </Link>
        </Box>
      </Box>
    </>
  );
};
