import { Alert, AlertTitle } from "@mui/material";

export default function Error() {
  return (
    <Alert severity="error">
      <AlertTitle>Ошибка</AlertTitle>
      Что-то пошло не так... <strong>Повторите попытку позже</strong>
    </Alert>
  );
}
