import { useRoutes } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { protectedRoutes }  from "./protected";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
  const user = useAppSelector((state) => state.auth.user);

  // const commonRoutes = [{ path: '/', element: <Landing /> }];
  // const commonRoutes = [{ path: '/', element: <MinimalLayout /> }];

  const routes = user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);
  return <>{element}</>;
};  