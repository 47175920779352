import { Box, Typography } from "@mui/material";

import { LoginForm } from "./components/LoginForm";

export const Login = () => {

  return (
    <Box
      sx={{
        height: "auto",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1" fontSize="32px">
        Войти в аккаунт
      </Typography>
      <LoginForm />
    </Box>
  );
};
