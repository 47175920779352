import { Box, Button, Typography } from "@mui/material";
import { useGetTasksQuery } from "../../services/tasks";
import { Task } from "./components/Task";
import { useEffect, useState } from "react";

export const WorkZone = () => {
  const { data } = useGetTasksQuery(1);
  const [statuses, setStatuses] = useState(data)

  useEffect(() => {
    setStatuses(data);
  }, [data]);

  // TODO:
  // редактирование тегов - всплывашка
  // все равно показывает пустые теги

  if (statuses)
    return (
      <Box>
        <Box width={212} textAlign={"center"}>
          <Typography>Проект XXX</Typography>
          <Typography>Смотреть детали проекта</Typography>
          <Typography>Фильтр Теги Доска</Typography>
          <Typography>Дорожная карта</Typography>
          <Button>Создать</Button>
        </Box>
        <Box display="flex" flexDirection="row" gap={"24px"}>
          {statuses.map((column: any) => (
            <Box
              display="flex"
              flexDirection="column"
              key={column.name}
              alignItems="center"
            >
              {column.name}
              {column.tasks.map((task: any) => (
                <Box key={task.id}>
                  <Task item={task} board={column} setStatuses={setStatuses} statuses={statuses}/>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  return <></>;
};
