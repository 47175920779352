import React from "react"

export const titleStyle = {
    fontFamily: "Zen Maru Gothic",
    fontSize: "20px",
    fontWeight: 700,
  }

  export const projectTitlesStyle = {
    fontFamily: "Zen Maru Gothic",
    fontSize: "16px",
    fontWeight: 700,
  }

  export const projectItemStyle = {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
  }

  export const projectNameBoxStyle = {
    width: "835px",
    minHeight: "50px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
  }

  export const projectContactBoxStyle = {
    width: "405px",
    mt: "5px",
    minHeight: "80px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
  }

  export const projectHoursBoxStyle = {
    display: "flex",
    width: "405px",
    mt: "5px",
    minHeight: "80px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
    alignItems: "center",
  }

  export const projectDiscriptionBoxStyle ={
    width: "405px",
    mt: "5px",
    minHeight: "195px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
  }

  export const discriptionPropsStyle = {
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#2196F3",
      borderRadius: "10px",
    },
  }