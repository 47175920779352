import React from "react";
import { Box, ListItemAvatar, Avatar } from "@mui/material";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

export const ProjectAvatar = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around"
      }}
    >
      <ListItemAvatar
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
         
        }}
      >
        <Avatar
          src={""}
          alt={""}
          sx={{
            color: "#2E3153",
            bgcolor: "#00A1E7",
            width: "90px",
            height: "90px",
          }}
        />
        <IconButton
          onClick={() => {}}
          sx={{
            alignSelf: "flex-end",
            width: "26px",
            height: "26px",
            position: "absolute",
            left: "60px",
            top: "70px",
          }}
        >
          <CameraAltOutlinedIcon sx={{ color: "#82D9FF" }} />
        </IconButton>
      </ListItemAvatar>
    </Box>
  );
};
