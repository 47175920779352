import {
  Box,
  Button,
  Input,
  IconButton,
  Typography,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useCreateNewTeamMutation } from "../../../services/teams";
import { ITeamData } from "../models/ITeamData";
import { useNavigate } from "react-router-dom";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import TeamAvatarModal from "../components/modal/TeamAvatarModal";

export const NewTeamPage = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState("");
  const [createNewTeam] = useCreateNewTeamMutation();
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const handleSave = async (selectedAvatar: string) => {
    setAvatar(selectedAvatar);
    setOpenAvatarModal(false);
  };

  const handleCancel = () => {
    setOpenAvatarModal(false);
  };

  const handleCloseModal = () => {
    setOpenAvatarModal(false);
  };

  const handleCreateTeam = async () => {
    try {
      if (name.trim() === "" || description.trim() === "") {
        alert("Пожалуйста, заполните все обязательные поля");
        return;
      }

      const newTeam = {
        name,
        description,
        rating: 0,
        avatar,
        members: [],
        documents: [],
        projects: [],
      } as Omit<ITeamData, "id">;

      await createNewTeam({
        team: newTeam,
      });

      navigate("/teams/my-teams");
    } catch (error) {
      alert("Не удалось создать команду!");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          ml: "200px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: "24px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontWeight: 700,
              fontSize: "28px",
            }}
          >
            Новая Команда
          </Typography>

          <Typography
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontWeight: 700,
              fontSize: "24px",
              mt: "25px",
            }}
          >
            Название
          </Typography>
          <Box
            sx={{
              mt: "6px",
              display: "flex",
              alignItems: "center",
              width: "490px",
              height: "33px",
              borderRadius: "8px",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <Input
              disableUnderline
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{
                style: {
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
                },
              }}
              sx={{
                flex: 1,
                height: "100%",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                pl: "7px",
                pt: "4px",
                input: {
                  "&::placeholder": {
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "black",
                    opacity: 1,
                  },
                },
              }}
            />
            <IconButton
              sx={{
                marginLeft: "8px",
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "22px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "760px",
            minHeight: "423px",
            backgroundColor: "#2E3153",
            borderRadius: "8px",
            pt: "34px",
            pl: "40px",
            pr: "40px",
            pb: "36px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ListItemAvatar
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                }}
              >
                <Avatar
                  src={avatar}
                  alt={name}
                  sx={{
                    color: "rgba(148, 148, 148, 1)",
                    bgcolor: "rgba(148, 148, 148, 1)",
                    width: "100px",
                    height: "100px",
                  }}
                />
                <IconButton
                  onClick={() => setOpenAvatarModal(true)}
                  sx={{
                    alignSelf: "flex-end",

                    position: "absolute",
                    left: "95px",
                  }}
                >
                  <CreateOutlinedIcon
                    sx={{
                      color: "#D6F3FF",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </IconButton>
                <TeamAvatarModal
                  open={openAvatarModal}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  onClose={handleCloseModal}
                />
              </ListItemAvatar>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Zen Maru Gothic",
                  fontWeight: 700,
                  fontSize: "24px",
                  color: "white",
                  mt: "58px",
                }}
              >
                Описание Команды
              </Typography>
            </Box>
            <Box
              sx={{
                mt: "17px",
                display: "flex",
                alignItems: "center",
                width: "520px",
                height: "175px",
                borderRadius: "8px",
                border: "1px solid black",
                backgroundColor: "white",
              }}
            >
              <Input
                disableUnderline
                multiline
                rows={5}
                type="text"
                placeholder=""
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputProps={{
                  style: {
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "black",
                  },
                }}
                sx={{
                  flex: 1,
                  height: "100%",
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  pl: "7px",
                  pt: "4px",
                  input: {
                    "&::placeholder": {
                      fontFamily: "Nunito",
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "black",
                      opacity: 1,
                    },
                  },
                }}
              />
              <IconButton
                sx={{
                  marginLeft: "8px",
                  alignSelf: "end",
                }}
              >
                <NearMeIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: "20px",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleCreateTeam}
          sx={{
            mt: "30px",
            justifySelf: "center",
            width: "315px",
            height: "40px",
            color: "black",
            backgroundColor: "#D0D0D0",
            borderRadius: "16px",
            fontFamily: "Zen Maru Gothic",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          Создать Команду
        </Button>
      </Box>
    </Box>
  );
};
