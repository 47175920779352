import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface ProjectCreateButtonProps {
    handleClick: ()=> void;
}

export const ProjectCreateButton = ({handleClick}: ProjectCreateButtonProps) => {

    
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: "50px",
      }}
    >
      <Box>
        <Button
          sx={{
            color: "white",
          }}
          onClick={handleClick}
        >
          <DateRangeIcon />
          <Typography
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontSize: "16px",
              fontWeight: 700,
              ml: "5px",
            }}
          >
            Сохранить проект
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
