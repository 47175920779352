import React, { useState } from "react";
import Alert from "@mui/material/Alert";

import {
  Modal,
  Typography,
  Button,
  Box,
  ListItem,
  Avatar,
  ListItemText,
} from "@mui/material";
import { ITeamMember } from "../../models/ITeamData";
import { useAddTeamMemberMutation } from "../../../../services/teams";
import { useGetUserFriendsQuery } from "../../../../services/user";
import { IFriends } from "../../../lk/model/UserData";
//import { userApi } from "../../../../services/user";

interface AddTeamMemberProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  teamId: number;
}
interface IUserFriends {}

const AddTeamMemberModal = ({
  open,
  onClose,
  onSave,
  teamId,
}: AddTeamMemberProps) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [avatar, setAvatar] = useState("");
  const [addMember] = useAddTeamMemberMutation();
  const { data: userFriends } = useGetUserFriendsQuery("");

  const handleSave = async () => {
    try {
      const newMember = {
        name,
        role,
        avatar,
        isTeamLeader: false,
      } as ITeamMember;

      await addMember({
        id: teamId,
        member: newMember,
      });

      onSave();
      onClose();
    } catch (error) {
      <Alert severity="error">Не удалось добавить нового члена команды!</Alert>;
    }
  };

  const handleCancel = () => {
    setName("");
    setRole("");
    setAvatar("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: "32px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box>
          <Typography variant="h6">Добавить нового участника</Typography>
        </Box>
        {/* {userFriends &&
          userFriends.map((friend: IFriends) => (
            <ListItem
              key={friend.userid}
              sx={{
                p: 0,
                mb: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Avatar
                src={friend.avatar}
                alt={friend.friendFullName}
                sx={{
                  width: "64px",
                  height: "64px",
                }}
              />

              <ListItemText
                sx={{
                  pl: "25px",
                }}
                primary={friend.friendFullName}
                secondary={friend.role}
                primaryTypographyProps={{
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                }}
                secondaryTypographyProps={{
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
                }}
              />

              <Button
                sx={{
                  width: "191px",
                  borderRadius: "44px",
                  backgroundColor: "#82D9FF",
                  mt: "18px",
                }}
                variant="contained"
                onClick={() => handleSave()}
              >
                Добавить
              </Button>
            </ListItem>
          ))} */}

        <Button
          sx={{
            width: "191px",
            borderRadius: "44px",
            backgroundColor: "#82D9FF",
          }}
          variant="contained"
          onClick={handleCancel}
        >
          Отмена
        </Button>
      </Box>
    </Modal>
  );
};

export default AddTeamMemberModal;
