export function getStatusDetails(status) {
  let color = "";
  let tooltipText = "";

  if (status === "Posted") {
    color = "#0E44D0";
    tooltipText = "Опубликован";
  } else if (status === "In progress") {
    color = "#0ED050";
    tooltipText = "В работе";
  } else if (status === "Done") {
    color = "#969696";
    tooltipText = "Проект завершен";
  } else if (status === "Not Posted") {
    color = "orange";
    tooltipText = "Проект не опубликован";
  }

  return { color, tooltipText };
}
