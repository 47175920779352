import { Typography, Box, Button, useTheme } from "@mui/material";

export default function TaskItem() {
  // добавить в пропсах свойства
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "22px",
        backgroundColor: theme.palette.primary.dark,
        width: "auto",
        height: "auto",
        borderRadius: "24px",
        textAlign: "start",
        padding: { lg: "10px 24px", md: "10px 24px", xs: "2% 4%" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Название проекта</Typography>
        <Typography>Дата</Typography>
      </Box>
      <Box>
        <Typography>
          Название задачи или начало ее текста. Длинный текст - скрывается часть
          скрывается многоточием.
        </Typography>
        <Typography>
          Вторая задача по этому же проекту.Думаю, что 2 строчки - оптимальный
          вариант.
        </Typography>
        <Typography>
          Третья задача. Всего 3 задачи на плашку проекта. Если задач больше -
          надо уже идти в сам проект.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            fontFamily: "Nunito",
            fontWeight: "400",
            fontSize: "14px",
            textTransform: "none",
            color: theme.palette.text.secondary,
          }}
          href="#text-buttons"
        >
          Еще
        </Button>
      </Box>
    </Box>
  );
}
