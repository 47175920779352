import React from "react";
import { Box } from "@mui/material";
import { ProjectEditButton } from "./ProjectEditButton";
import { ProjectAgileButton } from "./ProjectAgileButton";
import { ProjectApplicationButton } from "./ProjectApplicationButton";
import { projectButtonOutterBoxStyle } from "./styles/ProjectButtonStyles";

export const ProjectButtons = () => {
  return (
    <Box sx={projectButtonOutterBoxStyle}>
      <ProjectEditButton />
      <ProjectAgileButton />
      <ProjectApplicationButton/>
    </Box>
  );
};
