import React from "react";
import { Modal, Typography, Box, Avatar } from "@mui/material";

interface TeamInfoModalProps {
  open: boolean;
  onClose: () => void;
  
  avatar: string;
  name: string;
  rating: number;
  leaderName: string;
  description: string;
}

const TeamInfoModal = ({
  open,
  onClose,
  
  avatar,
  name,
  rating,
  leaderName,
  description,
}: TeamInfoModalProps) => {
  return (
    
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              width: "584px",
              minHeight: "279px",
              borderRadius: "40px",
              backgroundColor: "#D6F3FF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 4,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
           <Box sx ={{
            display: "flex",
            flexDirection: "column"
    
           }}>
            <Box>
    
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row"
            }}>
           <Box>
          <Avatar src={avatar}
            alt={name}
            sx={{
              width: "95px",
              height: "95px",
              borderRadius: "8px",
            }}
            >
          </Avatar>
           </Box>
           <Box sx={{
            display: "flex",
            flexDirection: "column",
            ml: "18px"
           }}>
           <Box sx={{
            display: "flex",
            flexDirection: "row"
           }}>
            <Typography 
            sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "24px",
                fontWeight: 700,
                color: "black",
              }}
            >
            Команда{" "}{name}
            </Typography>
            <Typography sx={{
                fontFamily: "Nunito",
                fontSize: "12px",
                fontWeight: 500,
                color: "black",
                ml: "28px",
                alignSelf: "center"
            }}>
           {"рейтинг:"} {" "}{rating}
           </Typography>
           </Box>
           <Box>
            
            <Typography sx ={{
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
            }}>
               {leaderName}
            </Typography>
            <Typography sx ={{
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
            }}>
                Project Manager - moderator
            </Typography>
           </Box>
           </Box>
            </Box>
             <Typography sx={{
                mt: "36px",
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: 400,
                color: "black",
             }}>
               {description}
             </Typography>
           </Box>
          </Box>
        </Modal>
      );
};

export default TeamInfoModal;
