import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Tooltip } from '@mui/material';
import { getStatusDetails } from '../StatusIndicatorFunc/StatusIndicator';

interface StatusIndicatorProps {
    status: string
}

export default function StatusIndicator({ status }: StatusIndicatorProps) {
const {color, tooltipText} = getStatusDetails(status);
  
    if (color) {
      return (
        <Tooltip title={tooltipText}>
        <FiberManualRecordIcon
          sx={{
            width: '17px',
            height: '17px',
            color: color,
          }}
        />
        </Tooltip>
      );
    }
  
    return null;
  }