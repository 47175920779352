import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { AllTeamsPage } from "./AllTeamsPage/AllTeamsPage";
import { MyTeamsPage } from "./MyTeamsPage/MyTeamsPage";
import { TeamsNavigation } from "./components/TeamsNavigation";

export const TeamsPage = () => {
  const [activeButton, setActiveButton] = useState("my-teams");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeButton === "teams") {
      navigate("/teams/all-teams");
    } else {
      navigate("/teams/my-teams");
    }
  }, [navigate, activeButton]);

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column"}}>
      <Box>
        <TeamsNavigation activeButton={activeButton} onButtonClick={handleButtonClick} />
      </Box>
      {activeButton === "teams" && <AllTeamsPage />}
      {activeButton === "my-teams" && <MyTeamsPage />}
    </Box>
  );
};











// import React, { useState } from "react";
// import {Teams} from "./Teams";
// import {MyTeamsPage} from "./MyTeamsPage";


// const TeamsPage = () => {
//   const [activeButton, setActiveButton] = useState("teams");

//   const handleButtonClick  = (buttonName: string) => {
//     setActiveButton(buttonName);
//   };

//   return (
//     <>
//       <button onClick={() => handleButtonClick("teams")}>Команды</button>
//       <button onClick={() => handleButtonClick("my-teams")}>Мои команды</button>
      
//       {activeButton === "teams" && <Teams />}
//       {activeButton === "my-teams" && <MyTeamsPage />}
//     </>
//   );
// };

// export default TeamsPage;