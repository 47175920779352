export const navigationBarOutterBoxStyles = {
    display: "flex",
    marginBottom: "38px",
    justifyContent: "space-between",
    alignItems: "center",
  }

export const createButtonStyles = {
    color: "#82D9FF",
    fontFamily: "Zen Maru Gothic",
    fontWeight: 500,
    fontSize: "16px",
  }