import React, { useState } from "react";
import { Modal, Typography, TextField, Button, Box } from "@mui/material";
import Alert from '@mui/material/Alert';


interface TeamAvatarModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (avatar: string) => void;
    onCancel: () => void;
  }

const TeamAvatarModal = ({
  open,
  onClose,
  onSave,
  onCancel,
}: TeamAvatarModalProps) => {
  const [avatar, setAvatar] = useState("");
 

  const handleSave = async () => {
    try {
    onSave(avatar);
    } catch(error) {
      <Alert severity="error">
        Не удалось обновить аватар команды!
      </Alert>
    }
  };

  const handleCancel = () => {
    setAvatar("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Добавьте ссылку на аватар</Typography>
        </Box>
        <TextField
          label="Ссылка на аватар"
          value={avatar}
          onChange={(e) => setAvatar(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 4,
          }}
        >
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Добавить
          </Button>
          <Box sx={{ mx: 2 }} />
          <Button
            sx={{
              width: "191px",
              borderRadius: "44px",
              backgroundColor: "#82D9FF",
            }}
            variant="contained"
            onClick={handleCancel}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TeamAvatarModal;