import { Box, Button, TextField } from "@mui/material";
import { useSendUserDataMutation } from "../../../../services/auth";
import {
  textFildStyle,
  enterBtnStyle,
  serviceBtnStyle,
} from "../../authoriseStyle";
import { useState } from "react";
import { JHButton } from "../../../../UI/jHButton/JHButton";

export const RegisterForm = () => {
  const [auth] = useSendUserDataMutation();
  const [loginData, setLoginData] = useState({
    login: "",
    email: "",
    password: "",
  });
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const handleSubmit = async () => {
    try {
      const resp = await auth(loginData);
      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box maxWidth="368px" m="1.5rem auto">
      <Box
        display="grid"
        gridTemplateColumns="1fr"
        rowGap="0.75rem"
        gridAutoRows="minmax(3rem, auto)"
      >
        <TextField
          name="login"
          value={loginData.login}
          label="Логин"
          style={textFildStyle}
          onChange={(e: any) => handleChange(e)}
        />
        <TextField
          name="email"
          value={loginData.password}
          style={textFildStyle}
          label="Email"
          onChange={(e: any) => handleChange(e)}
        />
        <TextField
          name="password"
          type="password"
          value={loginData.password}
          style={textFildStyle}
          label="Пароль"
          onChange={(e: any) => handleChange(e)}
        />

        <JHButton
          variant="contained"
          type="submit"
          className="primary"
           onClick={() => handleSubmit()}
        >
          Создать аккаунт
        </JHButton>

        <JHButton
          variant="contained"
          type="submit"
          className="secondary"
          onClick={() => handleSubmit()}
        >
          Продолжить с Google
        </JHButton>

        <JHButton
          variant="contained"
          type="submit"
          className="secondary"
          onClick={() => handleSubmit()}
        >
          Продолжить с Yandex
        </JHButton>

        <JHButton
          variant="contained"
          type="submit"
          className="secondary"
          onClick={() => handleSubmit()}
        >
          Продолжить с Github
        </JHButton>
      </Box>
    </Box>
  );
};
