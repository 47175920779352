import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IProject, IProjectRole } from "../pages/projects/model/projectData";

enum TagTypes {
  Projects = "Projects",
  ProjectRoles = "ProjectRoles",
  ProjectRoleById = "ProjectRoleById",
}

const baseUrl = "http://185.46.199.211:39810";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: [
    TagTypes.Projects,
    TagTypes.ProjectRoles,
    TagTypes.ProjectRoleById,
  ],
  endpoints: (builder) => ({
    getProjects: builder.query<IProject[], void>({
      query: () => ({
        url: "/projects",
      }),
      providesTags: [TagTypes.Projects],
    }),
    getUserProjects: builder.query<IProject[], { user_id: number }>({
      query: (user_id) => ({
        url: `api/projects/all`,
        params: user_id,
      }),
      providesTags: [TagTypes.Projects],
    }),
    getProjectById: builder.query<IProject, number>({
      query: (id) => ({
        url: `/projects/${id}`,
      }),
      providesTags: [TagTypes.Projects],
    }),

    addToFavorite: builder.mutation({
      query({ id, body }) {
        return {
          url: `projects/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [TagTypes.Projects],
    }),
    deleteMyProject: builder.mutation({
      query({ id }) {
        return {
          url: `projects/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [TagTypes.Projects],
    }),
    getAllProjectsRole: builder.query<IProjectRole[], void>({
      query: () => ({
        // url: `/api/stack/role/`,
        url: `/roles/`,
      }),
      providesTags: [TagTypes.ProjectRoles],
    }),
    getProjectRoleById: builder.query<IProjectRole, number>({
      query: (id) => ({
        // url: `/api/stack/role/${id}`,
        url: `/roles/${id}`,
      }),
      providesTags: [TagTypes.ProjectRoleById],
    }),
    createOrUpdateProject: builder.mutation<
      IProject,
      { project: Partial<IProject>; id?: number }
    >({
      query: ({ project, id }) => ({
        url: id ? `/projects/${id}` : "/projects",
        method: id ? "PUT" : "POST",
        body: project,
      }),
      invalidatesTags: [TagTypes.Projects],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetUserProjectsQuery,
  useGetProjectByIdQuery,
  useAddToFavoriteMutation,
  useDeleteMyProjectMutation,
  useGetAllProjectsRoleQuery,
  useGetProjectRoleByIdQuery,
  useCreateOrUpdateProjectMutation,
} = projectsApi;
