import { Typography } from "@mui/material";

export const Title = () => {
  return (
    <Typography
      variant="h1"
      color="text.primary"
      sx={() => ({
        fontSize: "28px",
        height: "auto",
        mt: "14px"
      })}
    >
      JuniorHub
    </Typography>
  );
};
