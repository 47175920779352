import React from "react";
import { Box } from "@mui/material";
import { ProjectAvatarRating } from "./components/ProjectAvatarRating";
import { ProjectNameandPace } from "./components/ProjectNameandPace";
import { ProjectButtons } from "./components/ProjectButtons";
import { projectInfoDeskBoxStyle } from "../../styles/ProjectOwnerDetailsStyles";
import { ProjectStatus } from "./components/ProjectStatus";
import { IProject } from "../../../model/projectData";

interface ProjectInfoDeskProps {
  project?: IProject
}

export const ProjectInfoDesk = ({
 project
}: ProjectInfoDeskProps) => {
  return (
    <Box sx={projectInfoDeskBoxStyle}>
      <ProjectAvatarRating />
      <ProjectNameandPace projectName={project?.title} hours={project?.pace} />
      <ProjectStatus project={project}/>
      <ProjectButtons />
    </Box>
  );
};
