import { FC, useContext } from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import { ColorModeContext } from "../../theme";
import { ReactComponent as DarkSwitch } from "../../../assets/images/DarkSwitch.svg";
import { ReactComponent as LightSwitch } from "../../../assets/images/LightSwitch.svg";
import { tokens } from "../../theme";

const ThemeSwitcherComponent: FC = (): JSX.Element => {
  const theme = useTheme();
  const colorMode: any = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);

  return (
    <Grid onClick={colorMode.toggleColorMode} pl="8px">
      {theme.palette.mode === "dark" ? (
        <Button
          sx={{
            minWidth: "39px",
            height: "24px",
            borderRadius: "18px",
            pt: "4px",
            pb: "4px",
            backgroundColor: theme.palette.primary.dark,
            "&:hover *": {
              fill: `${colors.blue[300]}`,
            },
            "&:hover": {
              background: theme.palette.primary.dark,
            },
          }}
          startIcon={<DarkSwitch />}
        />
      ) : (
        <Button
          sx={{
            minWidth: "39px",
            height: "24px",
            borderRadius: "18px",
            pt: "4px",
            pb: "4px",
            backgroundColor: theme.palette.primary.dark,
            "&:hover *": {
              fill: `${colors.blue[300]}`,
            },
            "&:hover": {
              background: theme.palette.primary.dark,
            },
          }}
          endIcon={<LightSwitch />}
        />
      )}
    </Grid>
  );
};

export default ThemeSwitcherComponent;
