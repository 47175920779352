import { Box, Button, TextField, Typography } from "@mui/material";
import { RegisterForm } from "./components/RegisterForm";

export const RegisterPage = () => {
  return (
    <Box
      // m="1rem auto" width="100%" textAlign="center" alignSelf="center"
    sx={{
      height: 'auto',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant="h1" fontSize="32px">Создать JuniorHub аккаунт</Typography>
      <RegisterForm/>
    </Box>
  );
};
