export const documentsTypographyStyle = {
  fontFamily: "Zen Maru Gothic",
  fontWeight: 700,
  fontSize: "16px",
};

export const documentsOutterBoxStyle = (isExpanded) => ({
  position: "relative",
  width: "410px",
  height: isExpanded ? "auto" : "150px",
  borderRadius: "8px",
  bgcolor: "#2E3153",
  pt: "15px",
  pr: "15px",
  pb: "25px",
  pl: "10px",
});

export const importantOutterBoxStyle = (isExpanded) => ({
  position: "relative",
  width: "815px",
  height: isExpanded ? "auto" : "150px",
  borderRadius: "8px",
  bgcolor: "#2E3153",
  pt: "15px",
  pr: "15px",
  pb: "30px",
  pl: "10px",
});

export const chatTextStyle = {
  fontFamily: "Zen Maru Gothic",
  fontWeight: 700,
  fontSize: "16px",
};

export const chatOutterBoxStyle = {
  mt: "10px",
  width: "365px",
  minHeight: "540px",
  borderRadius: "8px",
  bgcolor: "#2E3153",
};

export const ViewAllItemsButtonStyle = {
  display: "flex",
  alignSelf: "center",
  position: "absolute",
  bottom: "5px",
  right: "15px",
  color: "#101335",
  bgcolor: "#82D9FF",
  borderRadius: "30px",
  cursor: "pointer",
};

export const oneNewsBoxStyle = {
  fontFamily: "Nunito",
  fontSize: "14px",
  fontWeight: 400,
  height: "40px",
  borderBottom: "1px solid #82D9FF",
  "&:not(:first-of-type)" : {
    mt: "10px"
  }
}
