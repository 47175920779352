import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAutorized } from "../../../../store/auth/authSlice";
import { useAppDispatch } from "../../../../store/hooks";
import {
  enterBtnStyle,
  textFildStyle,
  serviceBtnStyle,
} from "../../authoriseStyle";

export const LoginForm = () => {
  const [loginData, setLoginData] = useState({ login: "", password: "" });
  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    dispatch(
      setAutorized({
        user: {
          id: 1,
          login: "evgen",
        },
      })
    );
    navigate("/lk");
  };

  return (
    <Box maxWidth="368px" m="1.5rem auto">
      <Box
        display="grid"
        gridTemplateColumns="1fr"
        rowGap="0.75rem"
        gridAutoRows="minmax(3rem, auto)"
      >
        <TextField
          name="login"
          value={loginData.login}
          label="Логин"
          style={textFildStyle}
          onChange={(e: any) => handleChange(e)}
        />
        <TextField
          name="password"
          type="password"
          value={loginData.password}
          style={textFildStyle}
          label="Пароль"
          onChange={(e: any) => handleChange(e)}
        />
        <Button
          variant="contained"
          type="submit"
          style={enterBtnStyle}
          onClick={() => handleSubmit()}
        >
          Войти
        </Button>

        <Button
          variant="contained"
          type="submit"
          style={serviceBtnStyle}
          onClick={() => handleSubmit()}
        >
          Продолжить с Google
        </Button>

        <Button
          variant="contained"
          type="submit"
          style={serviceBtnStyle}
          onClick={() => handleSubmit()}
        >
          Продолжить с Yandex
        </Button>

        <Button
          variant="contained"
          type="submit"
          style={serviceBtnStyle}
          onClick={() => handleSubmit()}
        >
          Продолжить с Github
        </Button>
      </Box>
    </Box>
  );
};
