import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import { LocationIcon } from "../../../../UI/Icons/LocationIcon";
import { IUserData } from "../../model/UserData";
import AddFriendIcon from "../icons/add-friend";
import CopyLinkIcon from "../icons/copy-link";
import {
  ButtonUserInfoStyle,
  LocationTypographyStyle,
  RatingTypographyStyle,
  PlaceInRatingTypographyStyle,
  NameOfUserTypographyStyle,
  UserNicknameTypographyStyle,
} from "../styles/InfoBaseStyles";

interface InfoBaseProps {
  userData: IUserData;
}

export const InfoBase = ({ userData }: InfoBaseProps) => {
  const theme = useTheme();

  return (
    <>
      {/* Рейтинг */}
      <Box
        justifyContent="end"
        display="grid"
        alignContent="baseline"
        position="relative"
      >
        <Typography sx={RatingTypographyStyle}>1092</Typography>
        <Typography sx={PlaceInRatingTypographyStyle}>
          место в рейтинге
        </Typography>
      </Box>

      {/* Ава, имя, ник, город */}
      <Box width="285px" display="grid" justifySelf="center">
        <Box display="grid" justifySelf="center">
          <Avatar
            sx={{
              width: 100,
              height: 100,
              bgcolor: theme.palette.primary.light,
            }}
          />
        </Box>
        <Typography variant="h2" sx={NameOfUserTypographyStyle}>
          {userData.firstName} {userData.lastName}
        </Typography>
        <Typography sx={UserNicknameTypographyStyle}>
          {userData.nickName}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "max-content max-content",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <LocationIcon />
          <Typography sx={LocationTypographyStyle}>
            {userData.location}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            marginTop: "16px",
            fontWeight: 500,
            fontFamily: "Nunito",
          }}
        >
          Мессенджеры:
        </Typography>

        {userData.listContacts.map((c) => (
          <Typography
            key={c}
            sx={{
              fontSize: "14px",
              fontFamily: "Nunito",
              fontWeight: 400,
            }}
          >
            {c}
          </Typography>
        ))}

        <Typography
          sx={{
            fontSize: "16px",
            marginTop: "10px",
            fontWeight: 500,
            fontFamily: "Nunito",
          }}
        >
          Мои сети:
        </Typography>
        {userData.listSocial.map((s, index) => (
          <Typography
            key={index}
            sx={{
              fontFamily: "Nunito",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {s}
          </Typography>
        ))}

        <Typography
          sx={{
            fontSize: "16px",
            marginTop: "10px",
            fontWeight: 500,
            fontFamily: "Nunito",
          }}
        >
          О себе:
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {userData.bio}
        </Typography>
        <Box sx={{ mt: "12px" }}>
          <Button variant="text" sx={ButtonUserInfoStyle}>
            <AddFriendIcon />
            Добавить в друзья
          </Button>

          <Button
            sx={ButtonUserInfoStyle}
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            <CopyLinkIcon />
            Копировать ссылку на профиль
          </Button>
        </Box>
      </Box>
    </>
  );
};
