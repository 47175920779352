import { Box } from "@mui/system";
import { CompleteIcon } from "../../../../../UI/Icons/CompleteIcon";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../components/theme";

export const JHBadge = (props: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      width={props.width}
      sx={{
        position: "relative",
      }}
    >
      {props.complete ? (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            borderRadius: 50,
            background: "#FFFFFF",
            width: "24px",
            height: "24px",
            zIndex: 1,
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <CompleteIcon />
        </Box>
      ) : null}

      <Box
        sx={{
          borderRadius: 50,
          zIndex: 1,
          p: "9px",
          position: "absolute",
          right: "-9px",
          bottom: "-3px",
          background: colors.blue[200],
          color: colors.black[100],
          display: "flex",
          gap: "1px",
          alignItems: "center"
        }}
      >
        {props.count}
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.66683 1.66683C4.19407 1.66683 3.00016 2.86074 3.00016 4.3335C3.00016 5.80625 4.19407 7.00016 5.66683 7.00016C7.13959 7.00016 8.3335 5.80625 8.3335 4.3335C8.3335 2.86074 7.13959 1.66683 5.66683 1.66683ZM1.66683 4.3335C1.66683 2.12436 3.45769 0.333496 5.66683 0.333496C7.87597 0.333496 9.66683 2.12436 9.66683 4.3335C9.66683 6.54263 7.87597 8.3335 5.66683 8.3335C3.45769 8.3335 1.66683 6.54263 1.66683 4.3335ZM10.2191 1.50507C10.4795 1.24472 10.9016 1.24472 11.1619 1.50507C12.724 3.06717 12.724 5.59983 11.1619 7.16192C10.9016 7.42227 10.4795 7.42227 10.2191 7.16192C9.95877 6.90157 9.95877 6.47946 10.2191 6.21911C11.2605 5.17772 11.2605 3.48928 10.2191 2.44788C9.95877 2.18753 9.95877 1.76542 10.2191 1.50507ZM10.6867 10.1718C10.776 9.81461 11.138 9.59743 11.4952 9.68673C12.3805 9.90805 12.9408 10.4741 13.2631 11.1187C13.575 11.7424 13.6668 12.4382 13.6668 13.0002C13.6668 13.3684 13.3684 13.6668 13.0002 13.6668C12.632 13.6668 12.3335 13.3684 12.3335 13.0002C12.3335 12.5621 12.2587 12.0913 12.0705 11.715C11.8928 11.3595 11.6199 11.0923 11.1718 10.9803C10.8146 10.891 10.5974 10.529 10.6867 10.1718ZM3.3335 11.0002C2.49386 11.0002 1.66683 11.8092 1.66683 13.0002C1.66683 13.3684 1.36835 13.6668 1.00016 13.6668C0.631973 13.6668 0.333496 13.3684 0.333496 13.0002C0.333496 11.2456 1.59581 9.66683 3.3335 9.66683H8.00016C9.73785 9.66683 11.0002 11.2456 11.0002 13.0002C11.0002 13.3684 10.7017 13.6668 10.3335 13.6668C9.96531 13.6668 9.66683 13.3684 9.66683 13.0002C9.66683 11.8092 8.8398 11.0002 8.00016 11.0002H3.3335Z"
            fill="black"
          />
        </svg>
      </Box>
      <div>{props.children}</div>
    </Box>
  );
};
