import React from "react";
import { MainLayout } from "../layout/mainLayout/MainLayout";
import { WorkZone } from "../pages/workzone/WorkZone";
import { Lk } from "../pages/lk";
import { Verify } from "../pages/lk/lkVerifySkill/LkVerifySkill";
import { MyTeamsPage } from "../pages/teams/MyTeamsPage/MyTeamsPage";
import { TeamsPage } from "../pages/teams/TeamsPage";
import { AllTeamsPage } from "../pages/teams/AllTeamsPage/AllTeamsPage";
import { Projects } from "../pages/projects/Projects";
import MyProjects from "../pages/projects/myProjects/MyProjects";
import PrSelected from "../pages/projects/prSelected/PrSelected";
import { ProjectCreate } from "../pages/projects/prCreate/ProjectCreate";
import { ProjectOwnerDetails } from "../pages/projects/PrOwnerDetails/ProjectOwnerDetails";
import { NewTeamPage } from "../pages/teams/NewTeamPage/NewTeamPage";
import { LkProfileViewOwner } from "../pages/lk/lkProfileViewOwner/lkProfileViewOwner";
import { LkProfileEdit } from "../pages/lk/lkProfileEdit/LkProfileEdit";
import { LkProfileSettings } from "../pages/lk/lkProfileSetting/LkProfileSettings";
import { LkProfileViewGuest } from "../pages/lk/lkProfileViewGuest/LkProfileViewGuest";
import { LkAccount } from "../pages/lk/lkAccount/LkAccount";

export const protectedRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "projects/myprojects",
        element: <MyProjects />,
      },
      {
        path: "projects/selected",
        element: <PrSelected />,
      },
      {
        path: "projects/create",
        element: <ProjectCreate />,
      },
      {
        path: "projects/create/:id",
        element: <ProjectCreate />,
      },

      {
        path: "projects/:id",
        element: <ProjectOwnerDetails />,
      },
      {
        path: "/teams",
        element: <TeamsPage />,
      },
      {
        path: "/teams/all-teams",
        element: <AllTeamsPage />,
      },

      {
        path: "/teams/my-teams",
        element: <MyTeamsPage />,
      },
      {
        path: "/teams/new-team",
        element: <NewTeamPage />,
      },
      {
        path: "work",
        element: <WorkZone />,
      },
      {
        path: "lk",
        element: <Lk />,
      },
      {
        path: "profile/edit",
        element: <LkAccount />,
      },
      {
        path: "profile",
        element: <LkProfileViewOwner />,
      },
      {
        path: "profile/:id",
        element: <LkProfileViewGuest />,
      },
      {
        path: "profile/edit",
        element: <LkProfileEdit />,
      },
      {
        path: "verify/:stackid/:skillid",
        element: <Verify />,
      },
      {
        path: "profile/settings",
        element: <LkProfileSettings />,
      },
    ],
  },
];
