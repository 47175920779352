import React, { useState } from "react";
import { Alert, IconButton, Typography } from "@mui/material";
import { useAddToFavoriteMutation } from "../../../../services/projects";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { IProject } from "../../model/projectData";


interface LikeBtnProps {
  isFavorite: boolean;
  project: IProject
}

export default function LikeBtn({project, isFavorite}: LikeBtnProps) {
     
    const [addLike, { isError }] = useAddToFavoriteMutation();
    const handleAddLike = async () => {
      try {
        await addLike({
          id : project.id,
          body: {
            ...project,
            isFavorite: !isFavorite,
          },
        });
       
      }
 
  catch (error) {
      return (
      <Alert>
        <Typography sx={{
          fontFamily: "Zen Maru Gothic"
        }}>
        Не удалось добавить команду в избранное
        </Typography>
      </Alert>
      )
    };
  };

  return (
    <IconButton sx={{ pt: "8px", width: "22px", height: "22px",  }} onClick={handleAddLike}>
       {isFavorite ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
    </IconButton>
  );
}