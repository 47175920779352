export const textFildStyle = {
  backgroundColor: "#EAEAEA",
};
export const enterBtnStyle = {
  marginTop: "12px",
  marginBottom: "30px",
  width: "110px",
  height: "32px",
  p: "14px 4px 14px 4px",
  backgroundColor: "#00A1E7",
  justifySelf: "center",
  color: "#E6E1E5",
  borderRadius: "18px",
  fontWeight: "500",
  fonSize: "16px",
  fontFamily: "Nunito",
  lineHeight: "none",
};
export const serviceBtnStyle = {
  maxWidth: "296px",
  minWidth: "296px",
  paddingTop: "12px",
  paddingBottom: "12px",
  justifySelf: "center",
  backgroundColor: "transparent",
  color: "#000000",
  border: "3px solid #82D9FF",
  borderRadius: "44px",
};
