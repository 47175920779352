import React, { useEffect, useRef } from "react";
import { Box, MenuItem } from "@mui/material";

interface RoleMenuProps {
  open: boolean;
  selectedOption?: number;
  options: { id: number; name: string }[];
  onChange: (option: number) => void;
  onOutsideClick: () => void;
}

const RoleMenu = ({
  open,
  selectedOption,
  options,
  onChange,
  onOutsideClick,
}: RoleMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!menuRef.current || !menuRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open, onOutsideClick]);

  const handleListMouseDown = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <Box
      ref={menuRef}
      sx={{
        position: "absolute",
        width: "220px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: 1,
        fontFamily: "Nunito",
        color: "black",
      }}
      onMouseDown={handleListMouseDown}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => onChange(option.id)}
          selected={option.id === selectedOption}
        >
          {option.name}
        </MenuItem>
      ))}
    </Box>
  );
};

export default RoleMenu;
