import React from "react";
import { Box } from "@mui/material";
import Card from "../CardComponents/Card";
import { IProject, IProjectVacancy } from '../../model/projectData';

interface ProjectItemProps {
  listItem: IProject;
  isFavorite: boolean;
  userId: number;
}

export default function ProjectItem({ listItem, isFavorite,  userId }: ProjectItemProps) {
  

  const vacList : IProjectVacancy[] = listItem.vacancies;
 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
      }}
    >
         <Card 
          listItem={listItem} 
         vacancies={vacList} 
         isFavorite={isFavorite}
         userId={userId}
             />
    </Box>
  );
}
