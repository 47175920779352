import React, { useEffect, useState } from "react";

export default function useCountDown(stopTimer, initialSeconds) {
  const [startAt, setStartAt] = useState(initialSeconds);
  const [timeOut, _setTimeOut] = useState();
  const countDown = () => {
    setStartAt((prev) => prev - 1);
  };

  const startTimer = () => {
    if (startAt === 0) return stopTimer();
    const timer = setTimeout(() => {
      countDown();
    }, 1000);
    _setTimeOut(timer);
    clearInterval(timeOut);
  };

  useEffect(() => {
    startTimer();
  }, [startAt]);

  return {
    startAt,
    count: () => setStartAt(startAt - 1000),
  };
}
