import React from "react";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavBarHorizontal from "../../components/navBar/NavBarHorizontal";
import { ColorModeContext, useMode } from "../../components/theme";

/* 
Surface colors 
affect surfaces of components, such as cards, sheets, and menus. */

/*
Background color 
appears behind scrollable content. 
The baseline background and surface color is #FFFFFF.*/

export const MainLayout = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBarHorizontal />
        <Container maxWidth="lg" sx={{ mt: "44px" }}>
          <Outlet />
        </Container>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
