import {
  Box,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { JHButton } from "../../../UI/jHButton/JHButton";

export enum Visible {
  all = "all",
  friends = "friends",
  me = "me",
}

export interface UserPrivacyData {
  contacts: Visible;
  basic: Visible;
  projects: Visible;
  stacks: Visible;
}

export const LkProfileSettings = () => {
  const [userRegData, setUserRegData] = useState({
    email: "mail@mail.ru",
    password: "12345678",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserRegData({ ...userRegData, [name]: value });
  };

  const [contacts, setContacts] = useState(Visible.me);
  const [basic, setBasic] = useState(Visible.me);
  const [projects, setProjects] = useState(Visible.me);
  const [stacks, setStacks] = useState(Visible.me);

  const handleSubmitPrivacy = () => {
    const userPrivacy: UserPrivacyData = {
      basic,
      contacts,
      projects,
      stacks,
    };
    console.log(userPrivacy);
  };

  const handleSubmitRegData = () => {
    console.log(userRegData);
  };

  return (
    <>
      <Box>
        <Typography variant="h2">Регистрационные данные</Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "max-content 200px max-content",
          }}
        >
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
          <OutlinedInput
            name="email"
            id="outlined-adornment-password"
            type={"text"}
            // value={userRegData.email}
            onChange={handleChange}
          ></OutlinedInput>
          <JHButton>Изменить</JHButton>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={"password"}
            name="password"
            onChange={handleChange}
          ></OutlinedInput>
          <JHButton>Изменить</JHButton>
        </Box>
        <JHButton onClick={handleSubmitRegData}>Сохранить</JHButton>
      </Box>

      <Typography variant="h2">Конфиденциальность</Typography>
      <Typography>
        Выберите данные о вас, которые будут видны другим пользователям.
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplate: "repeat(5, 1fr)/repeat(2, max-content)",
          columnGap: "20px",
        }}
      >
        <>&nbsp;</>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, max-content)",
            columnGap: "20px",
          }}
        >
          <Typography>Видны только мне</Typography>
          <Typography>Видны мне и моим друзьям</Typography>
          <Typography>Видны всем</Typography>
        </Box>

        <Typography>Контактные данные</Typography>

        {/* каждый FormControlLabel в виде display: block и тогда каждый ляжет в сетку с шапкой  */}

        <RadioGroup row name="contacts">
          <FormControlLabel
            control={<Radio onClick={() => setContacts(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            control={<Radio onClick={() => setContacts(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            value={Visible.all}
            control={<Radio onClick={() => setContacts(Visible.all)} />}
            label=""
          />
        </RadioGroup>

        <Typography>Основные данные</Typography>

        <RadioGroup row name="contacts">
          <FormControlLabel
            control={<Radio onClick={() => setBasic(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            control={<Radio onClick={() => setBasic(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            value={Visible.all}
            control={<Radio onClick={() => setBasic(Visible.all)} />}
            label=""
          />
        </RadioGroup>

        <Typography>Данные о проектах</Typography>

        <RadioGroup row name="contacts">
          <FormControlLabel
            control={<Radio onClick={() => setProjects(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            control={<Radio onClick={() => setProjects(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            value={Visible.all}
            control={<Radio onClick={() => setProjects(Visible.all)} />}
            label=""
          />
        </RadioGroup>

        <Typography>Мои навыки</Typography>
        <RadioGroup row name="contacts">
          <FormControlLabel
            control={<Radio onClick={() => setStacks(Visible.me)} />}
            value={Visible.me}
            label=""
          />
          <FormControlLabel
            control={<Radio onClick={() => setStacks(Visible.friends)} />}
            label=""
            value={Visible.friends}
          />
          <FormControlLabel
            value={Visible.all}
            control={<Radio onClick={() => setStacks(Visible.all)} />}
            label=""
          />
        </RadioGroup>

        <JHButton onClick={handleSubmitPrivacy} className="primary">
          Сохранить
        </JHButton>
      </Box>
    </>
  );
};
