import { Box, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../../components/theme";
import { projectsApi } from "../../../services/projects";
import { userApi } from "../../../services/user";
import { ISubSkill } from "../model/UserData";
import { InfoBase } from "./components/InfoBase/InfoBase";
import { InfoBaseStub } from "./components/InfoBase/InfoBaseStub";
import { UserFriends } from "./components/UserFriends";
import { UserProjects } from "./components/UserProjects";
import { UserSkills } from "./components/UserSkills/UserSkills";

export const LkProfileViewOwner = () => {
  const { data: userData, isLoading } = userApi.useGetUserDataQuery("1");
  const { data: friends } = userApi.useGetUserFriendsQuery("1");

  const user_id = Number(1);
  const { data: projects } = projectsApi.useGetUserProjectsQuery({ user_id });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  interface BlockState {
    selectedRole?: number;
    selectedSkill?: number;
    selectedSubSkills: ISubSkill[];
  }

  const initialBlockState: BlockState = {
    selectedRole: undefined,
    selectedSkill: undefined,
    selectedSubSkills: [],
  };

  const [blockStates, setBlockStates] = useState<BlockState[]>([
    { ...initialBlockState },
  ]);

  const handleBlockChange = (index: number, newBlock: BlockState) => {
    setBlockStates((prevStates) => {
      const updatedBlockStates = [...prevStates];
      updatedBlockStates[index] = newBlock;
      return updatedBlockStates;
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{
            width: "295px",
            height: "580px",
            borderRadius: "8px",
            p: "10px",
            bgcolor: theme.palette.primary.dark,
          }}
        >
          {userData ? <InfoBase userData={userData} /> : <InfoBaseStub />}
        </Grid>

        <Grid
          item
          lg={8}
          md={8}
          xs={12}
          sx={{
            width: "844px",
            ml: "24px",
          }}
        >
          {friends && friends.length > 0 ? (
            <UserFriends friends={friends} />
          ) : null}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                fontFamily: "Zen Maru Gothic",
                lineHeight: "16px",
                color: colors.grey[200],
                mt: "4px",
                mb: "4px",
              }}
            >
              Мои навыки
            </Box>
            <UserSkills onBlockChange={handleBlockChange} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                fontFamily: "Zen Maru Gothic",
                lineHeight: "16px",
                color: colors.grey[200],
                mt: "4px",
                mb: "4px",
              }}
            >
              Мои проекты
            </Box>
            {projects ? <UserProjects projects={projects} /> : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
