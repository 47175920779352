import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { TextField } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useTheme } from "@mui/material";

export const TeamChat = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{ fontSize: "16px", fontFamily: "Nunito", fontWeight: 600 }}
      >
        ЧАТ
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "16px",
          backgroundColor: theme.palette.background.paper,
          width: "367px",
          height: "543px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            pt: "30px",
            pl: "25px",
            pr: "25px",
          }}
        >
          <Typography sx={{ textAlign: "center", fontFamily: "Nunito" }}>
            Чат находится в разработке. В скором времени вы сможете с помощью
            нашего чата общаться с членами вашей команды.
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            pb: "5px",
            pr: "54px",
          }}
        >
          <IconButton>
            <CameraAltIcon />
          </IconButton>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <NearMeIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ marginBottom: "18px", marginRight: "25px", marginLeft: "25px" }}
        >
          <TextField
            sx={{ borderRadius: "8px" }}
            placeholder="Введите сообщение"
            multiline
            rows={4}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
