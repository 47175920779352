// Route: /verify/:stackid/:skillid
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
// import { useGetProfessionsListQuery } from "../../../services/stacks";
import { useGetUserStacksQuery } from "../../../services/user";
import { useAppSelector } from "../../../store/hooks";
import { JHButton } from "../../../UI/jHButton/JHButton";

const filteredFront = ( stacksFromBack: any, stackid: any ) => {
  // console.log(stacksFromBack[1].id, stackid);
  
  return stacksFromBack.filter((bk: any) => `${bk.id}` === stackid);
};

export const Verify = () => {
  const userId = useAppSelector((state) => state.auth.user.id);

  // const { data: allStacks } = useGetProfessionsListQuery();
  const { data: stacksFromBack } = useGetUserStacksQuery(`${userId}`);
  // вывести только нужный стек по id
  // console.log(stacksFromBack);

  const { stackid, skillid } = useParams();

  const test = stacksFromBack && filteredFront(stacksFromBack, stackid);
  console.log(test);
  

  // const filteredFront = stacksFromBack && stacksFromBack.filter((bk: any) => bk.id === stackid);

  const quiz = [
    {
      id: 1,
      title: "Вопрос 1",
      variants: [
        { id: 1, title: "Вариант 1" },
        { id: 2, title: "Вариант 2" },
        { id: 3, title: "Вариант 3" },
      ],
    },
    {
      id: 2,
      title: "Вопрос 2",
      variants: [
        { id: 1, title: "Вариант 21" },
        { id: 2, title: "Вариант 22" },
        { id: 3, title: "Вариант 23" },
      ],
    },
  ];
  const [answer, setAnswer] = useState<any>([]);
  // найти название скила в allStacks
  const skillTitle = skillid;

  const handleSubmit = () => {
    const verifyPassed = checkResult().every((el) => el === true);
    verifyPassed ? console.log("отправка стека") : console.log("fail");
  };

  const checkResult = () => {
    const rightAnswers = { quenstion1: 3, quenstion2: 3 };
    const answersArr = Object.values(answer);
    const rightAnswersArr = Object.values(rightAnswers);

    const result = answersArr.map(
      (answ, ind) =>
        JSON.stringify(answ) === JSON.stringify(rightAnswersArr[ind])
    );
    return result;
  };

  return (
    <Box>
      <Typography variant="h1">Верификациная навыков {skillTitle}</Typography>

      {quiz.map((quenstion) => (
        <>
          <Typography variant="h2">{quenstion.title}</Typography>
          <RadioGroup name={`quenstion${quenstion.id}`}>
            {quenstion.variants.map((variant) => (
              <>
                <FormControlLabel
                  control={
                    <Radio
                      onClick={() =>
                        setAnswer({
                          ...answer,
                          [`quenstion${quenstion.id}`]: variant.id,
                        })
                      }
                    />
                  }
                  value={variant.id}
                  label={variant.title}
                />
              </>
            ))}
          </RadioGroup>
        </>
      ))}

      <JHButton className="primary" onClick={handleSubmit}>
        Отправить
      </JHButton>
      {JSON.stringify(answer)}
      {/* перейти к след вопросу */}
      {/* вывести их в цикле внутри компонента swipe или paginate */}
    </Box>
  );
};
