import { Button, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

interface TeamNavigationProps {
  activeButton: string;
  onButtonClick: (buttonName: string) => void;
}

export const TeamsNavigation = ({
  activeButton,
  onButtonClick,
}: TeamNavigationProps) => {

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "28px",
          fontFamily: "Zen Maru Gothic",
          fontWeight: 700,
        }}
      >
        Команды
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: "10px",
        }}
      >
        <Button
          variant="text"
          onClick={() => onButtonClick("my-teams")}
          sx={{
            fontFamily: "Nunito",
            fontSize: "16px",
            color: theme.palette.secondary.main,

            fontWeight: activeButton === "my-teams" ? "400" : "600",
            border: activeButton === "my-teams" ? "none" : "0",
            backgroundColor:
              activeButton === "my-teams" ? theme.palette.info.main : "transparent",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor:
                activeButton === "my-teams" ? theme.palette.info.main : "white",
              textDecoration:
                activeButton === "my-teams" ? "none" : "underline",
            },
          }}
        >
          Мои Команды
        </Button>
        <Button
          variant="text"
          onClick={() => onButtonClick("teams")}
          sx={{
            fontFamily: "Nunito",
            fontSize: "16px",
            color: theme.palette.secondary.main,
            ml: "15px",
            fontWeight: activeButton === "teams" ? "400" : "600",
            border: activeButton === "teams" ? "none" : "0",
            backgroundColor:
              activeButton === "teams" ? theme.palette.info.main  : "transparent",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: activeButton === "teams" ? theme.palette.info.main  : "white",
              textDecoration: activeButton === "teams" ? "none" : "underline",
            },
          }}
        >
          Все Команды
        </Button>
      </Box>
    </Box>
  );
};
