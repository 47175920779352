import React from "react";
import { TextField, IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
}

const SearchInput = ({ value, onChange, onFocus }: SearchInputProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "368px",
        height: "36px",
        borderRadius: "12px",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Поиск по разделу"
        value={value}
        onChange={handleInputChange}
        onFocus={onFocus}
        sx={{
          width: "100%",
          height: "36px",
          "& .MuiOutlinedInput-root": {
            height: "100%",
            borderRadius: "12px",
            padding: "12px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        }}
        InputProps={{
          startAdornment: (
            <IconButton disabled>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default SearchInput;

