export interface IProjects extends Array<IProject> {}

export interface IProject {
  createrId: number;
  id: number;
  title: string;
  isFavorite: boolean;
  userProject: boolean;
  description: string;
  status: string;
  progress: number;
  pace: number;
  difficulty: number;
  news: Array<IProjectNews>;
  documents: Array<IProjectDocumentAndLink>;
  links: Array<IProjectDocumentAndLink>;
  vacancies: Array<IProjectVacancy>;
  startTimestamp: number;
  closeTimestamp: number;
  name?: string;
  members?: Array<string>;
  completeCount?: number;
  projectStartDate?: string;
  userRole?: {
    name: string;
  };
}

export interface IProjectNews {
  id: number;
  body: string;
}
export interface IProjectDocumentAndLink {
  id: number;
  title: string;
  description: string;
  postedTimestamp: number;
}

export interface IProjectVacancy extends IProjectRole {
  count: number;
}

export interface IProjectRole {
  id: number;
  name: string;
  skill: IProjectRolesSkill[];
}

export interface IProjectRolesSkill {
  id: number;
  name: string;
  instrumentGroup: string;
  subSkill: IProjectRolesSubskill[];
}

export interface IProjectRolesSubskill {
  id: number;
  name: string;
  instrumentGroup: string | null;
}

export interface IProjectNewRolesBlock {
  selectedRole?: number;
  selectedSkill?: number;
  selectedSubSkills: IProjectRolesSubskill[];
  count: number;
}

export interface IProjectInfo {
  name: string;
  disc: string;
  hoursCount: number;
  mail?: string;
  phone?: string;
}

export const sortType = {
  new: "Сначала новые",
  old: "Сначала старые",
  completed: "Сначала завершенные",
  abc: "По алфавиту"
};

export const filterType = {
  Front: "Frontend",
  Back: "Backend",
  Design: "Designer",
  Dev: "DevOps",
  Manager: "Project Manager"
};

export type OmittedProjectDetails = Omit<
  IProject,
  | "id"
  | "closeTimestamp"
  | "documents"
  | "links"
  | "news"
>;
