export const projectNameOutterBoxStyle = {
    display: "flex",
    mt: "40px",
    justifyContent: "center",
    alignItems: "center",
  }

  export const projectNameTitleStyle = {
    mx: "auto",
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "20px",
  }

  export const projectNameInnerTitleStyle = {
    mt: "10px",
    mx: "auto",
    fontFamily: "Nunito",
    fontWeight: 300,
    fontSize: "16px",
  }

  export const projectPaceTextStyle = {
    m: "10px auto 0 auto",
    fontFamily: "Nunito",
    fontWeight: 300,
    fontSize: "16px",
  }

  export const projectStatusTextStyle = {
    ml:"10px",
    fontFamily: "Nunito",
    fontWeight: 300,
    fontSize: "16px",
  }

  export const projectPaceInnerBoxStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  export const projectPaceIconStyle = {
    color: "white",
    width: "16px",
    height: "16px",
  }

  export const projectPaceInnerTextStyle = {
    ml: "10px",
    fontFamily: "Nunito",
    fontWeight: 300,
    fontSize: "16px",
  }