const CopyLinkIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M3.22 15.675V8.151L.563 14.567l2.659 1.108Zm15.768-2.025L13.588.617 4.379 4.426l5.4 13.033 9.209-3.809ZM6.563 6.126a.836.836 0 0 1-.834-.834c0-.458.375-.833.833-.833.459 0 .834.375.834.833a.836.836 0 0 1-.833.834ZM4.896 16.959H7.77l-2.875-6.95v6.95Z"
    />
  </svg>
)
export default CopyLinkIcon
