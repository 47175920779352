import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useTheme,
} from "@mui/material";
import { Timer } from "./Timer/Timer";
import { tokens } from "../../../../../../../../components/theme";
import {
  ISubSkill,
  IQuestion,
  IAnswer,
} from "../../../../../../model/UserData";

import {
  stacksApi,
  useGetQuestionsQuery,
} from "../../../../../../../../services/stacks";

interface QuizModalWindowProps {
  open: boolean;
  onClose: () => void;
  setSubSkillId: (subSkillId: string) => void;
  subSkillId: string;
  quizTitle: string;
}

const QuizModalWindow = ({
  open,
  onClose,
  setSubSkillId,
  subSkillId,
  quizTitle,
}: QuizModalWindowProps) => {
  const { data: quiz } = stacksApi.useGetQuestionsQuery(subSkillId);

  const [page, setPage] = useState(1);
  const [onwardsBtn, setOnwardsBtn] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPage = quiz?.length;
  const lastQuizIndex = page;
  const firstQuizIndex = lastQuizIndex - 1;
  const currentQuestion = quiz?.slice(firstQuizIndex, lastQuizIndex);

  const nextPage = () => {
    if (lastQuizIndex !== totalPage) {
      setPage((prev) => prev + 1);
    } else {
      setOnwardsBtn(false);
    }
  };

  const stopTimer = () => {
    setOnwardsBtn(false);
  };
  const sumbitQuiz = () => {};

  const buttonStyle = {
    width: "279px",
    height: "44px",
    borderRadius: "44px",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: colors.blue[200],
    color: theme.palette.primary.main,
    "&:hover": {
      background: colors.blue[500],
    },
  };

  if (currentQuestion) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: "956px",
            minHeight: "413px",
            borderRadius: "24px",
            backgroundColor: theme.palette.background.paper,
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: "20px",
            color: colors.grey[200],
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {currentQuestion?.map((question) => (
            <>
              <Box
                key={question.id}
                sx={{
                  fontFamily: "Zen Maru Gothic",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: colors.grey[200],
                  textAlign: "center",
                }}
              >
                ПРОВЕРКА НАВЫКОВ {quizTitle}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "850px",
                  fontFamily: "Nunito",
                }}
              >
                <Box
                  sx={{
                    width: "618px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: 400,
                      fontSize: "18px",
                      color: colors.grey[200],
                    }}
                  >
                    {question.name}
                  </Box>
                  {question.answer.map((item) => (
                    <FormGroup key={item.id}>
                      <FormControlLabel
                        control={<Checkbox name="answer" />}
                        label={`${item.name}`}
                      />
                    </FormGroup>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <Timer stopTimer={stopTimer} />
                </Box>
                <Box
                  sx={{
                    width: "81px",
                    height: "37px",
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "21.78px",
                    textAlign: "center",
                  }}
                >
                  {page}\{totalPage}
                </Box>
              </Box>
            </>
          ))}

          {onwardsBtn ? (
            <Button sx={buttonStyle} onClick={() => nextPage()}>
              Далее
            </Button>
          ) : (
            <Button sx={buttonStyle} onClick={() => sumbitQuiz()}>
              Отправить
            </Button>
          )}
        </Box>
      </Modal>
    );
  }
  return <></>;
};

export default QuizModalWindow;
