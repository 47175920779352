import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  documentsTypographyStyle,
  importantOutterBoxStyle,
  oneNewsBoxStyle,
} from "./styles/WorkingPanelStyles";
import { IProjectNews } from "../../../../model/projectData";
import { ViewAllItemsButton } from "./ViewAllItemsButton";

interface ProjectImportantProps {
  projectNews?: IProjectNews[];
}

export const ProjectImportant = ({ projectNews }: ProjectImportantProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Typography sx={documentsTypographyStyle}>Важное</Typography>
      <Box sx={{...importantOutterBoxStyle(isExpanded), mt:"10px"}}>
        {projectNews && projectNews.length > 0 ? (
          projectNews
            .slice(0, isExpanded ? projectNews.length : 2)
            .map((oneNews: IProjectNews) => (
              <Box key={oneNews.id} sx={oneNewsBoxStyle}>
                <Typography>{oneNews.body}</Typography>
              </Box>
            ))
        ) : (
          <Typography sx={{ textAlign: "center", mt: "50px" }}>
            Новости отсутствуют, добавьте какую-то новость или важный анонс
          </Typography>
        )}
        <ViewAllItemsButton isExpanded={isExpanded} onClick={handleClick} />
      </Box>
    </>
  );
};
