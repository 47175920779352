import { Box, IconButton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";


interface PeoplePerRoleProps {
    count : number;
    onCountChange : (newCount: number)=> void;
}

export const PeoplePerRole = ({count, onCountChange}: PeoplePerRoleProps) => {
   
    
    const handleIncrement = ()=> {
        onCountChange(count + 1);
    };
    const handleDecrement = ()=> {
        if (count > 0){
        onCountChange (count - 1);
        }
    };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Кол.во{" "}человек
        </Typography>
      
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          ml: "55px",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleDecrement}>
          <RemoveIcon
            sx={{
              width: "15px",
              height: "15px",
            }}
          />
        </IconButton>
        <Box
          sx={{
            width: "26px",
            height: "26px",
            borderRadius: "4px",
            bgcolor: "#82D9FF",
            px: "9px",
            py: "4px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "12px",
              fontWeight: 400,
              color: "black"
            }}
          >
            {count}
          </Typography>
        </Box>
        <IconButton onClick={handleIncrement}>
          <AddIcon
            sx={{
              width: "15px",
              height: "15px",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
