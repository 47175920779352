import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, MenuItem, Typography, IconButton } from "@mui/material";
import {
  PrEditStatusMenuStyle,
  PrManagerTitleStyle,
  PrStatusBoxStyle,
} from "../PrManager/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMemo } from "react";

interface ProjectEditStatusProps {
  status?: string;
  onStatusChange: (status: string) => void;
  openDropdown: string | null;
  setOpenDropdown: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ProjectEditStatus = ({
  status,
  onStatusChange,
  openDropdown,
  setOpenDropdown
}: ProjectEditStatusProps) => {
  const [initialStatus, setInitialStatus] = useState(status);
  const dropdownRef = useRef<HTMLElement | null>(null);

  const projectStatuses = useMemo(
    () => [
      { title: "Posted", variant: "Опубликован" },
      { title: "In progress", variant: "В работе" },
      { title: "Done", variant: "Проект завершен" },
      { title: "Not Posted", variant: "Не опубликован" },
    ],
    []
  );

  const convertTitleToVariant = useCallback(
    (statusTitle?: string) => {
      return projectStatuses.find(
        (projectStatus) => statusTitle === projectStatus.title
      )?.variant;
    },
    [projectStatuses]
  );

  useEffect(() => {
    const variant = convertTitleToVariant(status);
    if (variant) {
      setInitialStatus(variant);
    }
  }, [status, convertTitleToVariant]);

  const handleInnerBoxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (openDropdown !== 'status') {
      setOpenDropdown('status');
    } else {
      setOpenDropdown(null);
    }
  };

  const handleStatusChange = (statusTitle: string) => {
    onStatusChange(statusTitle);
    setOpenDropdown(null);
    const newStatus = convertTitleToVariant(statusTitle);
    if (newStatus) {
      setInitialStatus(newStatus);
    }
  };

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenDropdown]);

  const statusMenuOpen = openDropdown === 'status';

  return (
    <Box
      ref={dropdownRef}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography sx={PrManagerTitleStyle}>Статус проекта</Typography>

      <Box sx={PrStatusBoxStyle} onClick={handleInnerBoxClick}>
        {initialStatus}
        <IconButton
          sx={{
            color: "black",
            width: "16px",
            height: "16px",
          }}
        >
          {statusMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        {statusMenuOpen && (
          <Box sx={PrEditStatusMenuStyle}>
            {projectStatuses.map((projectStatus) => (
              <MenuItem
                key={projectStatus.title}
                onClick={() => handleStatusChange(projectStatus.title)}
              >
                {projectStatus.variant}
              </MenuItem>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
