import React, { useEffect, useState } from "react";
import { Avatar, Grid, Input, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SubNavBar } from "../../../components/subNavBar/SubNavBar";
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useUploadUserAvatarMutation,
} from "../../../services/user";
import { JHButton } from "../../../UI/jHButton/JHButton";
import { getRef } from "../../../utils/refUtils";
import { IUserData } from "../model/UserData";

export const LkProfileEdit = () => {
  const { data: userData } = useGetUserDataQuery("2");
  const [updateUser] = useUpdateUserDataMutation();
  const [uploadAvatar] = useUploadUserAvatarMutation();

  const [avatarUrl, setAvatarUrl] = useState("");

  // useEffect(() => {
  //   setAvatarUrl(userData ? userData.avatar : "nikit.jpg");
  // }, [userData]);

  const refNickName = React.createRef<HTMLInputElement>();
  const refReg = React.createRef<HTMLInputElement>();
  const refName = React.createRef<HTMLInputElement>();
  const refLastname = React.createRef<HTMLInputElement>();
  const refCountry = React.createRef<HTMLInputElement>();
  const refTown = React.createRef<HTMLInputElement>();
  const refPhone = React.createRef<HTMLInputElement>();
  const refEmail = React.createRef<HTMLInputElement>();
  const refGithub = React.createRef<HTMLInputElement>();
  const refTelegram = React.createRef<HTMLInputElement>();
  const refBio = React.createRef<HTMLInputElement>();
  const refFile = React.createRef<HTMLInputElement>();

  const handleSubmit = (e: any) => {
    const user = {
      //id: userData?.id,
      nick_name: getRef(refReg),
      full_name: `${getRef(refName)} ${getRef(refLastname)}`,
      country: getRef(refCountry),
      town: getRef(refTown),
      phone: getRef(refPhone),
      email: getRef(refEmail),
      github_link: getRef(refGithub),
      telegram: getRef(refTelegram),
      bio: getRef(refBio),
    };
    updateUser(user);
  };

  const handleUpload = async () => {
    const formData = new FormData();

    if (refFile.current && refFile.current.files)
      formData.append("avatar", refFile.current.files[0]);
    formData.append("name", "nikit");
    const res = await uploadAvatar(formData);
    console.log(res);
  };

  const handlePickFile = () => {
    refFile.current && refFile.current.click();
  };

  const subPages = [
    { title: "Профиль", url: "/profile" },
    { title: "Аккаунт", url: "/account" },
  ];
  return (
    <>
      <Grid container>
        <Grid item lg={4} md={4} xs={12}>
          <>
            <Avatar
              src={avatarUrl}
              sx={{ width: 100, height: 100, cursor: "pointer" }}
              onClick={handlePickFile}
            />
            <Input
              type="file"
              onChange={handleUpload}
              inputRef={refFile}
              sx={{ width: 0, height: 0 }}
              inputProps={{ accept: ".jpg, .jpeg" }}
            ></Input>

            <Typography>Основная информация</Typography>
            <Typography>Контакты</Typography>
            <Typography>О себе</Typography>
            <Typography>Профессии</Typography>
            <JHButton onClick={(e: any) => handleSubmit(e)}>Сохранить</JHButton>
          </>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <SubNavBar pages={subPages} />
          <>
            <Typography variant="h2">Основная информация </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {userData && (
                <>
                  {/* <TextField
                    label="Имя"
                    inputRef={refName}
                    defaultValue={userData.firstName}
                  ></TextField>
                  <TextField
                    label="Фамилия"
                    inputRef={refLastname}
                    defaultValue={userData.lastName}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.nickName}
                    label="Nickname"
                    inputRef={refNickName}
                  ></TextField>
                  <TextField
                    label="Дата регистрации"
                    inputRef={refReg}
                    defaultValue={userData.createDate}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.location}
                    label="Страна"
                    inputRef={refCountry}
                  ></TextField> */}
                  {/* <TextField
                    defaultValue={userData?.town}
                    label="Город"
                    inputRef={refTown}
                  ></TextField> */}
                  <Typography variant="h2">Контакты</Typography>
                  {/* <TextField
                    defaultValue={userData?.phone}
                    label="Телефон"
                    inputRef={refPhone}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.email}
                    label="Email"
                    inputRef={refEmail}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.github_link}
                    label="Github"
                    inputRef={refGithub}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.telegram}
                    label="Telegram"
                    inputRef={refTelegram}
                  ></TextField>
                  <TextField
                    defaultValue={userData?.bio}
                    label="О себе"
                    inputRef={refBio}
                    multiline
                    minRows={5}
                  ></TextField> */}
                </>
              )}
            </Box>

            <Box>
              <Typography variant="h2">Мои навыки</Typography>
              {/* {userData &&
                userData.stacks.map((stack) => <></>)} */}
            </Box>
          </>
        </Grid>
      </Grid>

      {/* {stacks
            ? stacks.map((stack) => (
                <Grid item lg={2} md={4} xs={12} key={stack.id}>
                  <SkillsItem stacks={stack} />
                </Grid>
              ))
            : null} */}

      {/* Добавить скилл */}
    </>
  );
};
