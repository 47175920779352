import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://185.46.197.203:39811/api/' }),
  endpoints: (builder) => ({
    getTasks: builder.query<any, number>({
      query: (projectId) => `tasks/all/${projectId}`,
    }),
    getStatuses: builder.query<any, void>({
      query: () => `statuses`,
    }),
  }),
})
export const { useGetTasksQuery, useGetStatusesQuery } = tasksApi;
