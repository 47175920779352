import React from "react";
import { Star, StarBorder } from "@mui/icons-material";
import { Box } from "@mui/material";

interface DifficultyStarsProps {
  difficulty?: number;
}

export const DifficultyStars = ({ difficulty }: DifficultyStarsProps) => {
  const stars = [];
  const validDifficulty = difficulty || 0;

  for (let i = 0; i < 10; i++) {
    if (i < validDifficulty) {
      stars.push(
        <Star
          key={i}
          color="primary"
          sx={{ height: "27px", width: "27px" }}
        />
      );
    } else {
      stars.push(
        <StarBorder
          key={i}
          color="primary"
          sx={{ height: "27px", width: "27px" }}
        />
      );
    }
  }

  return <Box>{stars}</Box>;
};
