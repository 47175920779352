// @ts-nocheck
import { Box } from "@mui/material";
import video from "./video.mp4";

export const Video = () => {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down("lg")]: {
          height: "auto",
        },
      })}
    >
      <video
        src={video}
        loop
        muted
        autoPlay
        width="100%"
        style={{
          display: "block",
          position: "absolute",
          bottom: "0",
          zIndex: 0
        }}
      />
    </Box>
  );
};
