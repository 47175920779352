import { Chip, LinearProgress, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { tokens } from "../../../../components/theme";
import { IProject } from "../../../projects/model/projectData";
import { JHBadge } from "./jHBadge/JHBadge";

interface IUserProjectsProps {
  projects: IProject[];
}

export const UserProjects = ({ projects }: IUserProjectsProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getDate = (date: string) => {
    function addLeadingZero(num: number) {
      return num < 10 ? "0" + num : num;
    }
    const date2 = new Date(`${date}`);
    return `${addLeadingZero(date2.getDate())}.${addLeadingZero(
      date2.getMonth() + 1
    )}.${date2.getFullYear()}`;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "844px",
          overflowX: "auto",
          gap: "25px",
        }}
      >
        {projects.map((project) => (
          <Box
            sx={{
              width: "844px",
              height: "auto",
              background: theme.palette.background.paper,
              p: "10px",
              overflow: "hidden",
              display: "grid",
              gridTemplateColumns: "120px 1fr",
              gap: "35px",
            }}
            key={project.id}
          >
            <Box sx={{}}>
              <JHBadge
                width="100px"
                count={project.members?.length}
                complete={project.completeCount}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#D0D0D0",
                  }}
                />
              </JHBadge>
              <Typography
                fontSize="14px"
                fontWeight="600"
                sx={{ textAlign: "center" }}
              >
                {project.title}
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight="300"
                fontFamily="Nunito"
                textAlign="center"
              >
                {project.userRole && project.userRole.name}
              </Typography>
              <Typography fontSize="14px" fontWeight="400">
                Start: {getDate(`${project.startTimestamp}`)}
              </Typography>
            </Box>
            <Box>
              <LinearProgress
                variant="determinate"
                color="success"
                value={project.progress}
                sx={{ height: "13px" }}
              />
              <Typography sx={{ fontFamily: "Nunito" }}>
                Описание проекта
              </Typography>
              <Typography sx={{ fontFamily: "Nunito" }}>
                {project.description}
              </Typography>

              <Box sx={{ display: "flex", gap: "26px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Nunito",
                  }}
                >
                  Вакансии
                </Typography>

                <Box sx={{ display: "flex", gap: "7px", flexWrap: "wrap" }}>
                  {project.vacancies.map((vacancy: any) => (
                    <Chip
                      key={vacancy.id}
                      sx={{
                        backgroundColor: colors.blue[50],
                        color: colors.black[100],
                        fontFamily: "Nunito",
                      }}
                      label={vacancy.role.name}
                    ></Chip>
                  ))}
                </Box>

                <NavLink
                  style={{ color: colors.blue[200], fontFamily: "Nunito" }}
                  to={`/projects/${project.id}`}
                >
                  Подробнее...
                </NavLink>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
