export const titleStyle = {
    fontFamily: "Zen Maru Gothic",
    fontWeight: 700,
    fontSize: "28px",
  }

  export const titleBoxStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    mt: "10px",
  }

  export const difficultyTextStyle = {
    color: "#82D9FF",
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "16px",
  }

  export const projectInfoBoxStyle = {
    display: "flex",
      }

  export const projectSquadTextSyle = {
    mt: "15px",
    fontFamily: "Zen Maru Gothic",
    fontWeight: 700,
    fontSize: "16px",
    color: "#E6E1E5",
  }

  export const projectInfoDeskBoxStyle = {
    pt: "15px",
    pb: "35px",
    mt: "5px",
    display: "flex",
    flexDirection: "column",
    width: "290px",
    minHeight: "480px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
  }