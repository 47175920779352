import { Box, BoxProps, Button, useTheme } from "@mui/material";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoImg } from "../assets/juniorhub.svg";
import background from "../assets/shield.png";
import { Title } from "./Title";
import { tokens } from "../../../../components/theme";

const Logo = () => {
  return (
    <Box
      sx={{
        width: "86px",
        alignSelf: "end",
        boxSizing: "border-box",
        mt: "41px",
      }}
    >
      <LogoImg
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};

export const Shield = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const handleRegistration = () => {
    navigate("/register");
  };

  return (
    <Box
      ref={ref}
      {...props}
      sx={(theme) => ({
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: "1fr",
        width: "400px",
        height: "auto",
        justifyItems: "center",
        aspectRatio: 0.852,
        background: `url(${background})`,
        backgroundSize: "cover",
        position: "relative",
        zIndex: 999,
      })}
    >
      <Logo />
      <Title />
      <Button
        sx={{
          p: "4px 14px",
          color: "#ffffff",
          background: colors.blue[300],
          textTransform: "none",
          mt: "100px",
          mb: "134px",
          fontSize: "20px",
          borderRadius: "18px",
          ":hover": {
            background: colors.blue[500],
          },
        }}
        type="submit"
        onClick={() => handleRegistration()}
      >
        Найти команду
      </Button>
    </Box>
  );
});
