import React from "react";
import { Avatar, Box, useTheme } from "@mui/material";
import { IUserData } from "../../../../model/UserData";
import { tokens } from "../../../../../../components/theme";
import { LocationIcon } from "../../../../../../UI/Icons/LocationIcon";

interface IInfoBaseBasicProps {
  userData: IUserData;
}

export const InfoBaseBasic = ({ userData }: IInfoBaseBasicProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        fontFamily: "Nunito",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          color: colors.grey[200],
          fontFamily: "Nunito",
        }}
      >
        <Avatar
          src={`${userData.avatar}`}
          sx={{
            gridColumn: "2/3",
            justifySelf: "center",
            width: 100,
            height: 100,
            bgcolor: theme.palette.primary.light,
          }}
        />
        <Box
          sx={{
            gridColumn: "3/3",
            justifySelf: "center",
            display: "grid",
            width: "88px",
            height: "71px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              py: "4px",
              textAlign: "center",
              color: colors.grey[50],
              fontFamily: "Nunito"
            }}
          >
            1111
            {userData.rating}
          </Box>
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: 200,
              color: colors.grey[200],
              lineHeight: "14.52px",
              fontFamily: "Nunito"
            }}
          >
            место в рейтинге
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItem: "center",
            width: "234px",
            fontWeight: 700,
            fontSize: "20px",
            textTransform: "none",
            fontFamily: "Zen Maru Gothic",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "4px",
            color: colors.grey[200],
          }}
        >
          <Box>{userData.firstName}</Box>
          <Box>{userData.lastName}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: "16px",
              marginTop: "10px",
              fontWeight: 300,
              fontFamily: "Nunito",
              color: colors.grey[200],
            }}
          >
            <Box>{userData.nickName}</Box>
            <Box
              sx={{
                display: "grid",
                gap: "4px",
                gridTemplateColumns: "max-content max-content",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LocationIcon />
              {userData.location}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
