import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface ProjectWorkingHoursItemsProps {
  hoursCount: number;
  hoursOnCountChange: (newCount: number) => void;
}

export const ProjectWorkingHoursItems = ({
  hoursCount,
  hoursOnCountChange,
}: ProjectWorkingHoursItemsProps) => {
  const handleIncrement = () => {
    hoursOnCountChange(hoursCount + 1);
  };
  const handleDecrement = () => {
    if (hoursCount > 0) {
      hoursOnCountChange(hoursCount - 1);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ml: "10px"
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Кол.во часов в неделю
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          ml: "55px",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handleDecrement}>
          <RemoveIcon
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "26px",
            height: "26px",
            borderRadius: "4px",
            bgcolor: "#82D9FF",
            px: "9px",
            py: "4px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "12px",
              fontWeight: 400,
              color: "black",
            }}
          >
            {hoursCount}
          </Typography>
        </Box>
        <IconButton onClick={handleIncrement}>
          <AddIcon
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
