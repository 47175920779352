import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { authReducer } from './auth/authSlice';
import { userApi } from "../services/user"
import { postsApi } from "../services/posts"
import { stacksApi } from '../services/stacks';
import { projectsApi } from '../services/projects';
import { teamsApi } from '../services/teams';
import { tasksApi } from '../services/tasks';



export const store = configureStore({
  reducer: {
    auth: authReducer,
    [userApi.reducerPath]: userApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [stacksApi.reducerPath]: stacksApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    userApi.middleware,
    postsApi.middleware,
    stacksApi.middleware,
    projectsApi.middleware,
    teamsApi.middleware,
    tasksApi.middleware
  )
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
