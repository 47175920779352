import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { NavLink, useNavigate } from "react-router-dom";
import { Badge, useTheme } from "@mui/material";
import { IsAutorized } from "../isAutorized/IsAutorized";
import { useAppSelector } from "../../store/hooks";
import Logo from "../../assets/images/Logo.svg";
import ThemeSwitcherComponent from "./themeSwitcher/ThemeSwitcher";
import { tokens } from "../theme";

const pagesUser = [
  { title: "Главная", url: "/lk" },
  { title: "Проекты", url: "/projects" },
  { title: "Команды", url: "/teams" },
  { title: "Рабочая зона", url: "/work" },
];

const pagesGuest = [
  { title: "Главная", url: "/" },
  { title: "О проекте", url: "/aboutproject" },
  { title: "Контакты", url: "/contacts" },
  { title: "О нас", url: "/aboutus" },
  { title: "Регистрация", url: "/register" },
  { title: "Войти", url: "/login" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBarHorizontal() {
  const navigate = useNavigate();
  const isAutorized = useAppSelector((state) => state.auth.user);
  const pages = isAutorized ? pagesUser : pagesGuest;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <AppBar
      position="sticky"
      elevation={1}
      sx={{
        backgroundColor: theme.palette.background.default,
        // maxWidth: "1440px",
        minHeight: "61px",
        p: "10px 144px 10px 144px",
        zIndex: 1
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: "41px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "860px",
            height: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <img src={Logo} alt="Logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <NavLink style={{
                    color: "#ffffff"
                  }} to={page.url}>{page.title}</NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "space-around",
              flexGrow: 1,
              margin: "0 5px",
              fontFamily: "Zen Maru Gothic",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {pages.map((page) => (
              <NavLink
                key={page.url}
                to={page.url}
                style={({ isActive }) =>
                  isActive
                    ? {
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        height: "32px",
                        padding: "4px 14px 4px 14px",
                        borderRadius: "18px",
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.light,
                        textDecoration: "none",
                      }
                    : {
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        height: "32px",
                        padding: "4px 14px 4px 14px",
                        borderRadius: "18px",
                        color: theme.palette.primary.contrastText,
                        textDecoration: "none",
                      }
                }
              >
                {page.title}
              </NavLink>
            ))}
          </Box>
        </Box>
        <IsAutorized>
          <Box
            sx={{
              display: "flex",
              maxWidth: "420px",
              height: "32px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "300px",
                height: "32px",
                color: `${colors.grey[50]}`,
                backgroundColor: `${colors.blue[300]}`,
                borderRadius: "8px",
                p: "1px 20px 1px 1px",
              }}
            >
              <InputBase
                placeholder="Найти"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "235px",
                  height: "30px",
                  borderRadius: "8px",
                  color: `${colors.grey[700]}`,
                  fontFamily: "Nunito",
                  fontWeight: 500,
                  backgroundColor: theme.palette.background.default,
                  paddingLeft: "16px",
                }}
              />
              <IconButton
                sx={{
                  p: "3px 1px 3px 16px",
                }}
              >
                <SearchIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </IconButton>
            </Grid>
            <IconButton
              sx={{
                width: "32px",
                height: "32px",
                p: "8px 8px 8px 8px",
              }}
              aria-label="show 5 new notifications"
              color="inherit"
            >
              <Badge
                overlap="circular"
                badgeContent=" "
                variant="dot"
                color="error"
              >
                <NotificationsNoneIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: `${colors.grey[500]}`,
                  }}
                />
              </Badge>
            </IconButton>
            <IconButton
              sx={{
                width: "32px",
                height: "32px",
                p: "8px 8px 8px 8px",
              }}
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate("profile")}
            >
              <PermIdentityIcon
                sx={{
                  width: "16px",
                  height: "16px",
                  color: `${colors.grey[500]}`,
                }}
              />
            </IconButton>
            <ThemeSwitcherComponent />
          </Box>
        </IsAutorized>
      </Toolbar>
      <Box sx={{ flexGrow: 0 }}>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </AppBar>
  );
}
export default NavBarHorizontal;
