import React from "react";
import { Box } from "@mui/material";
import { ProjectChat } from "./components/ProjectChat";
import { IProject } from "../../../model/projectData";

export const ProjectChatStack = () => {
  return (
    <Box>
      <ProjectChat />
    </Box>
  );
};
