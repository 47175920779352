export const PrManagerOutterBoxStyle = {
    display: "flex",
    justifyContent: "center",
    mt: "15px",
    width: "290px",
    minHeight: "480px",
    borderRadius: "8px",
    bgcolor: "#2E3153",
  }

  export const PrManagerInnerBoxStyle = {
    pt: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  }

  export const PrManagerTitleStyle = {
    fontFamily: "Zen Maru Gothic",
          fontSize: "20px",
          fontWeight: 700,
  }

  export const PrStatusBoxStyle = {
    width: "185px",
    minHeight: "36px",
    ml: "auto",
    mr: "auto",
    bgcolor: "#D6F3FF",
    color: "black",
    borderRadius: "4px",
    display: "flex",
    px: "7px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 500,
    position: "relative",
  }

  export const PrEditStatusMenuStyle = {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "white",
    color: "black",
    top: "35px",
    left: "10px",
    borderRadius: "4px"
  }