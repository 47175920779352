import React, { useRef } from "react";
import { useHandleOutsideClick } from "../helpers/useHandleOutsideClick";
import { Box, MenuItem } from "@mui/material";
import { roleMenuOutterBoxStyle } from "./SelectedRoles/RoleMenuStyle";


interface RoleMenuProps {
  open: boolean;
  selectedOption?: number ;
  options: { id: number; name: string }[];
  onChange: (option: number ) => void;
  onOutsideClick: () => void;
}

export const RoleMenu = ({
  open,
  selectedOption,
  options,
  onChange,
  onOutsideClick,
}: RoleMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useHandleOutsideClick(open, onOutsideClick, menuRef);

  const handleListMouseDown = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <Box
      ref={menuRef}
      sx={roleMenuOutterBoxStyle}
      onMouseDown={handleListMouseDown}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => onChange(option.id)}
          selected={option.id === selectedOption}
        >
          {option.name}
        </MenuItem>
      ))}
    </Box>
  );
};


