import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://jsonplaceholder.typicode.com' }),
  endpoints: (builder) => ({
    getPosts: builder.query < any, void> ({
      query: () => `posts/1`,
    }),
    getAlbums: builder.query < any, void> ({
      query: () => `albums/1`,
    }),
  }),
  
})
export const { useGetPostsQuery, useGetAlbumsQuery } = postsApi;
