import React from "react";
import { Box, Typography } from "@mui/material";
import { chatOutterBoxStyle, chatTextStyle } from "../../PrWorkingPanel/components/styles/WorkingPanelStyles";

export const ProjectChat = () => {
  return (
    <>
      <Typography
        sx={chatTextStyle}
      >
   Чат
      </Typography>
      <Box
        sx={chatOutterBoxStyle}
      ></Box>
    </>
  );
};