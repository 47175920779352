import React, { useEffect, useState } from "react";
import PrNavBar from "../components/prNavBar/PrNavBar";
import { Typography, Grid, Box, Pagination} from "@mui/material";
import PrItem from "../components/projectItem/ProjectItem";
import { projectsApi } from "../../../services/projects";
import { IProject } from "../model/projectData";
import PrSearch from "../components/prSearch/prSearch";
import useFilteredAndSortedProjects from "../components/FilterandSort/useFilteredAndSortedProjects";

export default function PrSelected() {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [originalFavoriteProjects, setOriginalFavoriteProjects] = useState<
    IProject[]
  >([]);

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;
  const userId = 3;

  const {
    data: projects,
    error,
    isLoading,
  } = projectsApi.useGetProjectsQuery();

  const favoriteProjects = useFilteredAndSortedProjects(
    projects?.filter((project: IProject) => project.isFavorite) || [],
    search,
    sort,
    selectedFilters,
    [],
    false
  );

  useEffect(() => {
    setOriginalFavoriteProjects(favoriteProjects);
  }, [favoriteProjects]);

  const handleTabChange = (tab: string) => {};

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(favoriteProjects.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = favoriteProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "28px",
          fontWeight: 700,
        }}
      >
        Проекты
      </Typography>
      <Box
        sx={{
          mt: "10px",
        }}
      >
        <PrNavBar onTabChange={handleTabChange} />
      </Box>
      <PrSearch
        inputSearch={setSearch}
        handleFilterProf={setSelectedFilters}
        sort={setSort}
        searchValue={search}
      />

      <Typography
        sx={{
          fontFamily: "Zen Maru Gothic",
          fontSize: "28px",
          fontWeight: 700,
          mt: "10px",
        }}
      >
        Избранные проекты
      </Typography>
      {favoriteProjects.length === 0 ? (
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: 700,
            mt: "150px",
            textAlign: "center",
          }}
        >
          Добавьте проект в избранное, чтобы он отобразился в этой вкладке.
        </Typography>
      ) : (
        <Grid sx={{ mt: "20px" }} container spacing={3}>
          {currentProjects.map((listItem: IProject) => {
            return (
              <Grid item lg={4} md={6} xs={12} key={listItem.id}>
                <PrItem
                  listItem={listItem}
                  isFavorite={listItem.isFavorite}
                  userId={userId}
                />
              </Grid>
            );
          })}
        </Grid>
      )}

      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            mt: "30px",
            justifyContent: "center",
            alignSelf: "flex-end",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            size="large"
            variant="outlined"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </>
  );
}
