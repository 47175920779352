import { CircularProgress, Box } from "@mui/material";

export default function ProgressBar() {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '50%',
        right: '48%'
      }}
    >
      <CircularProgress />
    </Box>
  );
}
