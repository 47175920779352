import React from "react";

export const outterBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  }

  export const titleStyle = {
    fontFamily: "Nunito",
    fontSize: "14px",
    fontWeight: 400,
  }

  export const iconCheckStyle= {
    width: "24px",
    height: "26px",
    color: "#0ED050",
    mr: "33px",
    cursor: "not-allowed",
  }

  export const buttonCancelStyle = {
    width: "90px",
    height: "26px",
    bgcolor: "#82D9FF",
    color: "black",
    fontFamily: "Nunito",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "13px"
  }

  export const buttonApplyStyle = {
    width: "90px",
    height: "26px",
    bgcolor: "#82D9FF",
    color: "black",
    fontFamily: "Nunito",
    fontSize: "12px",
    fontWeight: 400,
  }