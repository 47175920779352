import React from "react";
import { IconButton, CircularProgress } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { Box, ListItemAvatar, Typography, Avatar } from "@mui/material";
import { useGetUserTeamByIdQuery, useUpdateTeamAvatarMutation } from "../../../../services/teams";
import { TeamNavBar } from "../TeamNavBar/TeamNavBar";
import { ITeamData } from "../../models/ITeamData";
import { useTheme } from "@mui/material";
import TeamAvatarModal from "../modal/TeamAvatarModal";
import {useState} from "react"

interface TeamCurrentBarProps {
  teamId: number;
  teams: ITeamData[];
  currentTeamId: number | undefined;
  onTeamClick: (team: ITeamData) => void;
}
export const TeamCurrentBar = ({
  teamId,
  teams,
  currentTeamId,
  onTeamClick,
}: TeamCurrentBarProps) => {
  const { data: teamData, isLoading } = useGetUserTeamByIdQuery(teamId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateAvatar, isSuccess] = useUpdateTeamAvatarMutation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const theme = useTheme();

  const handleSaveAvatar = async (avatar: string) => {
    if (!teamData || !teamData.id) {
      return;
    }

    const updatedTeam: ITeamData = {
      ...teamData,
      avatar: avatar,
    };

   await updateAvatar({
      id: teamData.id,
      team: updatedTeam,
    });
      if ( isSuccess) {

    handleCloseModal();
      }
  };

  const handleCancelAvatar = () => {
    handleCloseModal();
  };  
  
  


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!teamData) {
    return <Typography>Failed to fetch team data</Typography>;
  }
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        mt: "29px",
      }}
    >
      <Box>
        <ListItemAvatar
          sx={{ display: "flex", flexDirection: "row", position: "relative" }}
        >
          <Avatar
            src={teamData.avatar}
            alt={teamData.name}
            sx={{
              color: theme.palette.grey[500],
              bgcolor: theme.palette.grey[500],
              width: "100px",
              height: "100px",
            }}
          />
          <IconButton
           onClick={handleOpenModal}
            sx={{
              alignSelf: "flex-end",
              width: "26px",
              height: "26px",
              position: "absolute",
              left: "95px",
            }}
          >
            <CameraAltOutlinedIcon
            sx={{color: theme.palette.grey[500]}}/>
          </IconButton>
          
        </ListItemAvatar>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ml: "50px",
          width: "calc(100% - 150px)"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center"
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
            alignItems: "center"
          }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
              fontFamily: "Zen Maru Gothic",
            }}
          >
            Команда{` `}
            {teamData.name}
          </Typography>
          <Typography
            sx={{
              ml: "18px",
              fontWeight: 500,
              fontSize: "12px",
              fontFamily: "Nunito",
              alignSelf: "center",
            }}
          >
            рейтинг: {teamData.rating}
          </Typography>
          </Box>
          <Box sx={{
            width: "50%",
            mr: "10px"
          }}>
          <TeamNavBar
            teams={teams}
            currentTeamId={currentTeamId}
            onTeamClick={onTeamClick}
          />
          </Box>
        </Box>
        <Box sx= {{
          display: "flex",
          width: "100%",
          flexWrap: "wrap"
          
        }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              fontFamily: "Zen Maru Gothic",
              wordWrap: "break-word"
            }}
          >
            {teamData.description}
          </Typography>
        </Box>
      </Box>
      <TeamAvatarModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveAvatar}
        onCancel={handleCancelAvatar}
      />
    </Box>
  );
};



