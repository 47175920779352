import React from "react";
import { Box } from "@mui/material";
import { IProjectNewRolesBlock, IProjectRole } from "../../../../model/projectData";

interface SelectedRolesItemsProps {
  blockState: IProjectNewRolesBlock
  projectRoles?: IProjectRole[]
}

export const SelectedRolesItems = ({
  blockState, projectRoles
}: SelectedRolesItemsProps) => {
  const role = projectRoles?.find((role)=> role.id === blockState.selectedRole)
  return (
    <Box
      sx={{
        fontFamily: "Zen Maru Gothic",
        fontSize: "20px",
        fontWeight: 700,
      }}
    >
      {role ? role.name : "Роль не выбрана"}
    </Box>
  );
};
