import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import SearchInput from "./components/SearchInput";
import FilterButton from "./components/FilterButton";
import FilterMenu from "./components/FilterMenu";
import SortButton from "./components/SortButton";
import SortMenu from "./components/SortMenu";
import { sortType, filterType } from "../../model/projectData";

interface PrSearchProps {
  inputSearch: Function;
  handleFilterProf: Function;
  sort: Function;
  searchValue: string;
  onFocus?: () => void;
}

const PrSearch = ({
  inputSearch,
  handleFilterProf,
  sort,
  searchValue,
  onFocus,
}: PrSearchProps) => {
  const [searchTxt, setSearchTxt] = useState(searchValue);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [selectedSortOption, setSelectedSortOption] = useState(sortType.new);
  const [openSortMenu, setOpenSortMenu] = useState(false);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSearchTxt(searchValue);
    setSelectedFilters([]);
    setIsFilterApplied(false);
  }, [searchValue]);

  const handleInputChange = (value: string) => {
    setSearchTxt(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newTimeout = setTimeout(() => {
      inputSearch(value);
      setIsFilterApplied(false);
      setSelectedFilters([]);
      handleFilterProf([]);
    }, 500);

    setSearchTimeout(newTimeout);
  };

  const handleFilterOptionChange = (option: string) => {
    const isSelected = selectedFilters.includes(option);
    let updatedFilters: string[];

    if (isSelected) {
      updatedFilters = selectedFilters.filter((filter) => filter !== option);
    } else {
      updatedFilters = [...selectedFilters, option];
    }

    setSelectedFilters(updatedFilters);
    handleFilterProf(updatedFilters);
    setIsFilterApplied(true);
  };

  const handleSortOptionChange = (option: string) => {
    setSelectedSortOption(option);
    setOpenSortMenu(false);
    sort(option);
  };

  const handleFilterButtonClick = () => {
    setOpenFilterMenu((prevState) => !prevState);
    setOpenSortMenu(false);
  };

  const handleSortButtonClick = () => {
    setOpenSortMenu((prevState) => !prevState);
    setOpenFilterMenu(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        !dropdownRef.current ||
        !(event.target as Node)?.contains(dropdownRef.current)
      ) {
        setOpenSortMenu(false);
        setOpenFilterMenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSortMenuOutsideClick = () => {
    setOpenSortMenu(false);
  };

  const handleFilterMenuOutsideClick = () => {
    setOpenFilterMenu(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "14px",
          }}
        >
          Введите ключевые слова
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "368px",
            height: "36px",
            borderRadius: "12px",
          }}
        >
          <SearchInput
            value={searchTxt}
            onChange={(value) => handleInputChange(value)}
            onFocus={onFocus}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "16px",
            }}
          >
            Фильтр профессий
          </Typography>
          <FilterButton
            onClick={handleFilterButtonClick}
            open={openFilterMenu}
          />
          {openFilterMenu && (
            <FilterMenu
              open={openFilterMenu}
              filters={Object.values(filterType)}
              selectedFilters={selectedFilters}
              onChange={handleFilterOptionChange}
              onOutsideClick={handleFilterMenuOutsideClick}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: "16px",
            }}
          >
            {selectedSortOption === "new"
              ? sortType.new
              : selectedSortOption === "old"
              ? sortType.old
              : selectedSortOption === "completed"
              ? sortType.completed
              : sortType.abc}
          </Typography>
          <SortButton onClick={handleSortButtonClick} />
          {openSortMenu && (
            <SortMenu
              open={openSortMenu}
              selectedOption={selectedSortOption}
              options={Object.keys(sortType)}
              onChange={handleSortOptionChange}
              onOutsideClick={handleSortMenuOutsideClick}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PrSearch;
