import React from "react";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  projectButtonIconStyle,
  projectButtonTextStyle,
} from "./styles/ProjectButtonStyles";
export const ProjectAgileButton = () => {
  return (
    <Box>
    <Button>
      <EditIcon sx={projectButtonIconStyle} />
      <Typography sx={projectButtonTextStyle}>Agile доски</Typography>
    </Button>
    </Box>
  );
};
