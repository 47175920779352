import ModeIcon from "@mui/icons-material/Mode";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../components/theme";
import {
  useUpdateUserDataMutation,
  useCreateUserSocialMutation,
  userApi,
  useAddUserContactMutation,
} from "../../../services/user";
import { InfoBaseStub } from "../lkProfileViewOwner/components/InfoBase/InfoBaseStub";
import { InfoBaseBasic } from "../lkProfileViewOwner/components/InfoBase/components/InfoBaseBasic";
import { UserSkills } from "../lkProfileViewOwner/components/UserSkills/UserSkills";
import { ISubSkill } from "../model/UserData";
import { Confidentiality } from "./components/Confidentiality";

export enum Visible {
  all = "all",
  friends = "friends",
  me = "me",
}

export interface UserPrivacyData {
  contacts: Visible;
  basic: Visible;
  projects: Visible;
  stacks: Visible;
}
export const LkAccount = () => {
  const { data: userData } = userApi.useGetUserDataQuery("1");
  const [userRegData, setUserRegData] = useState({
    email: "mail@mail.ru",
    password: "12345",
  });

  const [updateUser] = useUpdateUserDataMutation();
  const [addSocial] = useCreateUserSocialMutation();
  const [addContact] = useAddUserContactMutation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [socialProfile, setSocialProfile] = useState("");
  const [contact, setContact] = useState("");
  const userName = userData?.username;
  const id = userData?.id;
  // const listContacts = userData?.listContacts;
  // const listSocial = userData?.listSocial;

  const contactAdd = () => {
    addContact({
      userId: id,
      contactSocialName: contact,
    });
    setContact("");
  };

  const socialAdd = () => {
    addSocial({
      userId: id,
      contactSocialName: socialProfile,
    });
    setSocialProfile("");
  };

  useEffect(() => {
    if (userData && userData.firstName) setFirstName(userData.firstName);
    if (userData && userData.lastName) setLastName(userData.lastName);
    if (userData && userData.nickName) setNickName(userData.nickName);
    if (userData && userData.bio) setBio(userData.bio);
    if (userData && userData.location) setLocation(userData.location);
  }, [userData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserRegData({ ...userRegData, [name]: value });
  };

  const [contacts, setContacts] = useState(Visible.me);
  const [basic, setBasic] = useState(Visible.me);
  const [projects, setProjects] = useState(Visible.me);
  const [stacks, setStacks] = useState(Visible.me);

  const handleSubmitPrivacy = () => {
    const userPrivacy: UserPrivacyData = {
      basic,
      contacts,
      projects,
      stacks,
    };
    console.log(userPrivacy);
  };

  const handleSubmitRegData = () => {
    console.log(userRegData);
  };
  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  interface BlockState {
    selectedRole?: number;
    selectedSkill?: number;
    selectedSubSkills: ISubSkill[];
  }

  const initialBlockState: BlockState = {
    selectedRole: undefined,
    selectedSkill: undefined,
    selectedSubSkills: [],
  };

  const [blockStates, setBlockStates] = useState<BlockState[]>([
    { ...initialBlockState },
  ]);

  const handleBlockChange = (index: number, newBlock: BlockState) => {
    setBlockStates((prevStates) => {
      const updatedBlockStates = [...prevStates];
      updatedBlockStates[index] = newBlock;
      return updatedBlockStates;
    });
  };

  const handleSaveBase = () => {
    updateUser({
      id: id,
      username: userName,
      firstName: firstName,
      lastName: lastName,
      nickName: nickName,
      bio: bio,
      location: location,
      // listContacts,
      // listSocial,
      // listContacts: ["@Cruise_telegram"],
      // listSocial: ["cruise@github.com", "cruise.facebook.com"],
    });
  };
  const btnSmallStyle = {
    width: "157px",
    height: "40px",
    borderRadius: "44px",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: colors.blue[200],
    color: theme.palette.primary.main,
    "&:hover": {
      background: colors.blue[500],
    },
  };
  const btnLgStyle = {
    width: "279px",
    height: "40px",
    borderRadius: "44px",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: colors.blue[200],
    color: theme.palette.primary.main,
    "&:hover": {
      background: colors.blue[500],
    },
  };
  const titleStyle = {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Zen Maru Gothic",
    lineHeight: "16px",
    color: colors.grey[200],
    mt: "4px",
    mb: "4px",
  };
  const inputStyle = {
    width: "318px",
    height: "36px",
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: "4px",
    border: "solid 1px #83D9FF",
    color: colors.blue[200],
    "&:hover": {
      borderColor: colors.blue[600],
    },
  };

  return (
    <>
      <Grid
        container
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{
            width: "295px",
            height: "580px",
            borderRadius: "8px",
            p: "10px",
            bgcolor: theme.palette.primary.dark,
          }}
        >
          {userData ? <InfoBaseBasic userData={userData} /> : <InfoBaseStub />}
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          xs={12}
          sx={{
            width: "844px",
            ml: "24px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
              fontFamily: "Zen Maru Gothic",
              lineHeight: "16px",
              color: colors.grey[200],
              mt: "4px",
              mb: "16px",
            }}
          >
            Настройки и видимость
          </Typography>

          <Typography sx={titleStyle}>Регистрационные данные</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "845px",
              height: "118px",
              borderRadius: "8px",
              bgcolor: theme.palette.primary.dark,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "560px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100px",
                  height: "100px",
                  justifyContent: "space-around",
                  fontFamily: "Nunito",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                <span>E-mail</span>
                <span>Пароль</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  fontFamily: "Nunito",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                <span id="email">{userRegData.email}</span>
                <span id="password">{userRegData.password}</span>
              </Box>
            </Box>
            <Button sx={btnSmallStyle}>Сохранить</Button>
            <Link
              color={theme.palette.text.primary}
              bottom={35}
              position="relative"
            >
              <ModeIcon />
            </Link>
          </Box>

          <Confidentiality />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={titleStyle}>Мои навыки</Typography>
            <UserSkills onBlockChange={handleBlockChange} />
          </Box>

          <Typography sx={titleStyle}>Основная информация</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "845px",
              height: "352px",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: theme.palette.primary.dark,
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplate: "repeat(2, 65px)/repeat(2, 318px)",
                columnGap: "78px",
              }}
            >
              <Box>
                <InputLabel
                  sx={titleStyle}
                  htmlFor="outlined-adornment-password"
                >
                  Имя
                </InputLabel>
                <OutlinedInput
                  sx={inputStyle}
                  name="firstName"
                  id="outlined-adornment-name"
                  type={"text"}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName}
                ></OutlinedInput>
              </Box>
              <Box>
                <InputLabel
                  sx={titleStyle}
                  htmlFor="outlined-adornment-surname"
                >
                  Фамилия
                </InputLabel>
                <OutlinedInput
                  sx={inputStyle}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                ></OutlinedInput>
              </Box>
              <Box>
                <InputLabel
                  sx={titleStyle}
                  htmlFor="outlined-adornment-nickname"
                >
                  Никнейм
                </InputLabel>
                <OutlinedInput
                  value={nickName}
                  sx={inputStyle}
                  onChange={(e) => {
                    setNickName(e.target.value);
                  }}
                ></OutlinedInput>
              </Box>
              <Box>
                <InputLabel
                  sx={titleStyle}
                  htmlFor="outlined-adornment-location"
                >
                  Местоположение
                </InputLabel>
                <OutlinedInput
                  sx={inputStyle}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  value={location}
                ></OutlinedInput>
              </Box>
            </Box>
            <Box>
              <InputLabel sx={titleStyle} htmlFor="outlined-adornment-password">
                О себе
              </InputLabel>
              <OutlinedInput
                sx={{
                  width: "714px",
                  height: "84px",
                  borderRadius: "4px",
                  border: "solid 1px #83D9FF",
                  color: colors.blue[200],
                  "&:hover": {
                    borderColor: colors.blue[600],
                  },
                  mb: "20px",
                }}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
                value={bio}
              ></OutlinedInput>
            </Box>
            <Button onClick={handleSaveBase} sx={btnLgStyle}>
              Сохранить
            </Button>
          </Box>

          <Typography sx={titleStyle}>Контакты</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "845px",
              padding: "30px",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: theme.palette.primary.dark,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: "78px",
                mb: "20px",
              }}
            >
              <Box
                sx={{
                  rowGap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    fontWeight: 700,
                    fontSize: "16px",
                    fontFamily: "Zen Maru Gothic",
                    lineHeight: "16px",
                    color: colors.grey[200],
                  }}
                >
                  Социальные профили
                </Typography>
                {userData?.listSocial.map((social, ind) => (
                  <OutlinedInput
                    key={ind}
                    sx={inputStyle}
                    onChange={handleChange}
                    value={social}
                  ></OutlinedInput>
                ))}
                <OutlinedInput
                  sx={inputStyle}
                  onChange={(e) => {
                    setSocialProfile(e.target.value);
                  }}
                  value={socialProfile}
                ></OutlinedInput>
              </Box>

              <Box
                sx={{
                  rowGap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    fontWeight: 700,
                    fontSize: "16px",
                    fontFamily: "Zen Maru Gothic",
                    lineHeight: "16px",
                    color: colors.grey[200],
                  }}
                >
                  Мессенджеры
                </Typography>

                {userData?.listContacts.map((contact, ind) => (
                  <OutlinedInput
                    key={ind}
                    sx={inputStyle}
                    onChange={handleChange}
                    value={contact}
                  ></OutlinedInput>
                ))}
                <OutlinedInput
                  value={contact}
                  sx={inputStyle}
                  onChange={(e) => setContact(e.target.value)}
                ></OutlinedInput>
              </Box>
            </Box>
            <Button
              onClick={() => {
                socialProfile && socialAdd();
                contact && contactAdd();
              }}
              sx={btnLgStyle}
            >
              Сохранить
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
