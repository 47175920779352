import React from "react";
import { Box, Typography } from "@mui/material";
import StatusIndicator from "../../../../components/CardComponents/StatusIndicator";
import { IProject } from "../../../../model/projectData";
import { getStatusDetails } from "../../../../components/StatusIndicatorFunc/StatusIndicator";
import {  projectStatusTextStyle } from "./styles/ProjectNameAndPaceStyles";

interface ProjectStatusProps {
  project?: IProject;
}
export const ProjectStatus = ({ project }: ProjectStatusProps) => {
    const statusDetails = project ? getStatusDetails(project.status) : null;
    const projectStatusTitle = statusDetails ? statusDetails.tooltipText : "";
  return (
    <Box sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        mt: "15px"
    }}>
      {project && <StatusIndicator status={project.status} />}
      <Typography sx={projectStatusTextStyle}>
        {projectStatusTitle}    
      </Typography>
    </Box>
  );
};
