import { useEffect } from 'react';

export const useHandleOutsideClick = (open, onOutsideClick, menuRef) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current || !menuRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onOutsideClick, menuRef]);
};