import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  documentsOutterBoxStyle,
  documentsTypographyStyle,
} from "./styles/WorkingPanelStyles";
import { ViewAllItemsButton } from "./ViewAllItemsButton";

export const ProjectHistory = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <Typography sx={documentsTypographyStyle}>История изменений</Typography>
      <Box sx={documentsOutterBoxStyle(isExpanded)}>
        <ViewAllItemsButton isExpanded={isExpanded} onClick={handleClick} />
      </Box>
    </>
  );
};
