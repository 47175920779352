import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useGetUserTeamByIdQuery } from "../../../../services/teams";
import { TeamDocs } from "./TeamDocs/TeamDocs";
import { TeamMembers } from "./TeamMembers/TeamMembers";
import { TeamChat } from "./TeamChat/TeamChat";
import { TeamProjects } from "./TeamProjects/TeamProjects";

interface TeamItemListProps {
  teamId: number;
}

export const TeamItemList = ({ teamId }: TeamItemListProps) => {
  const { data: teamData, isLoading } = useGetUserTeamByIdQuery(teamId);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!teamData) {
    return <Typography>Failed to fetch team data</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mt: "15px",
          justifyContent: "space-between",
          flexWrap: "wrap"
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <TeamMembers
            teamId={teamId}
            members={teamData.members}
          />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <TeamDocs 
          documents={teamData.documents}
          teamId={teamId}
          
          />
        </Box>
        <Box sx={{ flexGrow: 1
         }}>
          <TeamChat />
        </Box>
        <Box sx={{ flexGrow: 1}}>
   <TeamProjects 
   projects={teamData.projects}
   />
        </Box>
      </Box>
    </>
  );
};

