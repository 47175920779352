import { ListItem } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import { Avatar } from "@mui/material";
import { IconButton } from "@mui/material";
import { ListItemText } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CrownIcon from "@mui/icons-material/EmojiEvents";
import React from "react";
import TeamMemberModal from "../../modal/TeamMemberModal";
import { ITeamMember } from "../../../models/ITeamData";
import { useTheme } from "@mui/material";

interface TeamMembersItemsProps {
  teamId: number;
  id: number;
  name: string;
  role: string;
  avatar: string;
  isTeamLeader: boolean;
}

export const TeamMembersItems = ({
  teamId,
  name,
  role,
  avatar,
  isTeamLeader,
}: TeamMembersItemsProps) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        p: 0,
        mb: "20px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ListItemAvatar
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <Avatar
          src={avatar}
          alt={name}
          sx={{
            width: "64px",
            height: "64px",
          }}
        />
        {isTeamLeader && (
          <CrownIcon
            sx={{
              position: "absolute",
              color: "gold",
              top: 0,
              right: "-20px",
            }}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        sx={{
          pl: "25px",
        }}
        primary={name}
        secondary={role}
        primaryTypographyProps={{
          fontFamily: "Nunito",
          fontSize: "16px",
          fontWeight: 500,
          color: theme.palette.text.primary,
        }}
        secondaryTypographyProps={{
          fontFamily: "Nunito",
          fontSize: "14px",
          fontWeight: 400,
          color: theme.palette.text.primary,
        }}
      />
      {!isTeamLeader && (
        <IconButton onClick={handleOpenModal}>
          <EditIcon />
        </IconButton>
      )}
      <TeamMemberModal
        open={openModal}
        onClose={handleCloseModal}
        member={
          {
            name,
            avatar,
            role,
            isTeamLeader,
          } as ITeamMember
        }
        onSave={() => {}}
        onDelete={() => {}}
        teamId={teamId}
      />
    </ListItem>
  );
};

// import { ListItem } from "@mui/material";
// import { ListItemAvatar } from "@mui/material";
// import { Avatar } from "@mui/material";
// import { IconButton } from "@mui/material";
// import { ListItemText } from "@mui/material";
// import { useCallback } from "react";
// import CancelIcon from "@mui/icons-material/Cancel";
// import EditIcon from "@mui/icons-material/Edit";

// interface TeamMembersItemsProps {
//   id: number;
//   name: string;
//   role: string;
//   avatar: string;
//   remove: (id: number) => void;
// }

// export const TeamMembersItems = ({
//   name,
//   role,
//   remove,
//   avatar,
//   id,
// }: TeamMembersItemsProps) => {
//   const handleDelete = useCallback(() => remove(id), [id]);

//   return (
//     <ListItem
//       sx={{
//         p: 0,
//         mb: "20px",
//         // maxHeight: 64,
//         display: "flex",
//         flexDirection: "row",
//       }}
//     >
//       <ListItemAvatar
//         sx={{ display: "flex", flexDirection: "row", position: "relative" }}
//       >
//         <Avatar
//           src={avatar}
//           alt={name}
//           sx={{
//             width: "64px",
//             height: "64px",
//           }}
//         />
//         <IconButton
//           onClick={handleDelete}
//           sx={{
//             position: "absolute",
//             width: 26,
//             height: 26,
//             top: 0,
//             right: -8,
//           }}
//         >
//           <CancelIcon
//             sx={{
//               color: "rgba(148, 148, 148, 1)",
//               backgroundColor: "white",
//               borderRadius: "100%",
//             }}
//           />
//         </IconButton>
//       </ListItemAvatar>
//       <ListItemText
//         sx={{
//           pl: "25px",
//         }}
//         primary={name}
//         secondary={role}
//         primaryTypographyProps={{
//           fontStyle: "Nunito",
//           fontSize: "16px",
//           fontWeight: 500,
//           color: "black",
//         }}
//         secondaryTypographyProps={{
//           fontStyle: "Nunito",
//           fontSize: "14px",
//           fontWeight: 400,
//           color: "black",
//         }}
//       />
//       <IconButton>
//         <EditIcon />
//       </IconButton>
//     </ListItem>
//   );
// };
