import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ITeamData, ITeamDoc, ITeamMember } from "../pages/teams/models/ITeamData";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const teamsApi = createApi({
  reducerPath: "teamsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["AllTeams","MyTeams", "TeamById"],
  endpoints: (builder) => ({
    getAllTeams: builder.query<ITeamData[], void>({
      query: () => ({
        url: "/teams",
        // params: {user_id: 1}
      }),
      providesTags: ["MyTeams"],
    }),
    getUserTeams: builder.query<ITeamData[], void>({
      query: () => ({
        url: "/teams",
      }),
      providesTags: ["MyTeams"],
    }),
    getUserTeamById: builder.query<ITeamData, number>({
      query: (id) => ({
        url: `/teams/${id}`,
      }),
      providesTags: ["TeamById"],
    }),

    createNewTeam: builder.mutation<ITeamData, { team: Partial<ITeamData> }> ({
      query: ({team}) => ({
        url: `/teams`,
        method: "POST",
        body: team,
      }),
      invalidatesTags: ["MyTeams"]
    }),

    updateTeamAvatar: builder.mutation<ITeamData, {id: number, team: ITeamData}>({
      query: ({id, team})=> ({
        url: `/teams/${id}`,
        method: "PUT",
        body: team,
      }),
      invalidatesTags: ["TeamById"]
    }),
    
    createTeamDocument: builder.mutation<ITeamDoc, { id: number, document: ITeamDoc }>({
      query: ({id, document}) => ({
        url: `/teams/${id}/documents`,
        method: "POST",
        body: document,
      }),
      invalidatesTags: ["TeamById"],
    }),
    updateTeamDocument: builder.mutation<ITeamDoc, {id: number, document: ITeamDoc}>({
      query: ({id, document})=> ({
        url: `/teams/${id}/documents/${document.id}`,
        method: "PUT",
        body: document,
      }),
      invalidatesTags: ["TeamById"]
    }),
    addTeamMember: builder.mutation<ITeamMember, any>({
      query: ({id, member}) => ({
        url: `/teams/${id}/members`,
        method: "POST",
        body: member
      }),
      invalidatesTags: ["TeamById"]
    }),
    deleteTeamDocument: builder.mutation<ITeamDoc, {id:number, document: ITeamDoc}>({
         query: ({id, document}) => ({
          url: `/teams/${id}/documents/${document.id}`,
          method: "DELETE"
         }),
         invalidatesTags: ["TeamById"]
    }),
   
    updateTeamMember: builder.mutation<ITeamMember, {id : number, member: ITeamMember}>({
      query: ({id, member })=> ({
        url: `/teams/${id}/members/${member.id}`,
        method: "PUT",
        body: member
      }),
      invalidatesTags: ["TeamById"]
    }),
    deleteTeamMember: builder.mutation<ITeamMember, {id: number, member: ITeamMember}>({
      query: ({id, member})=> ({
        url: `/teams/${id}/members/${member.id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["TeamById"]
    }),
    SendTlMessage: builder.mutation<ITeamMember, any>({
      query: ({id, member}) => ({
        url: `/teams/${id}/members`,
        method: "POST",
        body: member
      }),
      invalidatesTags: ["TeamById"]
    }),
  }),
});

export const {
  useGetUserTeamsQuery,
  useGetAllTeamsQuery,
  useGetUserTeamByIdQuery,
  useUpdateTeamAvatarMutation,
  useUpdateTeamDocumentMutation,
  useCreateTeamDocumentMutation,
  useDeleteTeamDocumentMutation,
  useAddTeamMemberMutation,
  useUpdateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useSendTlMessageMutation,
  useCreateNewTeamMutation
} = teamsApi;
