import {
  Box,
  Button,
  List,
  ListItemButton,
  Autocomplete,
  TextField,
  AutocompleteInputChangeReason,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { ITeamData } from "../models/ITeamData";
import { useTheme } from "@mui/material";

interface AllTeamsSearchProps {
  allTeams: ITeamData[];
  onSearch: (searchTerm: string) => void;
  onChange?: (searchTerm: string) => void;
}

export const AllTeamsSearch = ({ allTeams, onSearch, onChange}: AllTeamsSearchProps) => {
  const theme = useTheme();

  const initialSearchTerm = "";
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = () => {
    onSearch(searchTerm);
    setSearchTerm(initialSearchTerm);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      setSearchTerm(value || "");
      if (onChange) {
        onChange(value || "");
      }
    }
  };

  const handlePageClick = (event: MouseEvent) => {
    if (
      inputRef.current &&
      event.target instanceof Node &&
      !inputRef.current.contains(event.target)
    ) {
      setIsFocused(false);
    }
  };

  const handleAutofill = (name: string) => {
    setSearchTerm(name);
    setSuggestions([]);
  };

  const handleItemClick = (name: string) => {
    handleAutofill(name);
    handleSearch();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handlePageClick);
    return () => {
      document.removeEventListener("mousedown", handlePageClick);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "560px",
        height: "30px",
        borderRadius: "8px",
        border: "1px solid black",
        position: "relative",
      }}
    >
      <SearchOutlined
        sx={{
          width: "30px",
          height: "30px",
          pl: "10px",
        }}
      />

      <Autocomplete
        options={allTeams.map((team) => team.name)}
        noOptionsText={'Команда не найдена'}
        inputValue={searchTerm}
        onInputChange={handleInputChange}
        onChange={(event, value) => setSearchTerm(value || "")}
        forcePopupIcon={false}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            placeholder="Поиск команды"
            onKeyDown={handleKeyDown}
            variant="standard"
            fullWidth
            inputRef={inputRef}
          />
        )}
        autoComplete={false}
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          paddingLeft: "10px",
          fontFamily: "Roboto",
          fontSize: "14px",
        }}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <Button
        onClick={handleSearch}
        sx={{ p: 0, ml: "10px", mr: "14px", color: theme.palette.text.secondary }}
      >
        Найти команду
      </Button>
      {suggestions.length > 0 && isFocused && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid black",
            zIndex: 1,
          }}
        >
          <List sx={{ minWidth: "200px" }}>
            {suggestions.map((name) => (
              <ListItemButton
                key={name}
                onClick={() => handleItemClick(name)}
                sx={{ cursor: "pointer" }}
              >
                {name}
              </ListItemButton>
            ))}
          </List>
        </div>
      )}
    </Box>
  );
};
