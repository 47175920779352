import { Box, Typography, List, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ITeamDoc } from "../../../models/ITeamData";
import { TeamDocsItems } from "./TeamDocsItems/TeamDocsItems";
import { useMemo } from "react";
import CreateDocumentModal from "../../modal/CreateDocumentModal";
import React from "react";
import { useTheme } from "@mui/material";

interface TeamDocsProps {
  documents: ITeamDoc[];
  teamId: number;
}

export const TeamDocs = ({ documents, teamId }: TeamDocsProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const theme = useTheme();

  const memoizedDocuments = useMemo(() => {
    return documents.map((document) => (
      <TeamDocsItems
        key={document.id}
        id={document.id !== null ? document.id : undefined}
        name={document.name}
        description={document.description}
        avatar={document.avatar}
        publishedAt={document.publishedAt}
        teamId={teamId}
      />
    ));
  }, [documents]);

  return (
    <Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: "16px",
          fontFamily: "Zen Maru Gothic",
          fontWeight: 600,
        }}
      >
        ДОКУМЕНТЫ и ССЫЛКИ
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: "16px",
          width: "367px",
          height: "543px",
          bgcolor: theme.palette.background.paper,
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <List
          sx={{
            pt: "25px",
            pb: "83px",
            px: "30px",
            borderRadius: "8px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          {memoizedDocuments}
        </List>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "13px",

            mr: "16px",
          }}
        >
          <IconButton onClick={handleOpenModal}>
            <AddIcon
              sx={{
                height: "36px",
                width: "36px",
                color: theme.palette.grey[700],
                bgcolor: theme.palette.grey[100],
                borderRadius: "100%",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <CreateDocumentModal
        open={openModal}
        onClose={handleCloseModal}
        onSave={() => {}}
        teamId={teamId}
      />
    </Box>
  );
};
