import Alert from '@mui/material/Alert';
import {
  Modal,
  Typography,
  Input,
  Box,
  IconButton,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { ITeamDoc } from "../../models/ITeamData";
import { useSendTlMessageMutation } from "../../../../services/teams";
import NearMeIcon from "@mui/icons-material/NearMe";
import CrownIcon from "@mui/icons-material/EmojiEvents";

interface SendMessageModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  teamId: number;
  name: string;
  avatar: string;
  leaderName: string;
}



const SendMessageModal = ({
  open,
  onClose,
  onSave,
  name,
  avatar,
  leaderName,
  teamId,
}: SendMessageModalProps) => {
  const [sendMessage] = useSendTlMessageMutation();

  const handleSave = async () => {
    try {
    const newMessage = {} as ITeamDoc;

    await sendMessage({
      id: teamId,
      document: newMessage,
    });

    onSave();
    onClose();
  } catch(error) {
    <Alert severity="error">
      Не удалось отправить сообщение !
    </Alert>
  }
  };

  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "584px",
          minHeight: "279px",
          borderRadius: "40px",
          backgroundColor: "#D6F3FF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          p: 4,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Zen Maru Gothic",
                fontSize: "24px",
                fontWeight: 700,
                color: "black",
              }}
            >
              {"Команда"} {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "35px",
            }}
          >
            <Box>
              <AvatarGroup>
                <Avatar
                  src={avatar}
                  alt={name}
                  sx={{
                    width: "64px",
                    height: "64px",
                    borderColor: "#D6F3FF",
                  }}
                />
                <CrownIcon />
              </AvatarGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "42px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                {leaderName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Zen Maru Gothic",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
                }}
              >
                Project manager
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: "36px",
              display: "flex",
              alignItems: "center",
              width: "496px",
              height: "33px",
              borderRadius: "8px",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <Input
              disableUnderline
              type="text"
              placeholder="Написать руководителю"
              inputProps={{
                style: {
                  fontFamily: "Nunito",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "black",
                 
                },
              }}
              sx={{
                flex: 1,
                height: "100%",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                pl: "7px",
                pt: "4px",
                input: {
                "&::placeholder": {
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "black",
                    opacity: 1
                  },
                }
              }}
            />
            <IconButton
            onClick={handleSave}
              sx={{
                marginLeft: "8px",
              }}
            >
              <NearMeIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SendMessageModal;
