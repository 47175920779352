import { Grid, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { userApi } from "../../../services/user";
import { InfoBase } from "./components/InfoBase";
import { InfoBaseStub } from "./components/InfoBaseStub";
import { UserProjects } from "./components/UserProjects";
import { UserSkills } from "./components/UserSkills";
import { projectsApi } from "../../../services/projects";
import { stacksApi } from "../../../services/stacks";

export const LkProfileViewGuest = () => {
  const theme = useTheme();
  const params = useParams();
  const user_id = Number(params.id);
  const { data: userData } = userApi.useGetUserDataQuery(`${user_id}` || "1");
  const { data: projects } = projectsApi.useGetUserProjectsQuery({ user_id });
  const { data: skills } = stacksApi.useGetConfirmedSkillsQuery({
    id: `${user_id}`,
    type: "1",
  });

  return (
    <>
      <Grid container spacing={"26px"}>
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{
            bgcolor: theme.palette.primary.dark,
            borderRadius: "8px",
            p: "5px 15px",
            height: "600px",
          }}
        >
          {userData ? <InfoBase userData={userData} /> : <InfoBaseStub />}
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          {skills && <UserSkills skills={skills} />}
          {projects && <UserProjects projects={projects} />}
        </Grid>
      </Grid>
    </>
  );
};
