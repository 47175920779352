import React, { useEffect, useRef } from "react";
import { Box, IconButton, MenuItem } from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import { RoleMenu } from "./RoleMenu";
import { IProjectRolesSubskill, IProjectRole } from "../../model/projectData";
import { PeoplePerRole } from "./PeoplePerRole";
import { SubSkillConfirmationButton } from "./SelectedRoles/SubSkillConfirmationButton/SubSkillConfirmationButton";
import { IRole } from "./RoleCreationHooks/RoleCreationTypes";
import {
  InnerBox,
  OuterBox,
  SkillBox,
  SkillMenuBox,
} from "./SelectedRoles/BoxStyles";

interface RoleSelectBoxProps {
  blockId: string;
  projectRoles?: IProjectRole[];
  role: IRole;
  setSelectedRole: (roleId: number) => void;
  setSelectedSkill: (skillId: number) => void;
  onCountChange: (count: number) => void;
  onSubSkillConfirm: (subSkill: IProjectRolesSubskill) => void;
  onSubSkillUnconfirm: (subSkill: IProjectRolesSubskill) => void;
  openDropdown: string | null;
  setOpenDropdown: React.Dispatch<React.SetStateAction<string | null>>;
}

const RoleSelectBox = ({
  blockId,
  projectRoles,
  role,
  setSelectedRole,
  setSelectedSkill,
  onSubSkillConfirm,
  onSubSkillUnconfirm,
  onCountChange,
  openDropdown,
  setOpenDropdown,
}: RoleSelectBoxProps) => {
  const dropdownRef = useRef<HTMLElement | null>(null);

  const roleTitle = projectRoles?.find(
    (projectRole) => projectRole.id === role.selectedRole
  )?.name;

  const selectedRoleData = projectRoles?.find(
    (projectRole) => projectRole.id === role.selectedRole
  );

  const selectedSkillData =
    selectedRoleData?.skill.find(
      (oneSkill) => oneSkill.id === role.selectedSkill
    ) || null;

  const handleInnerBoxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (openDropdown !== blockId + "role") {
      setOpenDropdown(blockId + "role");
    } else {
      setOpenDropdown(null);
    }
  };

  const handleRoleChange = (roleId: number) => {
    setSelectedRole(roleId);
    setOpenDropdown(null);
  };

  const handleSkillBoxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (openDropdown !== blockId + "skill") {
      setOpenDropdown(blockId + "skill");
    } else {
      setOpenDropdown(null);
    }
  };

  const handleMenuItemClick = (skillId: number) => {
    setSelectedSkill(skillId);
    setOpenDropdown(null);
  };

  const handleSubSkillConfirm = (subSkill: IProjectRolesSubskill) => {
    onSubSkillConfirm(subSkill);
  };

  const handleSubSkillUnconfirm = (subSkill: IProjectRolesSubskill) => {
    onSubSkillUnconfirm(subSkill);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenDropdown]);

  const roleMenuOpen = openDropdown === blockId + "role";
  const skillMenuOpen = openDropdown === blockId + "skill";

  return (
    <OuterBox onClick={(event) => event.stopPropagation()}>
      <InnerBox onClick={handleInnerBoxClick}>
        {role.selectedRole === undefined ? "Выбрать роль" : roleTitle}
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            if (openDropdown !== blockId + "role") {
              setOpenDropdown(blockId + "role");
            } else {
              setOpenDropdown(null);
            }
          }}
          sx={{
            color: "black",
            width: "16px",
            height: "16px",
          }}
        >
          {openDropdown === blockId + "role" ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </InnerBox>
      {roleMenuOpen && (
        <RoleMenu
          open={openDropdown === blockId + "role"}
          selectedOption={role.selectedRole}
          options={projectRoles || []}
          onChange={handleRoleChange}
          onOutsideClick={() => setOpenDropdown(null)}
        />
      )}
      {role.selectedRole !== undefined && (
        <SkillBox onMouseDown={handleSkillBoxClick} onClick={() => {}}>
          {role.selectedSkill === undefined
            ? "Выбрать навык"
            : selectedSkillData?.name}
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (openDropdown !== blockId + "skill") {
                setOpenDropdown(blockId + "skill");
              } else {
                setOpenDropdown(null);
              }
            }}
            sx={{
              color: "black",
              width: "16px",
              height: "16px",
            }}
          >
            {openDropdown === blockId + "skill" ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          {skillMenuOpen && (
            <SkillMenuBox
              open={openDropdown === blockId + "skill"}
              onOutsideClick={() => setOpenDropdown(null)}
              onMouseDown={(event) => event.stopPropagation()}
            >
              {selectedRoleData?.skill?.map((oneSkill) => (
                <MenuItem
                  key={oneSkill.id}
                  onClick={() => handleMenuItemClick(oneSkill.id)}
                  selected={oneSkill.id === role.selectedSkill}
                >
                  {oneSkill.name}
                </MenuItem>
              ))}
            </SkillMenuBox>
          )}
        </SkillBox>
      )}

      {role.selectedSkill !== undefined && (
        <Box
          sx={{
            mt: "7px",
            pl: "10px",
            width: "200px",
            borderRadius: "4px",
          }}
        >
          {selectedSkillData?.subSkill?.map((subSkill) => (
            <SubSkillConfirmationButton
              key={subSkill.id}
              subSkill={subSkill}
              onConfirm={() => handleSubSkillConfirm(subSkill)}
              onUnconfirm={() => handleSubSkillUnconfirm(subSkill)}
              isSelected={role.selectedSubSkills.some(
                (s) => s.name === subSkill.name
              )}
              instrumentGroup={subSkill.instrumentGroup}
            />
          ))}
          <PeoplePerRole count={role.count} onCountChange={onCountChange} />
        </Box>
      )}
    </OuterBox>
  );
};
export default RoleSelectBox;
