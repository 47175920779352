import { Avatar, Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LocationIcon } from "../../../../../UI/Icons/LocationIcon";

export const InfoBaseStub = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Рейтинг */}
      <Box justifyContent="end" display="grid" alignContent="baseline">
        <Typography
          width="86px"
          fontSize="12px"
          fontWeight="500"
          bgcolor={grey[500]}
          px="30px"
          py="10px"
        >
          {/* {userData.rating} */}
        </Typography>
      </Box>

      {/* Ава, Дата, Кнопки */}
      <Box width="285px" display="grid" justifySelf="center">
        <Box display="grid" justifySelf="center">
          <Avatar sx={{ width: 100, height: 100 }} />
        </Box>
        <Typography
          variant="h2"
          sx={{
            fontSize: "20px",
            textTransform: "none",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "24px",
          }}
        >
          {/* {userData.full_name} */}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginTop: "8px",
            fontWeight: 300,
          }}
        >
          {/* {userData.nick_name} */}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            textAlign: "center",
            marginTop: "36px",
            fontWeight: 300,
          }}
        >
          Дата регистрации:{" "}
          {/* {new Date(userData.registration_date).toISOString().slice(0, 10)} */}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "max-content max-content",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <LocationIcon />
          <Typography
            sx={{
              marginLeft: "7px",
              fontSize: "16px",
              textAlign: "center",
              marginTop: "6px",
              fontWeight: 300,
            }}
          >
            {/* {userData.town}, {userData.country} */}
          </Typography>
        </Box>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            letterSpacing: "initial",
            py: "6px",
            m: "36px 0 6px",
            border: "1px solid #82D9FF",
            borderRadius: "44px",
            fontSize: "16px",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "#82D9FF",
            },
          }}
          onClick={() => {
            navigate("edit");
          }}
        >
          Редактировать профиль
        </Button>

        <Button
          onClick={() => navigate("/profile/settings")}
          sx={{
            letterSpacing: "initial",
            py: "6px",
            my: "6px",
            backgroundColor: "transparent",
            color: "#000000",
            border: "3px solid #82D9FF",
            borderRadius: "44px",
            fontSize: "16px",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Редактировать видимость
        </Button>
        <Button
          sx={{
            letterSpacing: "initial",
            fontFamily: "Nunito",
            py: "6px",
            px: "12px",
            my: "6px",
            backgroundColor: "transparent",
            color: "#000000",
            border: "3px solid #82D9FF",
            borderRadius: "44px",
            fontSize: "16px",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Копировать ссылку на профиль
        </Button>
      </Box>

      <Box width="320px" justifySelf="center">
        <Typography
          variant="h2"
          sx={{
            marginTop: "36px",
          }}
        >
          Контакты
        </Typography>

        <Box
          sx={{
            fontSize: "14px",
            rowGap: "10px",
            marginTop: "10px",
            display: "grid",
            gridTemplateColumns: "max-content 1fr",
            columnGap: "10px",
          }}
        >
          <Box>Телефон:</Box>
          {/* <Box>{userData.phone}</Box> */}
          <Box>Email:</Box>
          {/* <Box>{userData.email}</Box> */}
          <Box>Github: </Box>
          {/* <Box>{userData.github_link}</Box> */}
          <Box>telegram: </Box>
          {/* <Box>{userData.telegram}</Box> */}
        </Box>

        <Typography sx={{ marginTop: "38px" }} variant="h2">
          О себе
        </Typography>
        <Typography fontSize="14px" sx={{ marginTop: "10px" }}>
          {/* {userData.bio} */}
        </Typography>
      </Box>
    </>
  );
};
