import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
} from "@mui/material";
import React from "react";

interface WZPriorityProps {
  priority: string;
}

const WZPriority: React.FC<WZPriorityProps> = ({ priority }) => {
  const priorityTypes = {
    high: "red",
    middle: "orange",
    low: "green",
  };
  return (
    <Avatar
      sx={{
        width: "16px",
        height: "16px",
        background: `${priorityTypes[priority as keyof typeof priorityTypes]}`,
      }}
    >
      {" "}
    </Avatar>
  );
};

export const Task = ({ item, board, setStatuses, statuses }: any) => {
  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDragEnd = (e: any) => {};

  const onDrop = (e: any, item: any, board: any) => {
    e.preventDefault();

    const droppedItem = Number(e.dataTransfer.getData("item"));
    const droppedBoard = e.dataTransfer.getData("board");

    const tempBoards = structuredClone(statuses);

    const targetBoardIndex = tempBoards.findIndex(
      (el: any) => el.name === board.name
    );
    
    const targetItemIndex = tempBoards[targetBoardIndex].tasks.findIndex((el:any)=> el.id === item.id)

    const startBoardIndex = tempBoards.findIndex((el:any)=>el.name === droppedBoard)

    const startItemIndex = tempBoards[startBoardIndex].tasks.findIndex((el:any)=>el.id === droppedItem)
    
    const tempItem = tempBoards[targetBoardIndex].tasks[targetItemIndex]

    tempBoards[targetBoardIndex].tasks[targetItemIndex] = tempBoards[startBoardIndex].tasks[startItemIndex]

    tempBoards[startBoardIndex].tasks[startItemIndex] = tempItem


    setStatuses(tempBoards)
  };

  const onDragStart = (e: any, item: any, board: any) => {
    e.dataTransfer.setData("item", item.id);
    e.dataTransfer.setData("board", board.name);
  };

  return (
    <Card
      variant="outlined"
      draggable={true}
      onPointerDown={() => {}}
      onDragOver={(e) => onDragOver(e)}
      onDragEnd={(e) => onDragEnd(e)}
      onDrop={(e) => onDrop(e, item, board)}
      onDragStart={(e) => onDragStart(e, item, board)}
      sx={{
        width: "270px",
        mt: "22px",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <Box>
        <CardHeader
          title={item.description}
          avatar={<WZPriority priority={item.priority} />}
        ></CardHeader>
        <CardContent>
          {item.tags && <Chip label={item.tags.map((tag: any) => tag.name)} />}
        </CardContent>
      </Box>
    </Card>
  );
};
