import React from "react";
import { Box } from "@mui/material";
import { ProjectAvatar } from "./components/ProjectAvatar";
import { ProjectName } from "./components/ProjectName";
import { ProjectManagerButton } from "./components/ProjectManagerButton";
import { ProjectEditStatus } from "../ProjectEditStatus/ProjectEditStatus";
import { PrManagerInnerBoxStyle, PrManagerOutterBoxStyle } from "./styles";

interface ProjectManagerProps {
  currentName: string;
  handleCreateProject: () => void;
  projectDataStatus?: string;
  onStatusChange: (status: string) => void;
  openDropdown: string | null;
  setOpenDropdown: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ProjectManager = ({
  currentName,
  handleCreateProject,
  projectDataStatus,
  onStatusChange,
  openDropdown,
  setOpenDropdown,
}: ProjectManagerProps) => {
  return (
    <Box sx={PrManagerOutterBoxStyle}>
      <Box sx={PrManagerInnerBoxStyle}>
        <Box>
          <ProjectAvatar />
          <ProjectName currentName={currentName} />
        </Box>
        {projectDataStatus && (
          <ProjectEditStatus
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            onStatusChange={onStatusChange}
            status={projectDataStatus}
          />
        )}
        <ProjectManagerButton handleCreateProject={handleCreateProject} />
      </Box>
    </Box>
  );
};
