import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export const ProjectReturnButton = () => {
    
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate("/projects/myprojects");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: "10px",
      }}
    >
      <Box>
        <Button
          sx={{
            color: "white",
          }}
          onClick={handleReturn}
        >
          <CloseIcon
            sx={{
              color: "red",
            }}
          />
          <Typography
            sx={{
              fontFamily: "Zen Maru Gothic",
              fontSize: "16px",
              fontWeight: 700,
              ml: "5px",
            }}
          >
            Вернуться назад
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
